// dbUtils.js
import alasql from 'alasql';

class DatabaseManager {
  constructor() {
    // Initialize default database
    alasql('CREATE DATABASE IF NOT EXISTS expressdb');
    alasql('USE expressdb');
    
    // Store table schemas
    this.schemas = new Map();
  }

  // Create a new table
  createTable(tableName, schema) {
    const columns = Object.entries(schema)
      .map(([name, type]) => `${name} ${type}`)
      .join(', ');
      
    const query = `CREATE TABLE IF NOT EXISTS ${tableName} (${columns})`;
    alasql(query);
    this.schemas.set(tableName, schema);
    
    return true;
  }

  // Insert data into a table
  insert(tableName, data) {
    if (Array.isArray(data)) {
      return alasql(`INSERT INTO ${tableName} VALUES ?`, [data]);
    } else {
      return alasql(`INSERT INTO ${tableName} VALUES ?`, [[data]]);
    }
  }

  // Select data from a table
  select(query) {
    return alasql(query);
  }

  // Update data in a table
  update(tableName, setValues, whereClause) {
    const setClause = Object.entries(setValues)
      .map(([key, value]) => `${key} = ${typeof value === 'string' ? `'${value}'` : value}`)
      .join(', ');
      
    const query = `UPDATE ${tableName} SET ${setClause} ${whereClause ? 'WHERE ' + whereClause : ''}`;
    return alasql(query);
  }

  // Delete data from a table
  delete(tableName, whereClause) {
    const query = `DELETE FROM ${tableName} ${whereClause ? 'WHERE ' + whereClause : ''}`;
    return alasql(query);
  }

  // Drop a table
  dropTable(tableName) {
    alasql(`DROP TABLE IF EXISTS ${tableName}`);
    this.schemas.delete(tableName);
    return true;
  }

  // Execute raw SQL query
  query(sql, params = []) {
    return alasql(sql, params);
  }

  // Get table schema
  getSchema(tableName) {
    return this.schemas.get(tableName);
  }

  // List all tables
  getTables() {
    return alasql('SHOW TABLES FROM expressdb');
  }
}

export const db = new DatabaseManager();