'use client'
import axios from 'axios'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  Terminal,
  Code,
  RefreshCw,
  Play,
  Maximize2,
  Minimize2,
  X,
  Check,
  LoaderIcon,
  Clock,
  FileText,
  Save,
  ToggleLeft,
  Table2,
  TerminalIcon,
  CheckCheck,
  ChevronDown,
  ListStart,
} from 'lucide-react'
import parse from 'html-react-parser'
import ReactConfetti from 'react-confetti'
import CodeMirror from '@uiw/react-codemirror'
import { sql } from '@codemirror/lang-sql'
import { debounce } from 'lodash'
import alasql from 'alasql'
import Header from '../../Components/Headers/CodeForCambodiaHeader'
import Footer from '../../Components/Footer'
import options from '../../Components/Lessons/OptionsQuill'
import { DraggableCore } from 'react-draggable'
import SQLDisplay from '../../Components/Lessons/SQL/SQLDisplay'
import HistorySection from '../../Components/Lessons/SQL/HistorySection'
import FreeTrialHeader from '../../Components/Headers/FreeTrialHeader'

// First, update the Button component to add a variant prop
const Button = ({ onClick, disabled, children, className, icon: Icon, variant }) => {
  const getVariantClasses = () => {
    switch (variant) {
      case 'primary':
        return 'bg-blue-500 text-white hover:bg-blue-600'
      case 'secondary':
        return 'bg-gray-100 text-gray-600 hover:bg-gray-200'
      default:
        return 'text-gray-600 hover:bg-gray-100'
    }
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`px-3 py-2 rounded-md text-sm font-medium transition-colors duration-150 flex items-center ${getVariantClasses()} ${className} ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      {Icon && <Icon size={16} className="mr-2" />}
      {children}
    </button>
  )
}

const TitleDropdown = ({ lesson }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const truncateTitle = (title, maxLength = 20) => {
    if (title.length <= maxLength) return title
    return `${title.substring(0, maxLength)}...`
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-sm text-gray-600 ml-4 hidden sm:flex items-center gap-1 cursor-pointer hover:text-gray-800"
      >
        {truncateTitle(lesson?.lesson_name || 'SQL Lesson')}
        <ChevronDown size={16} />
      </button>
      {isOpen && (
        <div className="absolute top-full left-0 mt-1 w-64 bg-white border border-gray-200 rounded-md shadow-lg z-10">
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-2">{lesson?.lesson_name || 'SQL Lesson'}</h2>
          </div>
        </div>
      )}
    </div>
  )
}

const NavItem = ({ icon: Icon, label, active, onClick }) => (
  <button
    onClick={onClick}
    className={`
      flex items-center gap-2 px-3 py-2 text-sm
      ${active ? 'bg-blue-50 text-blue-600 font-medium' : 'text-gray-700 hover:bg-gray-100'}
      rounded-md transition-colors
    `}
  >
    <Icon size={18} />
    <span>{label}</span>
  </button>
)





const CodeSection = ({
  query,
  setQuery,
  result,
  error,
  isRunning,
  runQuery,
  isCorrect,
  lesson,
  editorRef,
  isFullScreen
}) => {
  const [splitPosition, setSplitPosition] = useState(50)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const dragRef = useRef(null)
  const containerRef = useRef(null)

  // Constants for resize limits
  const MIN_WIDTH_PERCENTAGE = 30
  const MAX_WIDTH_PERCENTAGE = 70
  const RESIZE_THROTTLE = 16 // About 60fps

  const handleDragStart = useCallback((e) => {
    setIsDragging(true)
    const containerRect = containerRef.current.getBoundingClientRect()
    setStartX(e.clientX - containerRect.left)
  }, [])

  const handleDrag = useCallback(
    (e) => {
      if (!isDragging || !containerRef.current) return

      // Throttle the resize calculations
      requestAnimationFrame(() => {
        const containerRect = containerRef.current.getBoundingClientRect()
        const containerWidth = containerRect.width
        const currentX = e.clientX - containerRect.left
        
        // Calculate new position as a percentage of container width
        const newPercentage = (currentX / containerWidth) * 100

        // Clamp the position between MIN_WIDTH_PERCENTAGE and MAX_WIDTH_PERCENTAGE
        const clampedPosition = Math.min(
          Math.max(newPercentage, MIN_WIDTH_PERCENTAGE),
          MAX_WIDTH_PERCENTAGE
        )

        setSplitPosition(clampedPosition)
      })
    },
    [isDragging]
  )

  const handleDragStop = useCallback(() => {
    setIsDragging(false)
  }, [])

  // Use ResizeObserver with error handling
  useEffect(() => {
    let resizeObserver
    const container = containerRef.current

    try {
      resizeObserver = new ResizeObserver((entries) => {
        // Wrap in requestAnimationFrame to avoid ResizeObserver loop limit errors
        requestAnimationFrame(() => {
          if (!entries.length) return
          
          // Ensure the split position stays within bounds after resize
          const containerWidth = entries[0].contentRect.width
          const leftPanelWidth = (splitPosition / 100) * containerWidth
          
          if (leftPanelWidth < containerWidth * (MIN_WIDTH_PERCENTAGE / 100)) {
            setSplitPosition(MIN_WIDTH_PERCENTAGE)
          } else if (leftPanelWidth > containerWidth * (MAX_WIDTH_PERCENTAGE / 100)) {
            setSplitPosition(MAX_WIDTH_PERCENTAGE)
          }
        })
      })

      if (container) {
        resizeObserver.observe(container)
      }
    } catch (error) {
      console.warn('ResizeObserver error:', error)
    }

    return () => {
      try {
        if (resizeObserver && container) {
          resizeObserver.unobserve(container)
          resizeObserver.disconnect()
        }
      } catch (error) {
        console.warn('Error cleaning up ResizeObserver:', error)
      }
    }
  }, [splitPosition])

  return (
    <div className="flex flex-col h-full min-h-[60vh]">
      <div className="flex flex-grow relative" ref={containerRef}>
        {/* Left panel - Editor */}
        <div
          style={{
            width: `${splitPosition}%`,
            transition: isDragging ? 'none' : 'width 0.1s ease-out',
            minWidth: `${MIN_WIDTH_PERCENTAGE}%`,
            maxWidth: `${MAX_WIDTH_PERCENTAGE}%`
          }}
          className="h-full"
        >
          <CodeMirror
            value={query}
            height="100%"
            extensions={[sql()]}
            onChange={(value) => setQuery(value)}
            theme="light"
            basicSetup={{
              lineNumbers: true,
              highlightActiveLine: true,
              highlightSelectionMatches: true,
              foldGutter: true,
              dropCursor: true,
              allowMultipleSelections: true,
              indentOnInput: true,
              bracketMatching: true,
              closeBrackets: true,
              autocompletion: true,
              rectangularSelection: true,
              crosshairCursor: true,
              highlightActiveLineGutter: true,
            }}
            style={{
              fontSize: '14px',
              fontFamily: 'JetBrains Mono, monospace',
            }}
          />
        </div>

        {/* Drag Handle */}
        <DraggableCore
          onStart={handleDragStart}
          onDrag={handleDrag}
          onStop={handleDragStop}
          nodeRef={dragRef}
        >
          <div
            ref={dragRef}
            className={`
              absolute top-0 bottom-0
              w-4 -ml-2
              cursor-col-resize
              group
              flex items-center justify-center
              z-10
              ${isDragging ? 'select-none' : ''}
            `}
            style={{
              left: `${splitPosition}%`,
              transition: isDragging ? 'none' : 'left 0.1s ease-out',
            }}
          >
            <div
              className={`
                w-1 h-8
                bg-gray-300
                group-hover:bg-blue-400
                rounded
                transition-colors duration-150
                ${isDragging ? 'bg-blue-500' : ''}
              `}
            />
          </div>
        </DraggableCore>

        {/* Right panel - Output */}
        <div
          style={{
            width: `${100 - splitPosition}%`,
            transition: isDragging ? 'none' : 'width 0.1s ease-out',
            minWidth: `${100 - MAX_WIDTH_PERCENTAGE}%`,
            maxWidth: `${100 - MIN_WIDTH_PERCENTAGE}%`
          }}
          className="h-full bg-white overflow-auto border-l"
        >
          <div className="bg-gray-100 px-4 py-2 flex items-center justify-between overflow-hidden border-b border-gray-200">
            <div className="flex items-center gap-2">
              <Table2 size={16} />
              <span className="font-semibold">Query Result</span>
            </div>
            {isRunning ? (
              <LoaderIcon className="animate-spin text-blue-500" size={20} />
            ) : (
              isCorrect !== null && (
                isCorrect ? (
                  <Check className="text-green-500" size={20} />
                ) : (
                  <X className="text-red-500" size={20} />
                )
              )
            )}
          </div>
          <div className="p-4 h-full overflow-auto">
            {isRunning ? (
              <div className="flex items-center justify-center h-full text-gray-500">
                Verifying query...
              </div>
            ) : (
              <div className="space-y-4">
                {error && (
                  <div className="text-red-500 bg-red-50 p-3 rounded-md">
                    {error}
                  </div>
                )}
                {result && (
                  <div className=''>
                    <h4 className="text-sm font-bold text-gray-800 mb-2">Your Result:</h4>
                    <pre className={`font-mono text-sm whitespace-pre-wrap bg-gray-50 p-3 rounded-md pb-12  `}>
                      {result}
                    </pre>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}


const InstructionsSection = ({ instructions, initialData }) => (
  <div className="flex-grow overflow-auto p-4 pb-32">
    <div className="prose max-w-none overflow-y-auto">{parse(instructions || '', options)}</div>
  </div>
)



export default function SQLLessonModern({ lesson, isSubscribed }) {
  const [activeTab, setActiveTab] = useState('instructions')
  const [query, setQuery] = useState(lesson?.starter_sql || '')
  const [result, setResult] = useState('')
  const [error, setError] = useState(null)
  const [isCorrect, setIsCorrect] = useState(null)
  const [history, setHistory] = useState([])
  const [historyIndex, setHistoryIndex] = useState(-1)
  const [isRunning, setIsRunning] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const editorRef = useRef(null)

  const truncateTitle = (title, maxLength = 20) => {
    if (title.length <= maxLength) return title;
    return `${title.substring(0, maxLength)}...`;
  };

  useEffect(() => {
    if (lesson?.starter_sql) {
      setQuery(lesson.starter_sql)
    }
    if (lesson?.initial_data) {
      try {
        // Initialize the database with initial schema
        alasql('DROP DATABASE IF EXISTS db')
        alasql('CREATE DATABASE db')
        alasql('USE db')
        
        // Split and execute initial queries separately to handle multiple statements
        const queries = lesson.initial_data.split(';').filter(q => q.trim())
        queries.forEach(query => {
          if (query.trim()) {
            alasql(query)
          }
        })
      } catch (err) {
        console.error('Failed to initialize database with initial_data:', err)
        setError('Failed to initialize database. Please check the initial data.')
      }
    }
  }, [lesson])

  const runQuery = useCallback(async () => {
    if (!query.trim()) {
      setError('Query cannot be empty')
      return
    }

    setIsRunning('running')
    setError(null)
    
    try {
      // Ensure we're using the correct database
      alasql('USE db')
      
      // Split and execute multiple statements if present
      const queries = query.split(';').filter(q => q.trim())
      let finalResult
      
      for (const singleQuery of queries) {
        if (singleQuery.trim()) {
          finalResult = alasql(singleQuery)
        }
      }
      
      const resultString = JSON.stringify(finalResult, null, 2)
      setResult(resultString)
      setHistory(prev => [...prev, query])
      setHistoryIndex(-1)
      setIsCorrect(null)
    } catch (error) {
      console.error('Query error:', error)
      setError(error.message)
      setResult('')
    } finally {
      setIsRunning(false)
    }
  }, [query])

  const verifyAnswer = useCallback(async () => {
    setError('')
    if (!query.trim()) {
      setError('Query cannot be empty')
      return
    }

    setIsRunning('verifying')
    
    try {
      if (!lesson.sample_output) {
        throw new Error('Sample output is not defined for this lesson')
      }

      const verificationResponse = await axios.post('https://codeforcambodia.codes/codecademy/sql-verify/verify-sql', {
        query,
        expectedResult: lesson.sample_output,
        initialData: lesson.initial_data
      })

      const { correct, message, output } = verificationResponse.data

      setIsCorrect(correct)
      
      if (correct) {
        setShowConfetti(true)
        setTimeout(() => setShowConfetti(false), 5000)
      } else {
        setError(`${message}${output ? `\nExpected: ${lesson.sample_output}\nGot: ${output}` : ''}`)
      }
    } catch (error) {
      console.error('Verification error:', error)
      let errorMessage = 'An unknown error occurred'
      
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message
      } else if (error.message) {
        errorMessage = error.message
      }

      setError(errorMessage)
      setIsCorrect(false)
    } finally {
      setIsRunning(false)
    }
  }, [query, lesson])

  const toggleFullScreen = useCallback(() => {
    setIsFullScreen((prev) => !prev)
  }, [])

  return (
    <div className={`${isFullScreen ? 'min-h-screen' : ''} md:pb-12 pb-0 flex flex-col bg-white`}>
      {!isFullScreen ? (isSubscribed ? <Header/> : <FreeTrialHeader/>) : null}
      {showConfetti && <ReactConfetti />}
      <div className="h-12 border-b bg-gray-50 flex items-center justify-between px-4">
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1">
            <div className="w-3 h-3 rounded-full bg-red-500"></div>
            <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
          </div>
          <TitleDropdown lesson={lesson} />
        </div>
        <div className="flex items-center gap-3">
          <Button
            onClick={runQuery}
            icon={TerminalIcon}
          >
            Run Query
          </Button>
          <Button 
            onClick={verifyAnswer}
            className="items-center px-3 py-1.5 text-sm"
            icon={CheckCheck}
          >
            Verify
          </Button>
          <Button
            onClick={() => {
              setQuery(lesson.starter_sql || '')
              setResult('')
              setError(null)
              setIsCorrect(null)
            }}
            className="sm:inline-flex hidden items-center px-3 py-1.5 text-sm"
            icon={RefreshCw}
          >
            Reset
          </Button>
          <Button
            onClick={toggleFullScreen}
            className="p-2"
            icon={isFullScreen ? Minimize2 : Maximize2}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row flex-grow overflow-hidden">
        <div className="w-full sm:w-48 border-b sm:border-r flex sm:flex-col bg-gray-50">
          <nav className="p-3 flex sm:flex-col gap-2 overflow-x-auto sm:overflow-x-visible">
            <NavItem
              icon={FileText}
              label="Instructions"
              active={activeTab === 'instructions'}
              onClick={() => setActiveTab('instructions')}
            />
            <NavItem
              icon={ListStart}
              label="Initial Query"
              active={activeTab === 'initial'}
              onClick={() => setActiveTab('initial')}
            />
            <NavItem
              icon={Code}
              label="Edit Query"
              active={activeTab === 'code'}
              onClick={() => setActiveTab('code')}
            />
            <NavItem
              icon={Clock}
              label="History"
              active={activeTab === 'history'}
              onClick={() => setActiveTab('history')}
            />
          </nav>
        </div>

        <div className={`flex-grow flex flex-col ${isFullScreen && ''}`}>
          <div className={`flex-grow overflow-hidden  ${isFullScreen ? 'overflow-y-scroll' : 'overflow-hidden'}`}>
            {activeTab === 'instructions' && (
              <InstructionsSection 
                instructions={lesson?.instructions} 
                initialData={lesson?.initial_data} 
              />
            )}

            {activeTab === 'code' && (
              <CodeSection
                query={query}
                setQuery={setQuery}
                result={result}
                error={error}
                isRunning={isRunning}
                runQuery={runQuery}
                verifyAnswer={verifyAnswer}
                isCorrect={isCorrect}
                lesson={lesson}
                editorRef={editorRef}
                isFullScreen={isFullScreen}
              />
            )}

            {activeTab === 'history' && (
              <HistorySection history={history} setQuery={setQuery} />
            )}

            {activeTab === 'initial' && (

              <SQLDisplay code = {lesson?.initial_data} title = {'Initial Query'}/>
            )}
          </div>
        </div>
      </div>
      {!isFullScreen && <Footer/>}

    </div>
  )
}