import React, { useState, useEffect, useCallback, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';
import HomePage from './Pages/HomePage';
import LessonContentPage from './Pages/LessonContentPage';
import CoursePage from './Pages/CoursePage';
import './App.css';
import Dashboard from './Pages/Dashboard';
import AllCourses from './Pages/AllCourses';
import { v4 as uuidv4 } from 'uuid';
import PricingPage from './Pages/PricingPage';
import ContactPage from './Pages/ContactPage';
import AboutPage from './Pages/AboutPage';
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage';
import WebDevLesson from './Pages/Contents/WebDevLesson';
import ConfettiWrapper from './Components/Dashboard/ConfettiWrapper';
import StreakUpdateNotification from './Components/Dashboard/StreakUpdateNotification';
import TelegramJoinModal from './Components/HomePage/TelegramJoinModal';
import ProfilePage from './Pages/ProfilePage';
import LeaderboardPage from './Pages/LeaderboardPage';
import StreakLeaderboardPage from './Pages/StreakLeaderboardPage';
import MilestoneModal from './Components/Dashboard/MilestoneModal';
import SurveyModal from './Components/Modals/SurveyModal';
import CodeLoadingAnimation from './Components/CodeLoadingAnimation';
import DonationPage from './Pages/DonationPage';
import Response from './Components/Donation/Response';
import LearningPathsGrid from './Components/HomePage/LearningPathsGrid';
import LearningPathPage from './Pages/LearningPathPage';
import AllLearningPaths from './Pages/AllLearningPaths';
import SQLLesson from './Pages/Contents/SQLLesson';
import PHPLesson from './Pages/Contents/PHPLesson';
import SearchPage from './Pages/SearchPage';
import SearchResultDetail from './Pages/SearchResultDetail';
import ForumPage from './Pages/Forum/ForumPage';
import PostPage from './Pages/Forum/PostPage';
import ChallengePage from './Pages/ChallengePage';
import WorkspacePage from './Pages/Challenges/WorkspacePage';
import ProjectListPage from './Pages/Challenges/ProjectListPage';
import ProjectEditorPage from './Pages/Challenges/ProjectEditorPage';
import SubmissionPage from './Pages/Challenges/SubmissionPage';
import ReactNativeLesson from './Pages/Contents/ReactNativeLesson';
import FlutterLesson from './Pages/Contents/FlutterLesson';
import SubscriptionPage from './Pages/SubscriptionPage';
import { CancelPage } from './Pages/Stripe/CancelPage';
import { SuccessPage } from './Pages/Stripe/SuccessPage';
import NetworkingLesson from './Pages/Contents/NetworkingLesson';
import PythonPyodide from './Pages/Contents/PythonPyodide';
import CustomLesson from './Pages/Contents/CustomLesson';
import ExpressLesson from './Pages/Contents/ExpressLesson';
import JupyterLesson from './Pages/Contents/JupyterLesson';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalTimeSpent, setTotalTimeSpent] = useState(0);
  const intervalIdRef = useRef(null);
  const startTimeRef = useRef(null);

  const [currentStreak, setCurrentStreak] = useState(0);
  const [longestStreak, setLongestStreak] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showStreakUpdate, setShowStreakUpdate] = useState(false);
  const [showTelegramModal, setShowTelegramModal] = useState(false);
  const [showMilestoneModal, setShowMilestoneModal] = useState(false);
  const [milestoneLabel, setMilestoneLabel] = useState('');
  const [showSurveyModal, setShowSurveyModal] = useState(false);

  const [isRetrying, setIsRetrying] = useState(false);
  const maxRetries = 3;
  const retryDelay = 5000;

  const trackUniqueDevice = useCallback(() => {
    const deviceId = localStorage.getItem('deviceId') || uuidv4();
    localStorage.setItem('deviceId', deviceId);

    axios.post('https://codeforcambodia.codes/codecademy/user/track-unique-device', { deviceId })
      .then(response => {
        // Handle response if needed
      })
      .catch(error => {
        console.error('Error tracking unique device:', error.message);
      });
  }, []);

  useEffect(() => {
    const registeration_tries = localStorage.getItem('registeration_tries') || 0;
    console.log(registeration_tries);
  }, []);

  useEffect(() => {
    const hasJoinedTelegram = localStorage.getItem('telegramJoined') === 'true';

    if (isLoggedIn && !hasJoinedTelegram) {
      const timer = setTimeout(() => {
        setShowTelegramModal(true);
      }, 5 * 60 * 1000);

      return () => clearTimeout(timer);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      startTimeRef.current = parseInt(localStorage.getItem('startTime')) || Date.now();
    }
    setIsLoading(false);
  }, []);

  const fetchTotalTimeSpent = useCallback(async (retryCount = 0) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await axios.get('https://codeforcambodia.codes/codecademy/user/get-time', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setTotalTimeSpent(response.data.totalTimeSpent);
      setIsRetrying(false);
    } catch (error) {
      console.error('Error fetching total time spent:', error);
      if (retryCount < maxRetries) {
        setIsRetrying(true);
        setTimeout(() => {
          fetchTotalTimeSpent(retryCount + 1);
        }, retryDelay);
      }
    }
  }, []);

  const fetchStreak = useCallback(async (retryCount = 0) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await axios.get('https://codeforcambodia.codes/codecademy/user/streak', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const { current_streak, longest_streak } = response.data;
      setCurrentStreak(current_streak);
      setLongestStreak(longest_streak);
      setIsRetrying(false);
    } catch (error) {
      console.error('Error fetching streak:', error);
      if (retryCount < maxRetries) {
        setIsRetrying(true);
        setTimeout(() => {
          fetchStreak(retryCount + 1);
        }, retryDelay);
      }
    }
  }, []);

  const trackTimeSpent = useCallback(async (retryCount = 0) => {
    if (!isLoggedIn || !startTimeRef.current) return;

    const endTime = Date.now();
    const timeSpentOnPage = Math.floor((endTime - startTimeRef.current) / 1000);
    startTimeRef.current = endTime;

    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await axios.post(
        'https://codeforcambodia.codes/codecademy/user/track-time',
        { timeSpent: timeSpentOnPage },
        { headers: { 'Authorization': `Bearer ${token}` }}
      );

      const { totalTime, streakData, milestoneData, survey } = response.data;

      setTotalTimeSpent(totalTime);
      setShowSurveyModal(survey);

      

      if (milestoneData.milestoneUpdate) {
        //convert milestoneData.milestone to hours
        if (milestoneData.milestone <= 3600) {
          const minutes = Math.floor(milestoneData.milestone / 60);
          setMilestoneLabel(`${minutes} minutes milestone reached!`);
        } else {
          const hours = Math.floor(milestoneData.milestone / 3600);
          setMilestoneLabel(`${hours} hours milestone reached!`);
        }
        setShowMilestoneModal(true);
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 10000);
      }

      if (streakData.streakUpdated) {
        setShowStreakUpdate(true);
        setTimeout(() => setShowStreakUpdate(false), 5000);
      }
      setIsRetrying(false);
    } catch (error) {
      console.error('Error tracking time spent:', error);
      if (retryCount < maxRetries) {
        setIsRetrying(true);
        setTimeout(() => {
          trackTimeSpent(retryCount + 1);
        }, retryDelay);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      startTimeRef.current = Date.now();

      intervalIdRef.current = setInterval(() => {
        trackTimeSpent();
      }, 300000); // Changed to 5 minutes (300000 milliseconds)

      return () => {
        clearInterval(intervalIdRef.current);
      };
    }
  }, [isLoggedIn, trackTimeSpent]);

  useEffect(() => {
    trackUniqueDevice();
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      fetchTotalTimeSpent();
      fetchStreak();
    }

    const storedStartTime = localStorage.getItem('startTime');
    if (storedStartTime) {
      startTimeRef.current = parseInt(storedStartTime, 10);
    } else {
      startTimeRef.current = Date.now();
      localStorage.setItem('startTime', startTimeRef.current);
    }
  }, [fetchTotalTimeSpent, fetchStreak, trackUniqueDevice]);
  useEffect(() => {
    if (isLoggedIn) {
      fetchStreak();
      startTimeRef.current = Date.now();
      localStorage.setItem('startTime', startTimeRef.current);

      if (!intervalIdRef.current) {
        intervalIdRef.current = setInterval(() => {
          trackTimeSpent();
        }, 300000); // Changed to 5 minutes (300000 milliseconds)
      }

      const handleVisibilityChange = () => {
        if (document.hidden) {
          trackTimeSpent();
        } else {
          startTimeRef.current = Date.now();
          localStorage.setItem('startTime', startTimeRef.current);
        }
      };

      const handleBeforeUnload = () => {
        trackTimeSpent();
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        window.removeEventListener('beforeunload', handleBeforeUnload);
        localStorage.removeItem('startTime');
      };
    } else {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
      localStorage.removeItem('startTime');
    }
  }, [isLoggedIn, fetchStreak, trackTimeSpent]);

  const handleLogout = () => {
    trackTimeSpent();
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    localStorage.removeItem('token');
    localStorage.removeItem('startTime');
    delete axios.defaults.headers.common['Authorization'];
    setIsLoggedIn(false);
  };

  const handleLoginSuccess = (token) => {
    localStorage.setItem('token', token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setIsLoggedIn(true);
    fetchTotalTimeSpent();
    fetchStreak();
    startTimeRef.current = Date.now();
    localStorage.setItem('startTime', startTimeRef.current);
  };

  if (isLoading) {
    return <CodeLoadingAnimation />
  }

  return (
    <Router>
      <ConfettiWrapper showConfetti={showConfetti} />
      {/* <TelegramJoinModal isOpen={showTelegramModal} onClose={() => setShowTelegramModal(false)} /> */}
      <StreakUpdateNotification show={showStreakUpdate} currentStreak={currentStreak} />
      <MilestoneModal 
        showMilestoneModal={showMilestoneModal}
        setShowMilestoneModal={setShowMilestoneModal}
        milestoneLabel={milestoneLabel}
      />

      <Routes>
        <Route path="/" element={isLoggedIn ? <Dashboard currentStreak={currentStreak} longestStreak={longestStreak} totalTimeSpent={totalTimeSpent} /> : <HomePage onRegisterSuccess={handleLoginSuccess}/>} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/response" element={<Response/>} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/challenge" element={<ChallengePage />} />
        <Route path="/search/document/:documentId" element={<SearchResultDetail />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/sql-lesson" element={<SQLLesson />} />
        <Route path="/php-lesson" element={<PHPLesson />} />
        <Route path="/learning-path/:pathId" element={<LearningPathPage />} />
        <Route path="/all-learning-paths" element={<AllLearningPaths />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/leaderboard" element={<LeaderboardPage />} />
        <Route path="/flutter-lesson" element={<FlutterLesson />} />
        <Route path="/streak" element={<StreakLeaderboardPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/donation" element={<DonationPage/>} />
        <Route path="/forum" element={<ForumPage />} />
        <Route path="/forum/post/:postId" element={<PostPage />} />
        <Route path="/submission/:submissionId" element={<SubmissionPage />} />
        <Route path="/express-lesson" element={<ExpressLesson />} />
        <Route path="/custom-lesson" element={<CustomLesson />} />
        <Route path="/jupyter-lab" element={<JupyterLesson />} />
        <Route 
          path="/dashboard" 
          element={isLoggedIn ? <Dashboard currentStreak={currentStreak} longestStreak={longestStreak} /> : <Navigate to="/login" />} 
        />
        <Route path="/pricing" element={<PricingPage />} />
        <Route 
          path="/modules/:courseId/course" 
          element={isLoggedIn ? <CoursePage /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/courses/:courseId/modules/:moduleId/lessons/:lessonId" 
          element={isLoggedIn ? <LessonContentPage /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/courses" 
          element={<AllCourses />} 
        />
        <Route 
          path="/login" 
          element={
            isLoggedIn ? (
              <Navigate to="/dashboard" />
            ) : (
              <Login onLoginSuccess={handleLoginSuccess} />
            )
          } 
        />
        <Route 
          path="/register" 
          element={
            isLoggedIn ? (
              <Navigate to="/dashboard" />
            ) : (
              <Register onRegisterSuccess={handleLoginSuccess} />
            )
          } 
        />
        <Route path="/workspace" element={<WorkspacePage />} />
        <Route path="/subscription" element={<SubscriptionPage />} />
        <Route path="/cancel" element={<CancelPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/projects/:workspaceId" element={<ProjectListPage />} />
        <Route path="/workspace/:workspaceId/project/:projectId" element={<ProjectEditorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
