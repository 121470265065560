import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Split } from '@geoffcox/react-splitter';
import FileExplorer from '../../Components/Lessons/Express/FileExplorer';
import BrowserSimulator from '../../Components/Lessons/Express/BrowserSimulator';
import TerminalEmulator from '../../Components/Lessons/Express/TerminalEmulator';
import { SimulationProvider, useSimulation } from '../../Components/Lessons/Express/SimulationContext';
import Editor from '@monaco-editor/react';
import { Play, StopCircle } from 'lucide-react';
import { TerminalCommandHandler } from '../../Components/Lessons/Express/terminalCommand';
import Header from '../../Components/Headers/CodeForCambodiaHeader';
import Footer from '../../Components/Footer';
import FreeTrialHeader from '../../Components/Headers/FreeTrialHeader';
import parse from 'html-react-parser';
import options from '../../Components/Lessons/OptionsQuill';

const Tab = React.memo(({ active, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm font-medium border-b-2 flex-1 ${
      active
        ? 'text-blue-600 border-blue-500'
        : 'text-gray-600 hover:text-gray-800 border-transparent'
    }`}
  >
    {children}
  </button>
));

function ExpressSimulator(lesson) {
  const { 
    files,
    serverState,
    startServer,
    stopServer,
    simulateRequest,
    createFile,
    updateFile,
    deleteFile
  } = useSimulation();

  const [selectedFile, setSelectedFile] = useState('/server.js');
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [shouldRenderEditor, setShouldRenderEditor] = useState(false);
  const [activeSection, setActiveSection] = useState('instructions');

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRenderEditor(true);
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);

  const handleEditorChange = (value) => {
    const path = selectedFile.startsWith('/') ? selectedFile : `/${selectedFile}`;
    updateFile(path, value);
  };

  const handleRequest = async (config) => {
    console.log('Request config:', config);
    const response = await simulateRequest(config);
    console.log('Response:', response);
    return response;
  };

  const handleEditorDidMount = () => {
    setIsEditorReady(true);
  };

  const commandHandler = useMemo(() => {
    const simulationContext = {
      startServer,
      stopServer,
      createFile,
      updateFile,
      deleteFile
    };
    
    return new TerminalCommandHandler(
      files,
      serverState,
      simulationContext
    );
  }, [files, serverState, startServer, stopServer, createFile, updateFile, deleteFile]);

  return (
    <div className="h-screen flex flex-col bg-gray-100">
      {/* Tabs */}
      <div className="flex border-b border-gray-200 bg-white">
        <Tab
          active={activeSection === 'instructions'}
          onClick={() => setActiveSection('instructions')}
        >
          Instructions
        </Tab>
        <Tab
          active={activeSection === 'simulator'}
          onClick={() => setActiveSection('simulator')}
        >
          Express Simulator
        </Tab>
      </div>

      {activeSection === 'instructions' ? (
        <div className="flex-grow overflow-auto p-4 pb-32">
          <div className="prose max-w-none overflow-y-auto">
            {parse(lesson?.lesson?.instructions || '', options)}
          </div>
        </div>
      ) : (
        <div className="flex-1 flex flex-col">
          {/* Top Bar */}
          <div className="flex-none h-12 bg-white border-b shadow-sm px-4 flex items-center justify-between">
            <div className="text-lg font-semibold text-gray-800">Express.js Simulator</div>
            <div className="flex items-center gap-3">
              <button
                onClick={serverState.running ? stopServer : startServer}
                className={`px-4 py-1.5 rounded-md flex items-center gap-2 ${
                  serverState.running
                    ? 'bg-red-500 hover:bg-red-600 text-white'
                    : 'bg-green-500 hover:bg-green-600 text-white'
                }`}
              >
                {serverState.running ? (
                  <>
                    <StopCircle size={16} />
                    Stop Server
                  </>
                ) : (
                  <>
                    <Play size={16} />
                    Start Server
                  </>
                )}
              </button>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 flex overflow-hidden">
            {/* File Explorer */}
            <FileExplorer
              files={files}
              selectedFile={selectedFile}
              onSelectFile={setSelectedFile}
              onCreateFile={createFile}
              onDeleteFile={deleteFile}
            />

            {/* Main Split: Code+Terminal and Browser */}
            <Split
              direction="horizontal"
              initialSizes={[60, 40]}
              minSizes={[200, 200]}
            >
              {/* Left side: Editor and Terminal stacked vertically */}
              <div className="flex flex-col h-full">
                {/* Editor takes up most of the space */}
                <div className="flex-1 min-h-0">
                  {shouldRenderEditor && (
                    <Editor
                      height="100%"
                      width="100%"
                      defaultLanguage="javascript"
                      value={files['/'].children[selectedFile.replace('/', '')]?.content || ''}
                      onChange={handleEditorChange}
                      onMount={handleEditorDidMount}
                      theme="vs-dark"
                      options={{
                        minimap: { enabled: false },
                        fontSize: 14,
                        lineNumbers: 'on',
                        roundedSelection: false,
                        scrollBeyondLastLine: false,
                        automaticLayout: true,
                        padding: 8,
                      }}
                    />
                  )}
                </div>
                
                {/* Terminal takes up fixed height at bottom */}
                <div className="h-48 border-t border-gray-700">
                  <TerminalEmulator 
                    key="terminal"
                    commandHandler={commandHandler}
                    serverState={serverState}
                  />
                </div>
              </div>

              {/* Right side: Browser Simulator */}
              <BrowserSimulator 
                onRequest={handleRequest}
              />
            </Split>
          </div>

          {/* Status Bar */}
          <div className="flex-none h-6 bg-[#007ACC] text-white px-4 flex items-center text-sm">
            <span>
              {serverState.running ? 'Server: Running' : 'Server: Stopped'}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default function ExpressLesson(lesson) {
  return (
    <SimulationProvider>
      {lesson.isSubscribed ? <Header/> : <FreeTrialHeader/>}
      <ExpressSimulator lesson={lesson} />
      <Footer/>
    </SimulationProvider>
  );
}