import React, { useState, useEffect } from 'react';
import { Terminal, Loader, Package, BookOpen, Code2, Settings, PlayCircle, ChevronDown, Upload } from 'lucide-react';
import Editor from '@monaco-editor/react';
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader';
import Footer from '../../Components/Footer';
import { motion } from 'framer-motion';
import FreeTrialHeader from '../../Components/Headers/FreeTrialHeader';
import options from '../../Components/Lessons/OptionsQuill';
import parse from 'html-react-parser';

const PythonPyodide = ({lesson, isSubscribed}) => {
  const [code, setCode] = useState(lesson?.starter_code || '');
  const [pyodide, setPyodide] = useState(null);
  const [output, setOutput] = useState('');
  const [isRunning, setIsRunning] = useState(false);
  const [activeTab, setActiveTab] = useState('learn');
  const [isInstalling, setIsInstalling] = useState(false);
  const [customPackage, setCustomPackage] = useState('');
  const [packages, setPackages] = useState([
    { name: 'numpy', installed: false, description: 'បណ្ណាល័យគណនាលេខ' },
    { name: 'pandas', installed: false, description: 'បណ្ណាល័យរៀបចំទិន្នន័យ' },
    { name: 'matplotlib', installed: false, description: 'បណ្ណាល័យគូរក្រាហ្វិក' },
    { name: 'scipy', installed: false, description: 'បណ្ណាល័យគណនាវិទ្យាសាស្ត្រ' },
    { name: 'pydantic', installed: false, description: 'បណ្ណាល័យផ្ទៀងផ្ទាត់ទិន្នន័យ' },
    { name: 'scikit-learn', installed: false, description: 'បណ្ណាល័យម៉ាស៊ីនរៀន' }
  ]);
  const [uploadedFiles, setUploadedFiles] = useState({});

  useEffect(() => {
    const loadScript = async () => {
      const script = document.createElement('script');
      script.src = "https://cdn.jsdelivr.net/pyodide/v0.26.4/full/pyodide.js";
      document.head.appendChild(script);

      script.onload = async () => {
        try {
          const pyodideInstance = await window.loadPyodide();
          await pyodideInstance.loadPackage('micropip');
          setPyodide(pyodideInstance);
          setOutput('Python environment ready! Install packages to begin.\n');
        } catch (error) {
          setOutput('Error initializing Python: ' + error.message + '\n');
        }
      };
    };
    loadScript();
  }, []);

  const installPackage = async (packageName) => {
    if (!pyodide) return;
    setIsInstalling(true);
    setOutput(prev => prev + `\nInstalling ${packageName}...`);

    try {
      if (['numpy', 'pandas', 'matplotlib', 'scipy'].includes(packageName)) {
        await pyodide.loadPackage(packageName);
      } else {
        await pyodide.runPythonAsync(`import micropip; await micropip.install('${packageName}')`);
      }

      await pyodide.runPythonAsync(`import ${packageName === 'scikit-learn' ? 'sklearn' : packageName}`);

      setPackages(prev => prev.map(pkg => 
        pkg.name === packageName ? { ...pkg, installed: true } : pkg
      ));
      setOutput(prev => prev + `\n${packageName} installed successfully!`);
    } catch (error) {
      setOutput(prev => prev + `\nError installing ${packageName}: ${error.message}`);
    }

    setIsInstalling(false);
  };

  const installCustomPackage = async () => {
    if (!customPackage || !pyodide) return;
    setIsInstalling(true);
    setOutput(prev => prev + `\nInstalling custom package: ${customPackage}...`);

    try {
      await pyodide.runPythonAsync(`import micropip; await micropip.install('${customPackage}')`);
      setOutput(prev => prev + `\nCustom package ${customPackage} installed successfully!`);
      setCustomPackage('');
    } catch (error) {
      setOutput(prev => prev + `\nError installing custom package ${customPackage}: ${error.message}`);
    }

    setIsInstalling(false);
  };

  const runCode = async () => {
    if (!pyodide) return;
    setIsRunning(true);
    setOutput('Running...\n');

    try {
      pyodide.runPython(`
import io, sys
old_stdout = sys.stdout
sys.stdout = io.StringIO()
      `);

      const result = await pyodide.runPythonAsync(code);
      const stdout = pyodide.runPython('sys.stdout.getvalue()');

      pyodide.runPython(`
sys.stdout = old_stdout
      `);

      setOutput('Output:\n' + stdout + (result !== undefined ? '\nResult: ' + result : ''));
    } catch (error) {
      setOutput('Error: ' + error.message + '\n');
    }

    setIsRunning(false);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;
        setUploadedFiles(prev => ({
          ...prev,
          [file.name]: content
        }));

        if (pyodide) {
          pyodide.FS.writeFile(file.name, content);
          setOutput(prev => prev + `\nFile "${file.name}" uploaded successfully!`);
        }
      };
      reader.readAsText(file);
    } catch (error) {
      setOutput(prev => prev + `\nError uploading file: ${error.message}`);
    }
  };

  return (
    <div className="min-h-screen  flex flex-col bg-gray-50">
      {isSubscribed ? <CodeForCambodiaHeader /> : <FreeTrialHeader />}

      <main className="flex-1 ">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white overflow-hidden w-full"
        >

          {/* Navigation Tabs */}
          <div className="border-b border-gray-200 overflow-x-auto">
            <div className="flex whitespace-nowrap">
              {[
                { id: 'learn', icon: BookOpen, label: 'Instructions' },

                { id: 'settings', icon: Settings, label: 'Installation' },
                { id: 'code', icon: Terminal, label: 'Code' }
              ].map(tab => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`px-4 sm:px-6 py-3 flex items-center gap-2 transition-colors ${
                    activeTab === tab.id 
                      ? 'border-b-2 border-blue-500 text-blue-500 bg-blue-50'
                      : 'text-gray-500 hover:bg-gray-50'
                  }`}
                >
                  <tab.icon className="w-4 h-4" />
                  <span className="hidden sm:inline">{tab.label}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Settings Tab */}
          {activeTab === 'settings' && (
            <div className="p-4 sm:p-6">
              <div className="max-w-4xl mx-auto">
                <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-4">Package Installation</h2>
                <p className="text-gray-600 mb-2 Nokora">ចុច install library ខាងក្រោមដើម្បីដាក់បញ្ចូល library ដែលត្រូវបានប្រើនៅក្នុងកូដរបស់អ្នក</p>
                <p className="text-green-600 mb-6 Nokora">បើអត់ត្រូវការ អាចចាប់ផ្តើមកូដបាន</p>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {packages.map(pkg => (
                    <motion.div
                      key={pkg.name}
                      initial={{ opacity: 0, scale: 0.95 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.3 }}
                      className="border rounded-lg p-4 bg-white shadow-sm hover:shadow-md transition-shadow"
                    >
                      <div className="flex items-center justify-between mb-2">
                        <span className="text-lg font-semibold font-mono">{pkg.name}</span>
                        <button
                          onClick={() => !pkg.installed && installPackage(pkg.name)}
                          disabled={!pyodide || pkg.installed || isInstalling}
                          className={`px-4 py-2 rounded-md transition-colors font-mono text-sm ${
                            pkg.installed 
                              ? 'bg-emerald-500 text-white border border-emerald-800' 
                              : 'bg-emerald-500 text-white hover:bg-emerald-600 border border-emerald-700 disabled:opacity-50'
                          }`}
                        >
                          {pkg.installed ? '✓ Installed' : isInstalling ? 'Installing...' : 'pip install'}
                        </button>
                      </div>
                      <p className="text-gray-600 text-sm Nokora">{pkg.description}</p>
                    </motion.div>
                  ))}
                </div>

                <div className="mt-4">
                  <h3 className="text-lg font-bold text-gray-800 mb-2">Install Custom Package</h3>
                  <div className="flex gap-2">
                    <input
                      type="text"
                      value={customPackage}
                      onChange={(e) => setCustomPackage(e.target.value)}
                      placeholder="Enter package name"
                      className="flex-1 px-3 py-2 border rounded-md text-sm"
                    />
                    <button
                      onClick={installCustomPackage}
                      disabled={!customPackage || isInstalling}
                      className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50"
                    >
                      Install
                    </button>
                  </div>
                </div>

                <div className="mt-6 bg-gray-900 rounded-lg shadow-lg">
                  <div className="p-2 bg-gray-800 text-gray-100 font-semibold flex items-center gap-2">
                    <Terminal size={16} />
                    Installation Log
                  </div>
                  <pre className="p-4 text-sm text-green-400 h-40 overflow-auto font-mono">
                    {output || 'Installation output will appear here...'}
                  </pre>
                </div>
              </div>
            </div>
          )}

          {/* Learn Tab */}
          {activeTab === 'learn' && (
            <div className="p-4 sm:p-6 mx-auto">
              <div className="prose max-w-none">
                <div className="space-y-6">
                  {lesson?.instructions && (
                    <div className="rounded-lg">
                      <div className="text-gray-800">
                        {parse(lesson.instructions, options)}
                      </div>
                    </div>
                  )}

                </div>
              </div>
            </div>
          )}

          {/* Code Tab */}
          {activeTab === 'code' && (
            <div className="flex flex-col lg:flex-row h-[70vh]">
              <div className="flex-1 flex flex-col">
                <div className="p-3 flex items-center gap-3 bg-gray-50 border-b border-gray-200">
                  <Terminal className="text-blue-500" size={20} />
                  <h2 className="text-blue-500 font-bold hidden sm:block">Code Editor</h2>
                  <div className="flex items-center gap-2">
                    <label className="cursor-pointer flex items-center gap-2 text-sm text-gray-600 hover:text-blue-500 transition-colors">
                    <Upload size={16} />
                      <span className="hidden sm:inline">Upload File</span>
                      <input
                        type="file"
                        onChange={handleFileUpload}
                        accept=".csv,.txt,.json,.sql"
                        className="hidden"
                      />
                    </label>
                  </div>
                  <button 
                    onClick={runCode}
                    disabled={!pyodide || isRunning}
                    className="ml-auto px-4 py-1.5 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 transition-colors"
                  >
                    {isRunning ? <Loader className="animate-spin" size={16} /> : <PlayCircle size={16} />}
                    <span className="hidden sm:inline">Run Code</span>
                  </button>
                </div>
                <div className="h-[40vh] lg:h-full">
                  <Editor
                    height="100%"
                    defaultLanguage="python"
                    value={code}
                    onChange={setCode}
                    theme="vs-dark"
                    options={{
                      fontSize: 14,
                      minimap: { enabled: false },
                      scrollBeyondLastLine: false,
                    }}
                  />
                </div>
              </div>
              <div className="h-[30vh] lg:h-auto lg:w-1/3 bg-gray-900">
                <div className="p-2 bg-gray-800 border-b border-gray-700 text-gray-100 font-semibold flex items-center gap-2">
                  <Terminal size={16} />
                  Output
                </div>
                <pre className="p-4 font-mono text-sm overflow-auto h-[calc(100%-40px)] text-green-400 whitespace-pre-wrap">
                  {output || 'Run your code to see output here...'}
                </pre>
              </div>
            </div>
          )}
        </motion.div>
      </main>

      <Footer />
    </div>
  );
};

export default PythonPyodide;
