import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Footer from '../Components/Footer';
import Header from '../Components/Headers/CodeForCambodiaHeader';

// Cache key generator
const getCacheKey = (query, page) => `search_${query}_${page}`;

const SearchPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get('q') || '');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(parseInt(searchParams.get('page') || '1'));
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  // Load from cache on mount
  useEffect(() => {
    const urlQuery = searchParams.get('q');
    const urlPage = parseInt(searchParams.get('page') || '1');
    
    if (urlQuery) {
      setQuery(urlQuery);
      setPage(urlPage);
      
      // Try to load from cache first
      const cachedData = getCachedResults(urlQuery, urlPage);
      if (cachedData) {
        setResults(cachedData.results);
        setTotalPages(cachedData.totalPages);
      } else {
        handleSearch(urlPage);
      }
    }
  }, []);

  // Cache management functions
  const getCachedResults = (searchQuery, pageNum) => {
    try {
      const cacheKey = getCacheKey(searchQuery, pageNum);
      const cached = localStorage.getItem(cacheKey);
      if (cached) {
        const { timestamp, ...data } = JSON.parse(cached);
        // Cache expires after 1 hour
        if (Date.now() - timestamp < 3600000) {
          return data;
        } else {
          localStorage.removeItem(cacheKey);
        }
      }
    } catch (error) {
      console.error('Cache retrieval error:', error);
    }
    return null;
  };

  const cacheResults = (searchQuery, pageNum, data) => {
    try {
      const cacheKey = getCacheKey(searchQuery, pageNum);
      const cacheData = {
        ...data,
        timestamp: Date.now()
      };
      localStorage.setItem(cacheKey, JSON.stringify(cacheData));
      
      // Clean up old cache entries
      cleanupCache();
    } catch (error) {
      console.error('Cache storage error:', error);
    }
  };

  const cleanupCache = () => {
    try {
      const maxCacheEntries = 50; // Limit cache size
      const keys = Object.keys(localStorage)
        .filter(key => key.startsWith('search_'))
        .sort((a, b) => {
          const timeA = JSON.parse(localStorage.getItem(a)).timestamp;
          const timeB = JSON.parse(localStorage.getItem(b)).timestamp;
          return timeB - timeA;
        });

      // Remove oldest entries if cache is too large
      if (keys.length > maxCacheEntries) {
        keys.slice(maxCacheEntries).forEach(key => localStorage.removeItem(key));
      }
    } catch (error) {
      console.error('Cache cleanup error:', error);
    }
  };

  const handleSearch = async (currentPage = page, searchQuery = query) => {
    if (!searchQuery.trim()) {
      setResults([]);
      return;
    }

    // Check cache first
    const cachedData = getCachedResults(searchQuery, currentPage);
    if (cachedData) {
      setResults(cachedData.results);
      setTotalPages(cachedData.totalPages);
      setSearchParams({ q: searchQuery, page: currentPage.toString() });
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `https://codeforcambodia.codes/codecademy/search/?query=${encodeURIComponent(searchQuery.trim())}&page=${currentPage}&limit=10`
      );
      const data = await response.json();
      
      setResults(data.results || []);
      setTotalPages(data.totalPages || 1);
      setSearchParams({ q: searchQuery, page: currentPage.toString() });

      // Cache the results
      cacheResults(searchQuery, currentPage, {
        results: data.results || [],
        totalPages: data.totalPages || 1
      });
    } catch (error) {
      console.error('Search failed:', error);
      setResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPage(1);
      handleSearch(1);
    }
  };

  const handleResultClick = (result) => {
    const searchState = {
      query,
      page,
      results,
      totalPages,
      scrollPosition: window.scrollY
    };
    
    sessionStorage.setItem('lastSearchState', JSON.stringify(searchState));
    localStorage.setItem('lastSearchQuery', query);
    
    navigate(`/search/document/${result.documentId}`);
  };

  useEffect(() => {
    const lastSearchState = sessionStorage.getItem('lastSearchState');
    if (lastSearchState) {
      const { 
        query: lastQuery, 
        page: lastPage, 
        results: lastResults, 
        totalPages: lastTotalPages, 
        scrollPosition 
      } = JSON.parse(lastSearchState);
      
      setQuery(lastQuery);
      setPage(lastPage);
      setResults(lastResults);
      setTotalPages(lastTotalPages);
      setSearchParams({ q: lastQuery, page: lastPage.toString() });
      
      setTimeout(() => {
        window.scrollTo(0, scrollPosition);
      }, 0);
    }
  }, []);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    handleSearch(newPage);
  };

  return (
    <div className="min-h-screen bg-white">
      <Header />
      <div className={`w-full bg-white transition-all duration-300 ${results.length ? 'border-b border-gray-200 ' : ''}`}>
        <div className="max-w-5xl mx-auto px-4 py-8">
          <div className="relative">
            <h1 className="text-3xl font-bold text-center mb-6 Nokora">
              <span className="text-purple-500">ស្វែងរក</span>
              <span className="text-blue-500">មេរៀន</span>
            </h1>

            <div className="relative max-w-2xl mx-auto">
              <div className="relative group">
                {/* Khmer-inspired corner decorations */}
                <div className="absolute -top-2 -left-2 w-8 h-8 border-t-2 border-l-2 border-stone-500"></div>
                <div className="absolute -top-2 -right-2 w-8 h-8 border-t-2 border-r-2 border-stone-500"></div>
                <div className="absolute -bottom-2 -left-2 w-8 h-8 border-b-2 border-l-2 border-stone-500"></div>
                <div className="absolute -bottom-2 -right-2 w-8 h-8 border-b-2 border-r-2 border-stone-500"></div>

                <input
                  type="text"
                  value={query}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  placeholder="ស្វែងរក..."
                  className="w-full px-6 py-4 Nokora text-lg rounded-lg border-2 border-gray-200 
                  focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 
                  shadow-sm group-hover:shadow-md transition-all duration-300
                  bg-white placeholder-gray-400"
                />
                <button
                  onClick={() => {
                    setPage(1);
                    handleSearch(1);
                  }}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 p-3
                  text-gray-400 hover:text-blue-500 transition-colors duration-200
                  focus:outline-none focus:text-blue-600"
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </button>
              </div>
              <p className="text-sm text-gray-500 text-center mt-3 Nokora">
                ស្វែងរកចម្ងល់ដែលអ្នកមាន
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-3xl mx-auto px-4 py-4 min-h-[50vh]">
        {loading ? (
          <div className="flex justify-center py-10">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <div className="space-y-8">
            {results.length > 0 ? (
              results.map((result, index) => (
                <div
                  key={`${result.lessonId}-${index}`}
                  onClick={() => handleResultClick(result)}
                  className="cursor-pointer"
                >
                  <div className="text-sm text-gray-600 mb-1">
                    {result.courseName} › {result.moduleName} › {result.lessonName}
                  </div>
                  <h3 className="text-xl text-blue-600 hover:underline mb-1">{result.lessonName}</h3>
                  <p className="text-sm text-gray-700 Nokora">{result.snippet}</p>
                </div>
              ))
            ) : query && !loading ? (
              <div className="text-center text-gray-600 py-10 Nokora">
                គ្មានលទ្ធផលស្វែងរកទេ
              </div>
            ) : null}

            {totalPages > 1 && results.length > 0 && (
              <div className="flex justify-center space-x-2 mt-8 pb-8">
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i + 1}
                    onClick={() => handlePageChange(i + 1)}
                    className={`p-2 ${
                      page === i + 1
                        ? 'text-blue-500'
                        : 'text-gray-500 hover:text-gray-800'
                    }`}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default SearchPage;