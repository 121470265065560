import React from 'react';

const Connection = ({ connection, fromDevice, toDevice, onContextMenu }) => {
  if (!fromDevice || !toDevice) return null;

  const fromX = fromDevice.position.x;
  const fromY = fromDevice.position.y;
  const toX = toDevice.position.x;
  const toY = toDevice.position.y;

  const connectionNumber = connection.id.split('-').pop();
  const isNumbered = !isNaN(connectionNumber) && connectionNumber > 1;

  const midX = (fromX + toX) / 2;
  const midY = (fromY + toY) / 2;

  // Calculate angle for gradient
  const angle = Math.atan2(toY - fromY, toX - fromX) * 180 / Math.PI;

  return (
    <g>
      <defs>
        <linearGradient id={`gradient-${connection.id}`} gradientTransform={`rotate(${angle} 0.5 0.5)`}>
          <stop offset="0%" stopColor="#2563eb" />
          <stop offset="100%" stopColor="#3b82f6" />
        </linearGradient>
      </defs>
      <line
        x1={fromX}
        y1={fromY}
        x2={toX}
        y2={toY}
        strokeWidth="2.5"
        className="cursor-pointer hover:stroke-blue-400 stroke-gray-400 transition-colors duration-200 pointer-events-auto"
      />
      {isNumbered && (
        <g transform={`translate(${midX},${midY})`}>
          <circle
            r="14"
            fill="white"
            stroke="#3b82f6"
            strokeWidth="2"
            className="shadow-sm"
          />
          <text
            textAnchor="middle"
            dy=".3em"
            fontSize="12"
            fill="#2563eb"
            className="font-medium select-none"
          >
            {connectionNumber}
          </text>
        </g>
      )}
      <rect
        x={fromX - 30}
        y={fromY - 30}
        width="60"
        height="60"
        fill="transparent"
        onContextMenu={(e) => {
          e.stopPropagation();
          onContextMenu(e, 'connection', connection.id);
        }}
        className="pointer-events-auto"
      />
    </g>
  );
};

export default Connection;