import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Code, Lock, BookOpen, ThumbsUp, PlayCircle, ChevronDown, ChevronUp, Rocket, Star, Map, AwardIcon, Award, Link2 } from 'lucide-react';
import { fetchSubscriptionStatus, getLastKnownSubscription } from '../Stripe/fetchSubscriptionStatus';
import { checkCourseAccess } from '../functions/courseAccess';
import PremiumTutorialModal from './PremiumTutorialModal';

function LearningCourses({
  lastLearnedCourse,
  startedCourses,
  recommendedCourses,
  handleCourseClick,
  learningPaths,
  isLoading,
  handlePremiumOpenClose,
}) {
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [activeTab, setActiveTab] = useState('continue');
  const [courseAccess, setCourseAccess] = useState({});
  const [hasCheckedAccess, setHasCheckedAccess] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(() => {
    const cached = getLastKnownSubscription();
    return cached?.hasSubscription || false;
  });

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) return;

        const data = await fetchSubscriptionStatus(token);
        setHasSubscription(data.hasSubscription);
      } catch (err) {
        console.error('Error checking subscription:', err);
      }
    };

    if (!getLastKnownSubscription()) {
      checkSubscription();
    }
  }, []);

  useEffect(() => {
    const checkAllCoursesAccess = async () => {
      try {
        // Check last learned course
        if (lastLearnedCourse) {
          await checkAccess(lastLearnedCourse.course_id);
        }

        // Check all started courses
        for (const course of startedCourses) {
          await checkAccess(course.course_id);
        }

        setHasCheckedAccess(true);
      } catch (error) {
        console.error('Error checking courses access:', error);
        setHasCheckedAccess(true); // Set to true even on error to prevent infinite loading
      }
    };

    checkAllCoursesAccess();
  }, [lastLearnedCourse, startedCourses]);

  const checkAccess = async (courseId) => {
    try {
      const access = await checkCourseAccess(courseId);
      console.log(`access: ${JSON.stringify(access)}`);
      setCourseAccess(prev => ({
        ...prev,
        [courseId]: access
      }));
      console.log(`courseAccess: ${JSON.stringify(courseAccess)}`);
    } catch (error) {
      console.error(`Error checking access for course ${courseId}:`, error);
    }
  };



  const CourseProgressItem = ({ course, buttonColor = "blue" }) => {
    const access = courseAccess[course.course_id] || { isFree: true, hasAccess: true };
    const isPremiumLocked = !access.isFree && !access.hasAccess;

    return (
      <div className="pb-3 sm:pb-4 last:pb-0 relative">
        {/* Premium Badge */}
        {!access.isFree && (
          <div className="absolute top-0 right-0 flex items-center gap-2 bg-white border border-blue-200 text-blue-600 px-3 py-1 rounded-full text-sm shadow-sm">
            <Lock className="w-4 h-4" />
            <span className="Nokora font-medium">Premium</span>
          </div>
        )}

        <h4 className="text-lg font-semibold mb-2 pr-28 Nokora">{course.course_name}</h4>
        <p className="text-base text-gray-600 mb-2">
          <span className="text-orange-600">currentLesson:</span> {course.current_lesson_name}
        </p>
        <div className="w-full bg-gray-200 h-3 rounded-full mb-2">
          <div 
            className={`h-3 rounded-full ${!access.isFree && !access.hasAccess ? 'bg-gray-400' : `bg-${buttonColor}-600`}`}
            style={{ width: `${course.progress_percentage}%` }}
          ></div>
        </div>
        <p className="text-base text-right mb-3 sm:mb-4">{course.progress_percentage}% complete</p>
        
        {!access.isFree && !access.hasAccess ? (
          <Link 
            to={`/modules/${course.course_id}/course`}
            className="inline-flex Nokora items-center justify-center bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded transition duration-300"
          >
            <PlayCircle className="mr-2" /> រៀនបន្តរ
          </Link>
        ) : (
          <Link 
            to={`/courses/${course.course_id}/modules/${course.current_module_id}/lessons/${course.current_lesson_id}`}
            className={`inline-flex Nokora items-center justify-center bg-${buttonColor}-600 hover:bg-${buttonColor}-700 text-white py-2 px-4 rounded transition duration-300`}
          >
            <PlayCircle className="mr-2" /> រៀនបន្តរ
          </Link>
        )}
      </div>
    );
  };

  const truncateDescription = (description, words = 20) => {
    const truncated = description.split(' ').slice(0, words).join(' ');
    return truncated + (description.split(' ').length > words ? '...' : '');
  };

  const toggleDescription = (e, courseId) => {
    e.preventDefault();
    setExpandedDescriptions(prev => ({ ...prev, [courseId]: !prev[courseId] }));
  };

  const pathsWithProgress = learningPaths.filter(path => path.progress && parseFloat(path.progress.overall_progress) > 0);

  const LoadingMessage = () => (
    <div className="flex items-center space-x-2">
      <div className="animate-spin rounded-full h-4 w-4 border-2 border-blue-500 border-t-transparent"></div>
      <p className="text-base text-gray-500">Loading courses...</p>
    </div>
  );

  return (
    <div className="w-full mx-auto rounded-lg overflow-hidden">
      <div className="px-2">
        <h2 className="text-2xl sm:text-3xl font-bold text-blue-600 flex items-center mb-2">
          <Rocket className="mr-2 text-yellow-600" />
          <span className="hidden sm:inline Nokora">ការរៀនខ្ញុំ</span>
          <span className="sm:hidden Nokora">រៀន</span>
        </h2>

        {!hasSubscription && (
          <button
            onClick={handlePremiumOpenClose}
            className="underline text-blue-500 opacity-70 hover:text-blue-700 transition-colors duration-200 text-base flex items-center mb-2"
          >
            <Award className="mr-1 text-yellow-500" size={16} />
            <span className="Nokora">របៀបបាន pro</span>
          </button>
        )}

        <div className="mb-6">
          <div className="sm:hidden">
            <select
              className="block w-full py-2 px-3 border Nokora border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={activeTab}
              onChange={(e) => setActiveTab(e.target.value)}
            >
              <option value="continue">បន្តរការរៀន</option>
              <option value="learningpaths">ផែនទីសិក្សា</option>
              <option value="inprogress">ធ្លាប់រៀន</option>
              <option value="recommended">គួររៀន</option>
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex" aria-label="Tabs">
                {['continue', 'learningpaths', 'inprogress', 'recommended'].map((tab) => (
                  <button
                    key={tab}
                    className={`whitespace-nowrap Nokora mr-4 py-4 px-1 border-b-2 font-medium text-sm ${
                      activeTab === tab
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab === 'continue' && 'បន្តរការរៀន'}
                    {tab === 'learningpaths' && 'ផែនទីសិក្សា'}
                    {tab === 'inprogress' && 'ធ្លាប់រៀន'}
                    {tab === 'recommended' && 'គួររៀន'}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {activeTab === 'continue' && (
          <div>
            <h3 className=" font-semibold mb-3 text-blue-600 flex items-center">
              <Code className="mr-2" />
              <span className="hidden sm:inline Nokora">បន្តការរៀន() {'{'}</span>
              <span className="sm:hidden Nokora">បន្ត {'{'}</span>
            </h3>
            <div className="pl-4 sm:pl-6 border-l-2 border-blue-200">
              {isLoading ? (
                <LoadingMessage />
              ) : lastLearnedCourse ? (
                <CourseProgressItem course={lastLearnedCourse} buttonColor="blue" />
              ) : (
                <Link 
                to="/courses" 
                className="inline-flex items-center text-gray-600 hover:text-gray-400 transition-colors duration-200 underline Nokora"
                >
                  <Link2 className="mr-2" />
                  មិនទាន់បានរៀន ចុចលើខ្ញុំ
                </Link>
              )}
            </div>
            <p className="mt-2 text-lg font-semibold text-blue-600">{'}'}</p>
          </div>
        )}

        {activeTab === 'inprogress' && (
          <div>
            <h3 className="font-semibold mb-3 text-green-600 flex items-center">
              <BookOpen className="mr-2" />
              <span className="hidden sm:inline Nokora">ថ្នាក់ធ្លាប់រៀន() {'{'}</span>
              <span className="sm:hidden Nokora">ធ្លាប់រៀន {'{'}</span>
            </h3>
            <div className="pl-4 sm:pl-6 border-l-2 border-green-200">
              {isLoading ? (
                <LoadingMessage />
              ) : startedCourses.length > 0 ? (
                <ul className="space-y-4 sm:space-y-6">
                  {startedCourses.map(course => (
                    <CourseProgressItem 
                      key={course.course_id} 
                      course={course} 
                      buttonColor="green"
                    />
                  ))}
                </ul>
              ) : (
                <Link 
                  to="/courses" 
                  className="inline-flex items-center text-gray-600 hover:text-gray-400 transition-colors duration-200 underline Nokora"
                >
                  <Link2 className="mr-2" />
                  មិនទាន់បានរៀនខ្ញុំទេ ចុចលើខ្ញុំ
                </Link>
              )}
            </div>
            <p className="mt-2 text-lg font-semibold text-green-600">{'}'}</p>
          </div>
        )}

        {activeTab === 'recommended' && (
          <div>
            <h3 className=" font-semibold mb-3 text-slate-700 flex items-center">
              <ThumbsUp className="mr-2" />
              <span className="hidden sm:inline Nokora">គួរតែរៀន() {'{'}</span>
              <span className="sm:hidden Nokora">គួររៀន {'{'}</span>
            </h3>
            <div className="pl-4 sm:pl-6 border-l-2 border-purple-200">
              {isLoading ? (
                <LoadingMessage />
              ) : recommendedCourses.length > 0 ? (
                <ul className="space-y-4">
                  {recommendedCourses.map((course, index) => (
                    <li 
                      key={course.course_details?.id || course.courseName} 
                      className="border-l-2 border-purple-300 pl-3 "
                    >
                      <div onClick={() => handleCourseClick(course.course_details)} className="cursor-pointer">
                        <h4 className="text-lg font-semibold text-slate-800 mb-2">
                          {index === 0 && "🥇 "}
                          {index === 1 && "🥈 "}
                          {index === 2 && "🥉 "}
                          {index >= 3 && "✨ "}
                          {course.courseName}
                        </h4>
                        <p className='text-base text-slate-600 font-mono mb-2'>
                          // {course.reasoning}
                        </p>
                        {course.course_details && (
                          <p className='text-base text-slate-500 Nokora'>
                            /* {expandedDescriptions[course.course_details.id] 
                              ? course.course_details.description 
                              : truncateDescription(course.course_details.description)} */
                          </p>
                        )}
                      </div>

                      {course.course_details?.description && 
                       course.course_details.description.split(' ').length > 20 && (
                        <button
                          onClick={(e) => toggleDescription(e, course.course_details.id)}
                          className="text-slate-600 hover:text-slate-800 mt-2 flex items-center font-mono text-sm"
                        >
                          {expandedDescriptions[course.course_details.id] ? (
                            <>
                              .collapse() <ChevronUp className="ml-1 w-4 h-4" />
                            </>
                          ) : (
                            <>
                              .expand() <ChevronDown className="ml-1 w-4 h-4" />
                            </>
                          )}
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-base text-slate-500 font-mono">// No recommended courses at the moment 🎯</p>
              )}
            </div>
            <p className="mt-2 text-lg font-semibold text-slate-700">{'}'}</p>
          </div>
        )}

        {activeTab === 'learningpaths' && (
          <div>
            <h3 className="font-semibold mb-3 text-indigo-600 flex items-center">
              <Map className="mr-2" />
              <span className="hidden sm:inline Nokora">ផែនទីសិក្សា() {'{'}</span>
              <span className="sm:hidden Nokora">ផែនទី {'{'}</span>
            </h3>
            <div className="pl-4 sm:pl-6 border-l-2 border-indigo-200">
              {isLoading ? (
                <LoadingMessage />
              ) : pathsWithProgress.length > 0 ? (
                <ul className="space-y-4 sm:space-y-6">
                  {pathsWithProgress.map(path => (
                    <li key={path.id} className="pb-3 sm:pb-4 last:pb-0">
                      <h4 className="text-lg font-semibold mb-2 Nokora">{path.learning_path_name}</h4>
                      <p className='text-base text-gray-600 Nokora mb-2'>
                        {expandedDescriptions[path.id] ? path.description : truncateDescription(path.description)}
                      </p>
                      {path.description && path.description.split(' ').length > 20 && (
                        <button
                          onClick={(e) => toggleDescription(e, path.id)}
                          className="text-indigo-600 hover:text-indigo-800 mb-2 flex items-center Nokora"
                          aria-expanded={expandedDescriptions[path.id]}
                          aria-controls={`description-${path.id}`}
                        >
                          {expandedDescriptions[path.id] ? (
                            <>
                              បន្ថយ <ChevronUp className="ml-1" />
                            </>
                          ) : (
                            <>
                              អានបន្ថែម <ChevronDown className="ml-1" />
                            </>
                          )}
                        </button>
                      )}
                      <p className="text-base text-gray-600 mb-2">
                        <span className="text-orange-600">Current Course:</span> {path.progress.current_course_name}
                      </p>
                      <div className="w-full bg-gray-200 h-3 rounded-full mb-2">
                        <div 
                          className="bg-indigo-600 h-3 rounded-full" 
                          style={{ width: `${path.progress.overall_progress}%` }}
                        ></div>
                      </div>
                      <p className="text-base text-right mb-3 sm:mb-4">{path.progress.overall_progress}% complete</p>
                      <Link 
                        to={`/learning-path/${path.id}`}
                        className="inline-flex Nokora items-center justify-center bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded transition duration-300"
                      >
                        <PlayCircle className="mr-2" /> បន្តរៀន
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : (
                <Link 
                  to="/all-learning-paths" 
                  className="inline-flex items-center text-gray-600 hover:text-gray-400 transition-colors duration-200 underline Nokora"
                  >
                  <Link2 className="mr-2" />
                      មិនទាន់បានរៀន ចុចលើខ្ញុំ
                </Link>
              )}
            </div>
            <p className="mt-2 text-xl font-semibold text-indigo-600">{'}'}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default LearningCourses;