import React, { useEffect } from 'react';
import { LockKeyhole, ArrowRight, Terminal } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      const timer = setTimeout(() => {
        navigate('/login');
      }, 5000);
      
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div className="min-h-screen bg-white font-mono flex flex-col items-center justify-center p-4">
      <div className="max-w-2xl w-full space-y-8">
        {/* Code Editor Header */}
        <div className="bg-gray-100 border border-gray-200 rounded-t-lg">
          <div className="flex items-center px-4 py-2 border-b border-gray-200">
            <div className="flex space-x-2">
              <div className="w-3 h-3 rounded-full bg-[#FF5F56] border border-[#E0443E]"></div>
              <div className="w-3 h-3 rounded-full bg-[#FFBD2E] border border-[#DEA123]"></div>
              <div className="w-3 h-3 rounded-full bg-[#27C93F] border border-[#1AAB29]"></div>
            </div>
            <span className="ml-4 text-sm text-gray-600">unauthorized.tsx</span>
          </div>
          
          {/* Code-like Content */}
          <div className="p-8 bg-white border-t border-gray-200">
            <div className="space-y-6">
              <div className="flex items-center text-red-600">
                <Terminal className="h-5 w-5 mr-2" />
                <span className="text-sm">Error: Authentication required</span>
              </div>
              
              <div className="space-y-2">
                <div className="text-gray-800">
                  <span className="text-blue-600">const</span> <span className="text-purple-600">authStatus</span> = {
                  <span className="text-orange-600">'unauthorized'</span>};
                </div>
                <div className="text-gray-800">
                  <span className="text-blue-600">const</span> <span className="text-purple-600">message</span> = {
                  <span className="text-orange-600 Nokora">'អ្នកត្រូវការគណនីដើម្បីប្រើប្រាស់នេះ។'</span>};
                </div>
              </div>

              {/* Progress Bar */}
              <div className="text-gray-800">
                <span className="text-green-600">// Redirecting in 5 seconds...</span>
                <div className="mt-2 h-1 w-full bg-gray-200 rounded">
                  <div className="h-1 bg-blue-500 rounded animate-[progress_5s_ease-in-out]" />
                </div>
              </div>

              {/* Login Button */}
              <div className="text-gray-800">

                <button
                  onClick={() => navigate('/login')}
                  className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                >
                  <span className="text-sm">/login</span>
                  <ArrowRight className="ml-2 h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedPage;