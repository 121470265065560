import React, { useState } from 'react';
import { Router, Monitor, HardDriveIcon } from 'lucide-react';

const InterfaceStatusList = ({ interfaces }) => (
  <div className="divide-y divide-gray-100 max-h-[60vh] z-[100] overflow-y-auto">
    {interfaces.map((intf, idx) => (
      <div key={idx} className="py-3 px-1 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div
            className={`w-2.5 h-2.5 rounded-full ${
              intf.status === 'up' ? 'bg-green-500' : 
              intf.status === 'down' ? 'bg-red-500' : 
              'bg-gray-400'
            }`}
          />
          <span className="font-medium text-sm text-gray-900">{intf.name}</span>
        </div>
        <div className="flex flex-col items-end">
          <span className="text-sm text-gray-600">
            {intf.status.toUpperCase()}
          </span>
          {intf.ip && (
            <span className="text-xs text-gray-500">
              IP: {intf.ip}
            </span>
          )}
          {intf.vlan && (
            <span className="text-xs text-gray-500">
              VLAN: {intf.vlan}
            </span>
          )}
        </div>
      </div>
    ))}
  </div>
);

const InterfaceStatusDots = ({ interfaces }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  const statuses = Object.entries(interfaces || {}).map(([name, info]) => ({
    name,
    status: info.status,
    ...(info.ip && { ip: info.ip }),
    ...(info.vlan && { vlan: info.vlan })
  }));

  const maxVisible = 3;
  const total = statuses.length;
  const displayDots = statuses.slice(0, maxVisible);
  const remaining = total - maxVisible;
  const remainingInterfaces = statuses.slice(maxVisible);

  return (
    <>
      <div className="flex items-center gap-1.5 mt-2">
        {displayDots.map((intf, idx) => (
          <div
            key={idx}
            className="group relative"
          >
            <div
              className={`w-2.5 h-2.5 rounded-full transition-all duration-200 
                ${intf.status === 'up' ? 'bg-green-500 hover:bg-green-600' : 
                  intf.status === 'down' ? 'bg-red-500 hover:bg-red-600' : 
                  'bg-gray-400 hover:bg-gray-500'
                } shadow-sm`}
            />
            <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-xs py-1 px-2 rounded -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap z-50">
              {`${intf.name}: ${intf.status}${intf.ip ? ` (${intf.ip})` : ''}${intf.vlan ? ` VLAN:${intf.vlan}` : ''}`}
            </div>
          </div>
        ))}
        {remaining > 0 && (
          <button
            onClick={() => setIsDialogOpen(true)}
            className="group relative"
          >
            <div className="w-5 h-5 rounded-full bg-gray-200 hover:bg-gray-300 flex items-center justify-center text-[10px] font-medium text-gray-600 cursor-pointer transition-colors duration-200">
              +{remaining}
            </div>
            <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-xs py-1 px-2 rounded -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap z-50">
              Click to view all interfaces
            </div>
          </button>
        )}
      </div>

      {isDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex z-20 items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Interface Status</h3>
              <button 
                onClick={() => setIsDialogOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
            </div>
            <div className="mt-2">
              <div className="mb-4 pb-3 border-b">
                <div className="text-sm text-gray-500">
                  Active Interfaces
                </div>
                <InterfaceStatusList interfaces={displayDots} />
              </div>
              {remaining > 0 && (
                <div>
                  <div className="text-sm text-gray-500 mb-2">
                    Additional Interfaces
                  </div>
                  <InterfaceStatusList interfaces={remainingInterfaces} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Device = ({ 
  device, 
  connectionMode, 
  connectionStart, 
  onDeviceClick, 
  onDeviceDoubleClick,
  onDragStart,
  onDragEnd,
  onContextMenu
}) => {
  const getDeviceIcon = (type) => {
    switch (type) {
      case 'router': return <Router className="w-8 h-8 text-blue-600" />;
      case 'switch': return <HardDriveIcon className="w-8 h-8 text-green-600" />;
      case 'pc': return <Monitor className="w-8 h-8 text-purple-600" />;
      default: return null;
    }
  };

  return (
    <div
      className={`absolute w-24 z-[10] h-24 flex flex-col items-center justify-center bg-white rounded-xl border-2
        ${connectionMode ? 'border-yellow-400 cursor-pointer shadow-yellow-100' : 'border-gray-200'} 
        ${connectionStart === device.id ? 'border-green-400 bg-green-50 shadow-green-100' : ''}
        transition-all duration-200 hover:bg-gray-50 hover:shadow-md shadow-sm`}
      style={{
        left: device.position.x - 48,
        top: device.position.y - 48,
      }}
      onClick={(e) => onDeviceClick(e, device.id)}
      onDoubleClick={(e) => {
        e.stopPropagation();
        onDeviceDoubleClick(device);
      }}
      draggable={!connectionMode}
      onDragStart={(e) => onDragStart(e, device)}
      onDragEnd={onDragEnd}
      onContextMenu={(e) => onContextMenu(e, 'device', device.id)}
    >
      <div className="flex flex-col items-center gap-1">
        {getDeviceIcon(device.type)}
        <span className="text-xs font-medium text-gray-700 max-w-[80px] truncate">
          {device.config?.hostname || device.type}
        </span>
      </div>
      <InterfaceStatusDots interfaces={device.interfaces} />
    </div>
  );
};

export default Device;