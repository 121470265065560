import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const CourseErrorModal = ({ showModal, setShowModal, courseAccessStatus, onContinueCourse, userProgress }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/subscription');
    setCopied(true);
  };

  if (!showModal) return null;

  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center z-50 p-4"
          onClick={() => setShowModal(false)}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            transition={{ type: 'spring', stiffness: 300, damping: 25 }}
            className="bg-white rounded-2xl shadow-xl w-full max-w-md overflow-hidden border border-gray-200"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="p-6 space-y-6">
              <div className="flex justify-between items-center">
                <h3 className="text-2xl font-bold text-gray-900 Nokora">
                  {!courseAccessStatus.isFree && !courseAccessStatus.hasAccess
                    ? 'មេរៀនគឺជាប្រភេទ Premium'
                    : 'មេរៀនទាន់រៀនដល់ទេ'}
                </h3>
                <button
                  onClick={() => setShowModal(false)}
                  className="text-gray-400 hover:text-gray-600 transition-colors duration-200"
                >
                  <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <p className="text-gray-600 Nokora">
                {!courseAccessStatus.isFree && !courseAccessStatus.hasAccess
                  ? 'សុំជួយបង់ប្រាក់ដើម្បីបាន pro ព្រោះវាជួយលើការស្រាវជ្រាវ និងមេរៀនថ្មីៗសម្រាប់ខ្មែរគ្រប់រូប!'
                  : 'សុំរៀនតាមលំដាប់លំដោយ អត់អាចរៀនរំលងបានទេ!'}
              </p>
              {!courseAccessStatus.isFree && !courseAccessStatus.hasAccess ? (
                <div className="space-y-4">
                  <motion.div
                    className="relative h-48 w-full rounded-xl overflow-hidden bg-gray-100"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    <video
                      className="w-full h-full object-cover"
                      controls
                      autoPlay
                    >
                      <source src="https://codeforcambodia.s3.us-east-005.backblazeb2.com/subscriptionCompressed.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </motion.div>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handleClick}
                    className={`w-full px-4 py-3 rounded-xl font-medium transition-all duration-300 Nokora ${
                      copied 
                        ? 'bg-green-100 text-green-700 border border-green-200' 
                        : 'bg-blue-100 text-blue-700 border border-blue-200 hover:bg-blue-50'
                    }`}
                  >
                    <span className="flex items-center justify-center">
                      {copied ? (
                        <>
                          <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          កំពុងដំណើរការ!
                        </>
                      ) : (
                        <>
                          <div className="flex flex-col items-center">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                              </svg>
                              បង់ប្រាក់
                            </div>
                          </div>
                        </>
                      )}
                    </span>
                  </motion.button>
                  <a 
                    href="https://t.me/codeforcambodia_support" 
                    target="_blank"
                    rel="noopener noreferrer" 
                    className="block text-center text-blue-600 hover:text-blue-800 transition-colors duration-200 text-sm Nokora font-medium"
                  >
                    <span className="flex items-center justify-center">
                      <svg className="w-4 h-4 mr-1" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.562 8.161c-.18 1.897-.962 6.502-1.359 8.627-.168.9-.5 1.201-.82 1.23-.697.064-1.226-.461-1.901-.903-1.056-.692-1.653-1.123-2.678-1.799-1.185-.781-.417-1.21.258-1.911.177-.184 3.247-2.977 3.307-3.23.007-.032.015-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.139-5.062 3.345-.479.329-.913.489-1.302.481-.428-.009-1.252-.242-1.865-.442-.751-.244-1.349-.374-1.297-.789.027-.216.324-.437.892-.663 3.498-1.524 5.831-2.529 6.998-3.015 3.333-1.386 4.025-1.627 4.477-1.635.099-.002.321.023.465.141.121.099.195.231.214.375.012.094.027.271.015.417z"/>
                      </svg>
                      ទាក់ទងតាម Telegram
                    </span>
                  </a>
                </div>
              ) : (
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => {
                    setShowModal(false);
                    onContinueCourse();
                  }}
                  className="w-full px-4 py-3 bg-blue-100 text-blue-700 border border-blue-200 hover:bg-blue-50 rounded-xl font-medium transition-all duration-300 Nokora"
                >
                  {userProgress ? 'រៀនបន្តរ' : 'ចាប់ផ្តើមរៀន'}
                </motion.button>
              )}
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setShowModal(false)}
                className="w-full px-4 py-3 bg-gray-100 text-gray-700 border border-gray-200 rounded-xl font-medium hover:bg-gray-50 transition-all duration-300 Nokora"
              >
                បិទ
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CourseErrorModal;