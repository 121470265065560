import React, { useEffect, useState } from 'react';

export default function ReactNativePreview({ output }) {
  const [previewUrl, setPreviewUrl] = useState('');

  useEffect(() => {
    const code = encodeURIComponent(output || `
import React from 'react';
import { View, Text } from 'react-native';

export default function App() {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>Start editing to see your changes</Text>
    </View>
  );
}`);

    setPreviewUrl(`https://snack.expo.dev/embedded?platform=web&name=Preview&description=React%20Native%20Preview&code=${code}`);
  }, [output]);

  return (
    <div className="w-full h-[70vh] rounded-lg overflow-hidden border border-gray-200 bg-white">
      {previewUrl && (
        <iframe
          src={previewUrl}
          className="w-full h-full border-none"
          allow="geolocation; camera; microphone"
        />
      )}
    </div>
  );
}