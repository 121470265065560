'use client'

import React, { useState } from 'react';
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader';
import Footer from '../../Components/Footer';
import { Terminal, Code, Play, Copy as CopyIcon } from 'lucide-react';
import parse from 'html-react-parser';
import FlutterCompiler from '../../Components/Lessons/Fluter/FlutterCompiler';
import FreeTrialHeader from '../../Components/Headers/FreeTrialHeader';
import options from '../../Components/Lessons/OptionsQuill';

const Button = React.memo(({ children, onClick, className, icon: Icon }) => (
  <button
    onClick={onClick}
    className={`px-2 sm:px-3 py-1 sm:py-2 rounded-md text-xs sm:text-sm font-medium transition-colors duration-150 flex items-center ${className}`}
  >
    {Icon && <Icon size={14} className="mr-1 sm:mr-2" />}
    {children}
  </button>
));

export default function FlutterLesson({ lesson = {}, isSubscribed }) {
  const [activeSection, setActiveSection] = useState('instructions');
  const [copyStatus, setCopyStatus] = useState('Copy');
  
  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyStatus('Copied!');
      setTimeout(() => setCopyStatus('Copy'), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy:', err);
      setCopyStatus('Failed to copy');
      setTimeout(() => setCopyStatus('Copy'), 2000);
    }
  };

  const code = `hello`
  // Create the encoded source code
  const encodedCode = lesson?.code ? btoa(lesson.code) : 'aW1wb3J0ICdwYWNrYWdlOmZsdXR0ZXIvbWF0ZXJpYWwuZGFydCc7Cgp2b2lkIG1haW4oKSB7CiAgcnVuQXBwKE15QXBwKCkpOwp9CgpjbGFzcyBNeUFwcCBleHRlbmRzIFN0YXRlbGVzc1dpZGdldCB7CiAgQG92ZXJyaWRlCiAgV2lkZ2V0IGJ1aWxkKEJ1aWxkQ29udGV4dCBjb250ZXh0KSB7CiAgICByZXR1cm4gTWF0ZXJpYWxBcHAoCiAgICAgIGhvbWU6IFNjYWZmb2xkKAogICAgICAgIGJvZHk6IENlbnRlcigKICAgICAgICAgIGNoaWxkOiBUZXh0KCdIZWxsbyBXb3JsZCcpLAogICAgICAgICksCiAgICAgICksCiAgICApOwogIH0KfQ==';

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      {isSubscribed ? <CodeForCambodiaHeader /> : <FreeTrialHeader/>}
      <header className="bg-gray-100 p-2 flex items-center space-x-2 border-b border-gray-200 sticky top-0 z-10">
        <div className="flex space-x-1">
          <div className="w-3 h-3 rounded-full bg-red-500"></div>
          <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
          <div className="w-3 h-3 rounded-full bg-green-500"></div>
        </div>
        <h1 className="text-xs sm:text-sm flex-grow text-center font-semibold">
          {lesson?.lesson_name || 'Untitled Lesson'}
        </h1>
      </header>

      <div className="flex flex-grow">
        <nav className="bg-gray-100 w-12 md:w-48 flex flex-col border-r border-gray-200 sticky">
          {[
            { id: 'instructions', icon: Terminal, label: 'Instructions' },
            { id: 'code', icon: Code, label: 'Code' },
            { id: 'expected', icon: Play, label: 'Expected' }
          ].map(({ id, icon: Icon, label }) => (
            <Button
              key={id}
              onClick={() => setActiveSection(id)}
              className={`justify-start w-full py-2 sm:py-3 ${
                activeSection === id ? 'bg-blue-100 text-blue-600' : 'text-gray-600 hover:bg-gray-200'
              }`}
              icon={Icon}
            >
              <span className="hidden md:inline">{label}</span>
            </Button>
          ))}
        </nav>

        <main className="flex-grow">
          {activeSection === 'instructions' && (
            <div className="prose prose-sm sm:prose lg:prose-lg p-4 max-w-none Nokora">
              {lesson?.instructions ? parse(lesson.instructions, options) : (
                <p className="text-gray-600 italic">No instructions available for this lesson.</p>
              )}
              {lesson?.instructions ? 
              <>
                {lesson?.starter_code && (
                  <div className="mt-4 relative">
                    <h3 className="font-semibold text-gray-800 mb-2">Starter Code:</h3>
                    <pre className="bg-gray-50 p-4 rounded-lg border border-gray-200 overflow-x-auto">
                      <code className="text-sm font-mono text-gray-800">
                        {lesson.starter_code}
                      </code>
                    </pre>
                    <button 
                      onClick={() => handleCopy(lesson.starter_code)}
                      className="absolute top-10 right-2 px-3 py-1 text-sm text-gray-600 hover:text-gray-800 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors bg-white shadow-sm flex items-center gap-1"
                    >
                      <CopyIcon size={14} />
                      {copyStatus}
                    </button>
                  </div>
                )}
              </> : null}
            </div>
          )}

          {activeSection === 'code' && (
            <div className="">
              <iframe
                style={{width: '100%', height: '600px'}}
                src={`https://dartpad.dev/embed-flutter.html?source=${encodedCode}&theme=light`}
                title="Flutter Playground"
                className="bg-white"
              />
            </div>
          )}

          {activeSection === 'expected' && (
            <FlutterCompiler />
          )}
        </main>
      </div>
      <Footer />
    </div>
  );
}
