import { createContext, useContext, useState, useCallback } from 'react';
import alasql from 'alasql';
import { express, Request } from './expressSimulator';  // Remove Router from import
import { db } from './dbUtils';  // Add this import

const SimulationContext = createContext();

export const SimulationProvider = ({ children }) => {
  const [files, setFiles] = useState({
    '/': {
      type: 'folder',
      name: '/',
      children: {
        'server.js': {
          type: 'file',
          content: `const express = require('express');
const app = express();

app.get('/', (req, res) => {
  res.send('Hello World!');
});

app.listen(3000, () => {
  console.log('Server running on port 3000');
});`,
        },
        'package.json': {
          type: 'file',
          content: `{
  "name": "express-app",
  "version": "1.0.0",
  "dependencies": {
    "express": "^4.17.1"
  }
}`
        }
      }
    }
  });

  const [serverState, setServerState] = useState({
    running: false,
    instance: null,
    logs: [],
    port: null
  });

  // File system operations
  const createFile = useCallback((path) => {
    setFiles(prev => {
      const newFiles = { ...prev };
      const parts = path.split('/').filter(Boolean);
      let current = newFiles['/'];
      
      // Create parent directories if they don't exist
      for (let i = 0; i < parts.length - 1; i++) {
        if (!current.children[parts[i]]) {
          current.children[parts[i]] = {
            type: 'folder',
            name: parts[i],
            children: {}
          };
        }
        current = current.children[parts[i]];
      }
      
      // Create the file
      const fileName = parts[parts.length - 1];
      current.children[fileName] = {
        type: 'file',
        name: fileName,
        content: ''
      };
      
      return newFiles;
    });
  }, []);

  const updateFile = useCallback((path, content) => {
    setFiles(prev => {
      const newFiles = { ...prev };
      const parts = path.split('/').filter(Boolean);
      let current = newFiles['/'];
      
      for (let i = 0; i < parts.length - 1; i++) {
        current = current.children[parts[i]];
        if (!current) {
          throw new Error(`Directory not found: ${parts[i]}`);
        }
      }
      
      const fileName = parts[parts.length - 1];
      if (!current.children[fileName]) {
        throw new Error(`File not found: ${fileName}`);
      }
      
      current.children[fileName].content = content;
      return newFiles;
    });
  }, []);

  const deleteFile = useCallback((path) => {
    setFiles(prev => {
      const newFiles = { ...prev };
      const parts = path.split('/').filter(Boolean);
      let current = newFiles['/'];
      
      for (let i = 0; i < parts.length - 1; i++) {
        current = current.children[parts[i]];
        if (!current) {
          throw new Error(`Directory not found: ${parts[i]}`);
        }
      }
      
      const fileName = parts[parts.length - 1];
      delete current.children[fileName];
      return newFiles;
    });
  }, []);

  const startServer = useCallback(() => {
    try {
      const serverCode = files['/'].children['server.js'].content;
      let lastAppInstance = null;
  
      // Create require context
      const createRequireContext = () => {
        const requireFn = (module) => {
          if (module === 'express') {
            // Create a wrapper function that captures the last instance
            const wrapper = () => {
              const instance = express();
              lastAppInstance = instance;
              return instance;
            };
            
            // Copy all properties from express to our wrapper
            Object.assign(wrapper, express);
            return wrapper;
          }
          
          // Add alasql support
          if (module === 'alasql') {
            return {
              ...alasql,
              db // Expose our database manager instance
            };
          }
          
          // Handle local files
          if (module.startsWith('./')) {
            const fileName = module.replace('./', '').replace('.js', '') + '.js';
            const fileContent = files['/'].children[fileName]?.content;
            
            if (!fileContent) {
              throw new Error(`Module not found: ${module}`);
            }
            
            const exports = {};
            const moduleObj = { exports };
            const localContext = {
              require: requireFn,
              console: moduleContext.console,
              exports,
              module: moduleObj
            };
            
            const localFn = new Function(
              'require', 
              'console', 
              'exports', 
              'module', 
              fileContent
            );
            
            localFn(
              localContext.require,
              localContext.console,
              localContext.exports,
              localContext.module
            );
            
            return moduleObj.exports;
          }
          throw new Error(`Module not found: ${module}`);
        };
        return requireFn;
      };
  
      const moduleContext = {
        require: createRequireContext(),
        console: {
          log: (...args) => {
            setServerState(prev => ({
              ...prev,
              logs: [...prev.logs, args.join(' ')]
            }));
          }
        }
      };
  
      // Execute the server code
      const fn = new Function('require', 'console', serverCode);
      fn(moduleContext.require, moduleContext.console);
  
      setServerState(prev => ({
        ...prev,
        running: true,
        instance: lastAppInstance,
        logs: [...prev.logs]
      }));
  
    } catch (error) {
      console.error('Server start error:', error);
      setServerState(prev => ({
        ...prev,
        running: false,
        instance: null,
        logs: [...prev.logs, `Error starting server: ${error.message}`]
      }));
    }
  }, [files]);
  

  const stopServer = useCallback(() => {
    setServerState(prev => ({
      ...prev,
      running: false,
      instance: null,
      logs: [...prev.logs, 'Server stopped']
    }));
  }, []);

  const simulateRequest = useCallback(async (config) => {
    console.log('simulateRequest called:', config);
    if (!serverState.running || !serverState.instance) {
      console.log('Server state:', serverState); // Add this
      return { 
        status: 500,
        body: 'Server is not running',
        headers: {}
      };
    }
  
    try {
      // Create request object
      const req = new Request(
        config.method || 'GET',
        config.url || '/',
        config.body,
        config.headers || {}
      );
      console.log('Created request:', req); // Add this
  
      // Run through the Express instance and get the response
      const response = await serverState.instance.handle(req);
      console.log('Server response:', response); // Add this
      return response;
  
    } catch (error) {
      console.error('Request error:', error);
      return {
        status: 500,
        body: error.message,
        headers: {}
      };
    }
  }, [serverState]);

  const value = {
    files,
    serverState,
    createFile,
    updateFile,
    deleteFile,
    startServer,
    stopServer,
    simulateRequest
  };

  return (
    <SimulationContext.Provider value={value}>
      {children}
    </SimulationContext.Provider>
  );
};

export const useSimulation = () => {
  const context = useContext(SimulationContext);
  if (!context) {
    throw new Error('useSimulation must be used within a SimulationProvider');
  }
  return context;
};