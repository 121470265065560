import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function ManageSubscriptionPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectToStripePortal = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }

        const response = await fetch('https://codeforcambodia.codes/codecademy/stripe/create-portal-session', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to create portal session');
        }

        const { url } = await response.json();
        window.location.href = url;
      } catch (err) {
        console.error('Error:', err);
        // If there's an error, redirect to subscription page
        navigate('/subscription');
      }
    };

    redirectToStripePortal();
  }, [navigate]);

  // Return null since we're redirecting immediately
  return null;
}