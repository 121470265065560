'use client'

import React, { useState } from 'react'
import PHPLessonModern from '../../Components/Lessons/PHP/PHPLessonModern'
import PHPLessonRetro from '../../Components/Lessons/PHP/PHPLessonRetro'

export default function PHPLesson({ lesson, isSubscribed }) {
  const [design, setDesign] = useState('modern')

  const toggleDesign = () => {
    setDesign((prevDesign) => (prevDesign === 'modern' ? 'retro' : 'modern'))
  }


  // Shared props for both components
  const designProps = {
    lesson,
    currentDesign: design,
    onToggleDesign: toggleDesign,
    isModern: design === 'modern',
    isRetro: design === 'retro',
    isSubscribed
  }

  return (
    <div className="min-h-screen flex flex-col">
      {/* The toggle button is now handled by the individual components */}
      {design === 'modern' ? (
        <PHPLessonModern {...designProps} />
      ) : (
        <PHPLessonRetro {...designProps} />
      )}
    </div>
  )
}