export default class InterfaceCommands {
    constructor(networkState) {
        this.networkState = networkState;
        
    }

    canHandle(cmd) {
        return [
            'interface', 'ip', 'shutdown', 'no', 'description', 'speed',
            'duplex', 'ping', 'ipconfig', 'exit', 'dhcp'
        ].includes(cmd);
    }

    execute(cmd, args, deviceId) {
        const device = this.networkState.devices.get(deviceId);
        const mode = this.networkState.configMode.get(deviceId);
    
        // Handle ip route command in config mode
        if (cmd === 'ip' && args[0] === 'route' && mode.mode === 'config') {
            return this.handleIpRoute(args.slice(1), deviceId);
        }
    
        // Add ping command handling for all device types
        if (cmd === 'ping') {
            return this.handlePing(args, deviceId);
        }
    
        // Special handling for PC devices
        if (device.type === 'pc') {
            switch (cmd) {
                case 'ipconfig':
                    return this.handleIpConfig(deviceId);
                case 'ip':
                    return this.handlePcIpCommand(args, deviceId);
                case 'ping':
                    return this.handlePing(args, deviceId);
                default:
                    return {
                        error: 'Command not supported on PC devices',
                        input: `${cmd} ${args.join(' ')}`,
                        prompt: device.name
                    };
            }
        }
    
        // If in interface mode, only allow interface-specific commands
        if (mode.mode === 'config-if') {
            switch (cmd) {
                case 'ip':
                    if (args[0] !== 'route') {  // Don't allow ip route in interface mode
                        return this.handleIpCommand(args, deviceId);
                    } else {
                        return {
                            error: 'Invalid command in interface configuration mode',
                            input: `${cmd} ${args.join(' ')}`
                        };
                    }
                case 'shutdown':
                    return this.handleShutdown(args, deviceId);
                case 'no':
                    return this.handleNo(args, deviceId);
                case 'exit':
                    return this.handleExit(deviceId);
                case 'interface':
                    return this.handleInterface(args, deviceId);
                case 'description':
                    return this.handleDescription(args, deviceId);
                case 'speed':
                    return this.handleSpeed(args, deviceId);
                case 'duplex':
                    return this.handleDuplex(args, deviceId);
                default:
                    return { error: 'Unknown interface command' };
            }
        }
    
        // Handle commands in 'config' mode
        if (mode.mode === 'config') {
            switch (cmd) {
                case 'interface':
                    return this.handleInterface(args, deviceId);
                case 'ip':
                    if (args[0] === 'route') {
                        return this.handleIpRoute(args.slice(1), deviceId);
                    } else {
                        return {
                            error: 'Invalid command in configuration mode',
                            input: `${cmd} ${args.join(' ')}`
                        };
                    }
                case 'exit':
                    return this.handleExit(deviceId);
                default:
                    return { error: 'Unknown configuration command' };
            }
        }
    
        // If in privileged mode
        if (mode.mode === 'privileged') {
            switch (cmd) {
                case 'configure':
                    if (args[0] === 'terminal') {
                        this.networkState.configMode.set(deviceId, { mode: 'config' });
                        return {
                            success: true,
                            output: '',
                            input: 'configure terminal',
                            prompt: this.networkState.getCommandPrompt(deviceId)
                        };
                    }
                    break;
                case 'show':
                    return this.handleShow(args, deviceId);
                case 'exit':
                    return this.handleExit(deviceId);
                default:
                    return { error: 'Unknown privileged command' };
            }
        }
    
        // If in user mode
        if (mode.mode === 'user') {
            switch (cmd) {
                case 'enable':
                    this.networkState.configMode.set(deviceId, { mode: 'privileged' });
                    return {
                        success: true,
                        output: '',
                        input: 'enable',
                        prompt: this.networkState.getCommandPrompt(deviceId)
                    };
                case 'ping':
                    return this.handlePing(args, deviceId);
                case 'show':
                    return this.handleShow(args, deviceId);
                default:
                    return { error: 'Unknown command' };
            }
        }
    
        return { error: 'Invalid command or not allowed in current mode' };
    }
    

    handleExit(deviceId) {
        const mode = this.networkState.configMode.get(deviceId);
        if (mode.mode === 'config-if' || mode.mode === 'config-router') {
            this.networkState.configMode.set(deviceId, { mode: 'config' });
        } else if (mode.mode === 'config') {
            this.networkState.configMode.set(deviceId, { mode: 'privileged' });
        } else if (mode.mode === 'privileged') {
            this.networkState.configMode.set(deviceId, { mode: 'user' });
        }
        return {
            success: true,
            output: '',
            input: 'exit',
            prompt: this.networkState.getCommandPrompt(deviceId)
        };
    }

    

    handleIpRoute(args, deviceId) {
        const device = this.networkState.devices.get(deviceId);
        const mode = this.networkState.configMode.get(deviceId);

        if (device.type !== 'router') {
            return {
                error: 'ip route command is only available on routers',
                input: `ip route ${args.join(' ')}`,
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        }

        if (!['privileged', 'config'].includes(mode.mode)) {
            return {
                error: 'Command authorization failed - must be in privileged or config mode',
                input: `ip route ${args.join(' ')}`,
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        }

        if (args.length < 3) {
            return {
                error: 'Invalid IP route command or insufficient arguments',
                input: `ip route ${args.join(' ')}`
            };
        }

        const [destination, subnetMask, nextHop] = args;
        if (!this.validateIpAddress(destination) || !this.validateIpAddress(subnetMask)) {
            return {
                error: 'Invalid IP address or subnet mask format',
                input: `ip route ${args.join(' ')}`
            };
        }

        // Convert interface name to proper case
        const properInterfaceName = Object.keys(device.interfaces).find(
            intf => intf.toLowerCase() === nextHop.toLowerCase()
        );

        // Check if nextHop is either a valid IP or a valid interface
        if (!this.validateIpAddress(nextHop) && !properInterfaceName) {
            return {
                error: 'Invalid next hop IP address or exit interface',
                input: `ip route ${args.join(' ')}`
            };
        }

        if (!device.routingTable) {
            device.routingTable = [];
        }

        device.routingTable.push({
            destination,
            subnetMask,
            nextHop: properInterfaceName || nextHop  // Use proper interface name if it's an interface
        });

        return {
            success: true,
            output: '',
            input: `ip route ${destination} ${subnetMask} ${nextHop}`,
            prompt: this.networkState.getCommandPrompt(deviceId)
        };
    }

    handleInterface(args, deviceId) {
        const mode = this.networkState.configMode.get(deviceId);
        const command = `interface ${args.join(' ')}`;
    
        if (!mode || (mode.mode !== 'config' && mode.mode !== 'config-if')) {
            return {
                error: 'Command authorization failed - not permitted in current mode',
                input: command
            };
        }
    
        const device = this.networkState.devices.get(deviceId);
        if (!device) {
            return {
                error: 'Device not found',
                input: command
            };
        }
    
        const inputInterfaceName = this.parseInterfaceName(args);
    
        // Find the interface name in device.interfaces regardless of case
        const interfaceName = Object.keys(device.interfaces).find(
            name => name.toLowerCase() === inputInterfaceName.toLowerCase()
        );

        if (device.interfaces[interfaceName]) {
            this.networkState.configMode.set(deviceId, {
                mode: 'config-if',
                interface: interfaceName
            });
            console.log(`Device ${deviceId} mode set to config-if for interface ${interfaceName}`);
            return {
                success: true,
                output: '',
                input: command,
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        }
    
        if (interfaceName) {
            this.networkState.configMode.set(deviceId, {
                mode: 'config-if',
                interface: interfaceName
            });
            return {
                success: true,
                output: '',
                input: command,
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        }
    
        return {
            error: `Interface not found. Available interfaces:\n${Object.keys(device.interfaces).join('\n')}`,
            input: command
        };
    }
    
    
    parseInterfaceName(args) {
        let interfaceName = args.join(' ');
        const interfaceMap = {
            '^fa\\s*(\\d+/\\d+)': 'FastEthernet',
            '^gi\\s*(\\d+/\\d+)': 'GigabitEthernet',
            '^eth\\s*(\\d+/\\d+)': 'Ethernet',
            '^se\\s*(\\d+/\\d+)': 'Serial',
            '^fastethernet\\s*(\\d+/\\d+)': 'FastEthernet',
            '^gigabitethernet\\s*(\\d+/\\d+)': 'GigabitEthernet',
            '^ethernet\\s*(\\d+/\\d+)': 'Ethernet',
            '^serial\\s*(\\d+/\\d+)': 'Serial'
        };
    
        for (const [pattern, fullName] of Object.entries(interfaceMap)) {
            const regex = new RegExp(pattern, 'i'); // Case-insensitive matching
            const match = interfaceName.match(regex);
            if (match) {
                const rest = match[1] || '';
                interfaceName = `${fullName}${rest}`;
                break;
            }
        }
    
        return interfaceName.replace(/\s+/g, '');
    }
    
    

    handleIpCommand(args, deviceId) {
        const device = this.networkState.devices.get(deviceId);
        const mode = this.networkState.configMode.get(deviceId);
    

        // For PCs handling DHCP
        if (device.type === 'pc') {
            if (args[0] === 'dhcp') {
                // Get connected router
                const connectedRouter = this.findConnectedRouter(deviceId);
                if (!connectedRouter) {
                    return {
                        error: 'No router connected',
                        input: 'ip dhcp',
                        prompt: device.name
                    };
                }
    
                // Get router's interface IP
                const routerInterface = this.getConnectedInterface(connectedRouter.id, deviceId);
                console.log('Found router interface:', JSON.stringify(routerInterface));
                
                if (!routerInterface || !routerInterface.ip) {
                    return {
                        error: 'No router interface configured',
                        input: 'ip dhcp',
                        prompt: device.name
                    };
                }
    
                // Generate DHCP address (increment last octet)
                const routerIp = routerInterface.ip;
                const ipParts = routerIp.split('.');
                const lastOctet = parseInt(ipParts[3]) + 100; // Start from x.x.x.100
                const dhcpIp = `${ipParts[0]}.${ipParts[1]}.${ipParts[2]}.${lastOctet}`;
    
                // Configure PC
                if (!device.interfaces['Ethernet0']) {
                    device.interfaces['Ethernet0'] = {
                        name: 'Ethernet0',
                        type: 'ethernet',
                        status: 'up'
                    };
                }
    
                device.interfaces['Ethernet0'].ip = dhcpIp;
                device.interfaces['Ethernet0'].subnet = routerInterface.subnet;
                device.interfaces['Ethernet0'].gateway = routerInterface.ip;
                device.interfaces['Ethernet0'].status = 'up';
    
                console.log('Updated PC interface:', JSON.stringify(device.interfaces['Ethernet0']));
    
                return {
                    success: true,
                    output: `IP address was automatically configured:\nIP Address: ${dhcpIp}\nSubnet Mask: ${routerInterface.subnet}\nDefault Gateway: ${routerInterface.ip}`,
                    input: 'ip dhcp',
                    prompt: device.name
                };
            }
        }
        
        // Special handling for PC devices
        if (device.type === 'pc') {
            if (!mode || mode.mode !== 'config') {
                return {
                    error: 'Command authorization failed - must be in config mode',
                    input: `ip ${args.join(' ')}`
                };
            }
    
            if (args[0] === 'address' && args.length >= 3) {
                const ip = args[1];
                const subnet = args[2];
    
                if (this.validateIpAddress(ip) && this.validateIpAddress(subnet)) {
                    // For PCs, we always configure Ethernet0
                    if (!device.interfaces['Ethernet0']) {
                        device.interfaces['Ethernet0'] = {
                            name: 'Ethernet0',
                            type: 'ethernet',
                            status: 'up'
                        };
                    }
                    
                    device.interfaces['Ethernet0'].ip = ip;
                    device.interfaces['Ethernet0'].subnet = subnet;
                    
                    return {
                        success: true,
                        output: '',
                        input: `ip address ${ip} ${subnet}`,
                        prompt: this.networkState.getCommandPrompt(deviceId)
                    };
                }
                return { 
                    error: 'Invalid IP address or subnet mask',
                    input: `ip address ${ip} ${subnet}`
                };
            }
        }

        if (mode.mode === 'config-if') {
            const networkInterface = device.interfaces[mode.interface];
    
            if (!networkInterface) {
                console.log(`Interface ${mode.interface} not found on device ${deviceId}`);
                return { error: 'Interface not found' };
            }
    
            if (args[0] === 'address' && args.length >= 3) {
                const ip = args[1];
                const subnet = args[2];
    
                if (this.validateIpAddress(ip) && this.validateIpAddress(subnet)) {
                    networkInterface.ip = ip;
                    networkInterface.subnet = subnet;
    
                    console.log(`Interface ${mode.interface} updated on device ${deviceId}:`, networkInterface);
    
                    return {
                        success: true,
                        output: '',
                        input: `ip address ${ip} ${subnet}`,
                        prompt: this.networkState.getCommandPrompt(deviceId)
                    };
                } else {
                    console.log(`Invalid IP address or subnet mask: ip=${ip}, subnet=${subnet}`);
                    return {
                        error: 'Invalid IP address or subnet mask',
                        input: `ip address ${ip} ${subnet}`
                    };
                }
            } else {
                return {
                    error: 'Invalid IP command or insufficient arguments',
                    input: `ip ${args.join(' ')}`
                };
            }
        }

        return { 
            error: 'Invalid IP command or insufficient arguments',
            input: `ip ${args.join(' ')}`
        };
    }
    
    findConnectedRouter(deviceId) {
        // Get all connections for this device
        const connections = Array.from(this.networkState.connections.values())
            .filter(conn => conn.fromDevice === deviceId || conn.toDevice === deviceId);
        
        console.log(`Connections for device ${deviceId}:`, connections);
    
        // Find connected router
        for (const connection of connections) {
            const otherId = connection.fromDevice === deviceId ? connection.toDevice : connection.fromDevice;
            const otherDevice = this.networkState.devices.get(otherId);
            console.log(`Connected device ${otherId}:`, otherDevice);
            
            if (otherDevice && otherDevice.type === 'router') {
                return otherDevice;
            }
        }
        return null;
    }

    getConnectedInterface(routerId, deviceId) {
        const connection = Array.from(this.networkState.connections.values())
            .find(conn => 
                (conn.fromDevice === routerId && conn.toDevice === deviceId) ||
                (conn.fromDevice === deviceId && conn.toDevice === routerId)
            );
    
        console.log(`Connection found:`, connection);
    
        if (!connection) return null;
    
        const router = this.networkState.devices.get(routerId);
        const interfaceName = connection.fromDevice === routerId ? connection.from : connection.to;
        
        console.log(`Router interface:`, router.interfaces[interfaceName]);
        
        return router.interfaces[interfaceName];
    }

    validateIpAddress(ip) {
        // Regular expression to validate IPv4 addresses
        const ipRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ipRegex.test(ip);
    }
    

    handleShutdown(args, deviceId) {
        const mode = this.networkState.configMode.get(deviceId);
        if (!mode || mode.mode !== 'config-if') {
            return {
                error: 'Command authorization failed - not permitted in current mode',
                input: `shutdown`
            };
        }
        return this.toggleInterfaceStatus(deviceId, 'down');
    }

    handleNo(args, deviceId) {
        const mode = this.networkState.configMode.get(deviceId);
        const device = this.networkState.devices.get(deviceId);

        if (!mode || mode.mode !== 'config-if') {
            return {
                error: 'Command authorization failed - not permitted in current mode',
                input: `no ${args.join(' ')}`
            };
        }

        const networkInterface = device.interfaces[mode.interface];
        if (!networkInterface) {
            return { error: 'Interface not found' };
        }

        if (args[0] === 'shutdown') {
            networkInterface.status = 'up';
            return {
                success: true,
                output: '',
                input: `no ${args.join(' ')}`,
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        }
        if (args[0] === 'ip' && args[1] === 'address') {
            return this.removeIpAddress(deviceId);
        }
        return { error: 'Invalid no command' };
    }

    toggleInterfaceStatus(deviceId, status) {
        const device = this.networkState.devices.get(deviceId);
        const mode = this.networkState.configMode.get(deviceId);

        if (mode.mode === 'config-if') {
            const networkInterface = device.interfaces[mode.interface];
            if (networkInterface) {
                networkInterface.status = status;
                return {
                    success: true,
                    output: '',
                    input: `interface ${networkInterface.name} ${status}`,
                    prompt: this.networkState.getCommandPrompt(deviceId)
                };
            }
        }
        return { error: 'Invalid command or interface not found' };
    }

    removeIpAddress(deviceId) {
        const device = this.networkState.devices.get(deviceId);
        const mode = this.networkState.configMode.get(deviceId);

        if (mode.mode === 'config-if') {
            const networkInterface = device.interfaces[mode.interface];
            if (networkInterface) {
                delete networkInterface.ip;
                delete networkInterface.subnet;
                return {
                    success: true,
                    output: 'IP address removed',
                    input: `interface ${networkInterface.name} ip address remove`,
                    prompt: this.networkState.getCommandPrompt(deviceId)
                };
            }
        }
        return { error: 'Invalid command or interface not found' };
    }

    handleDescription(args, deviceId) {
        const device = this.networkState.devices.get(deviceId);
        const mode = this.networkState.configMode.get(deviceId);

        if (mode.mode === 'config-if' && args.length > 0) {
            const description = args.join(' ');
            const networkInterface = device.interfaces[mode.interface];
            if (networkInterface) {
                networkInterface.description = description;
                return {
                    success: true,
                    output: '',
                    input: `interface ${networkInterface.name} description ${description}`,
                    prompt: this.networkState.getCommandPrompt(deviceId)
                };
            }
        }
        return { error: 'Invalid description command or context' };
    }

    // New method to find DHCP server in network
    findDhcpServer(clientId) {
        const connectedDevices = this.getConnectedDevices(clientId);
        return connectedDevices.find(device => 
            device.type === 'router' && 
            this.networkState.dhcpPools.has(device.id)
        );
    }

    getConnectedDevices(deviceId) {
        const connections = Array.from(this.networkState.connections.values());
        const connectedDeviceIds = connections
            .filter(conn => conn.fromDevice === deviceId || conn.toDevice === deviceId)
            .map(conn => conn.fromDevice === deviceId ? conn.toDevice : conn.fromDevice);
        
        return connectedDeviceIds.map(id => this.networkState.devices.get(id)).filter(Boolean);
    }

    // New method to request DHCP lease
    requestDhcpLease(clientId, serverId) {
        const pool = this.networkState.dhcpPools.get(serverId);
        if (!pool) return null;

        // Simple DHCP allocation logic
        const usedIps = new Set(Array.from(this.networkState.dhcpLeases.values())
            .map(lease => lease.ip));

        // Find first available IP in pool
        const ip = pool.range.find(ip => !usedIps.has(ip));
        if (!ip) return null;

        const lease = {
            ip: ip,
            subnet: pool.subnet,
            gateway: pool.gateway,
            clientId: clientId,
            expiry: Date.now() + 86400000 // 24 hour lease
        };

        this.networkState.dhcpLeases.set(clientId, lease);
        return lease;
    }

    handleSpeed(args, deviceId) {
        const device = this.networkState.devices.get(deviceId);
        const mode = this.networkState.configMode.get(deviceId);

        if (mode.mode === 'config-if' && args.length > 0) {
            const speed = args[0];
            const networkInterface = device.interfaces[mode.interface];
            if (networkInterface) {
                networkInterface.speed = speed;
                return {
                    success: true,
                    output: '',
                    input: `interface ${networkInterface.name} speed ${speed}`,
                    prompt: this.networkState.getCommandPrompt(deviceId)
                };
            }
        }
        return { error: 'Invalid speed command or context' };
    }

    handlePing(args, deviceId) {
        if (args.length === 0) {
            return {
                error: 'IP address required',
                input: 'ping'
            };
        }

        const targetIp = args[0];
        if (!this.validateIpAddress(targetIp)) {
            return {
                error: 'Invalid IP address',
                input: `ping ${targetIp}`
            };
        }

        const sourceDevice = this.networkState.devices.get(deviceId);
        if (!sourceDevice) {
            return {
                error: 'Source device not found',
                input: `ping ${targetIp}`
            };
        }

        // Check if source device has an IP address
        const sourceInterface = Object.values(sourceDevice.interfaces).find(intf => intf.ip && intf.status === 'up');
        if (!sourceInterface || !sourceInterface.ip) {
            return {
                error: 'No source IP address configured',
                input: `ping ${targetIp}`
            };
        }

        // Find target device in the network
        let targetFound = false;
        let targetDevice = null;
        let targetInterface = null;

        // First, check direct connections
        for (const [_, device] of this.networkState.devices) {
            for (const intf of Object.values(device.interfaces)) {
                if (intf.ip === targetIp && intf.status === 'up') {
                    targetFound = true;
                    targetDevice = device;
                    targetInterface = intf;
                    break;
                }
            }
            if (targetFound) break;
        }

        // If not directly connected, check routing tables
        if (!targetFound && sourceDevice.type === 'router') {
            const routingTable = sourceDevice.routingTable || [];
            for (const route of routingTable) {
                // Check if target IP is in the network defined by the route
                if (this.isIpInNetwork(targetIp, route.destination, route.subnetMask)) {
                    targetFound = true;
                    break;
                }
            }
        }

        if (!targetFound) {
            return {
                output: `Pinging ${targetIp} with 32 bytes of data:\nRequest timed out.\nRequest timed out.\nRequest timed out.\nRequest timed out.\n\nPing statistics for ${targetIp}:\n    Packets: Sent = 4, Received = 0, Lost = 4 (100% loss)`,
                input: `ping ${targetIp}`,
                success: true
            };
        }

        return {
            output: `Pinging ${targetIp} with 32 bytes of data:\nReply from ${targetIp}: bytes=32 time<1ms TTL=128\nReply from ${targetIp}: bytes=32 time<1ms TTL=128\nReply from ${targetIp}: bytes=32 time<1ms TTL=128\nReply from ${targetIp}: bytes=32 time<1ms TTL=128\n\nPing statistics for ${targetIp}:\n    Packets: Sent = 4, Received = 4, Lost = 0 (0% loss)\nApproximate round trip times in milli-seconds:\n    Minimum = 0ms, Maximum = 0ms, Average = 0ms`,
            input: `ping ${targetIp}`,
            success: true
        };
    }

    // Add this helper method to check if an IP is in a network
    isIpInNetwork(ip, networkAddress, subnetMask) {
        const ipParts = ip.split('.').map(Number);
        const networkParts = networkAddress.split('.').map(Number);
        const maskParts = subnetMask.split('.').map(Number);
        
        for (let i = 0; i < 4; i++) {
            if ((ipParts[i] & maskParts[i]) !== (networkParts[i] & maskParts[i])) {
                return false;
            }
        }
        return true;
    }

    handleIpConfig(deviceId) {
        const device = this.networkState.devices.get(deviceId);
        const interfaceName = device.interfaces['Ethernet0'];

        let output = 'FastEthernet0 Configuration\n\n';
        
        if (interfaceName.ip && interfaceName.subnet) {
            output += `   IP Address. . . . . . . . . . . . : ${interfaceName.ip}\n`;
            output += `   Subnet Mask . . . . . . . . . . . : ${interfaceName.subnet}\n`;
            if (interfaceName.gateway) {
                output += `   Default Gateway . . . . . . . . . : ${interfaceName.gateway}\n`;
            }
        } else {
            output += '   IP Address. . . . . . . . . . . . : unassigned\n';
        }

        return {
            success: true,
            output: output,
            prompt: device.name
        };
    }

    handlePcIpCommand(args, deviceId) {
        const device = this.networkState.devices.get(deviceId);
        
        // Add DHCP handling here
        if (args[0] === 'dhcp') {
            // Get connected router
            const connectedRouter = this.findConnectedRouter(deviceId);
            if (!connectedRouter) {
                return {
                    error: 'No router connected',
                    input: 'ip dhcp',
                    prompt: device.name
                };
            }

            // Get router's interface IP
            const routerInterface = this.getConnectedInterface(connectedRouter.id, deviceId);
            if (!routerInterface || !routerInterface.ip) {
                return {
                    error: 'No router interface configured',
                    input: 'ip dhcp',
                    prompt: device.name
                };
            }

            // Generate DHCP address (increment last octet)
            const routerIp = routerInterface.ip;
            const ipParts = routerIp.split('.');
            const lastOctet = parseInt(ipParts[3]) + 100; // Start from x.x.x.100
            const dhcpIp = `${ipParts[0]}.${ipParts[1]}.${ipParts[2]}.${lastOctet}`;

            // Configure PC
            device.interfaces['Ethernet0'].ip = dhcpIp;
            device.interfaces['Ethernet0'].subnet = routerInterface.subnet;
            device.interfaces['Ethernet0'].gateway = routerInterface.ip;
            device.interfaces['Ethernet0'].status = 'up';

            return {
                success: true,
                output: `IP address was automatically configured:\nIP Address: ${dhcpIp}\nSubnet Mask: ${routerInterface.subnet}\nDefault Gateway: ${routerInterface.ip}`,
                input: 'ip dhcp',
                prompt: device.name
            };
        }
        
        // Original static IP handling
        if (args[0] === 'address' && args.length >= 3) {
            const ip = args[1];
            const subnet = args[2];

            if (!this.validateIpAddress(ip) || !this.validateIpAddress(subnet)) {
                return {
                    error: 'Invalid IP address or subnet mask format',
                    input: `ip address ${ip} ${subnet}`,
                    prompt: device.name
                };
            }

            // For PCs, always use Ethernet0
            if (!device.interfaces['Ethernet0']) {
                device.interfaces['Ethernet0'] = {
                    name: 'Ethernet0',
                    type: 'ethernet',
                    status: 'up'
                };
            }

            device.interfaces['Ethernet0'].ip = ip;
            device.interfaces['Ethernet0'].subnet = subnet;

            return {
                success: true,
                output: `IP address configured successfully.`,
                input: `ip address ${ip} ${subnet}`,
                prompt: device.name
            };
        }

        return {
            error: 'Usage: ip address <ip-address> <subnet-mask> or ip dhcp',
            input: `ip ${args.join(' ')}`,
            prompt: device.name
        };
    }

    handleDuplex(args, deviceId) {
        const device = this.networkState.devices.get(deviceId);
        const mode = this.networkState.configMode.get(deviceId);

        if (mode.mode === 'config-if' && args.length > 0) {
            const duplex = args[0];
            const networkInterface = device.interfaces[mode.interface];
            if (networkInterface) {
                networkInterface.duplex = duplex;
                return {
                    success: true,
                    output: '',
                    input: `interface ${networkInterface.name} duplex ${duplex}`,
                    prompt: this.networkState.getCommandPrompt(deviceId)
                };
            }
        }
        return { error: 'Invalid duplex command or context' };
    }
}

