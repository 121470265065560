import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const DebugPanel = ({ debug, devices, connections, connectionMode, connectionStart }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="flex">
      <div className={`${isOpen ? 'block' : 'hidden'} bg-white border-t border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-600 space-y-1 sm:space-y-2 w-full sm:w-auto max-w-full sm:max-w-xs overflow-x-auto`}>
        <div className="whitespace-nowrap">Socket ID: <span className="text-blue-600">{debug.socketId || 'Disconnected'}</span></div>
        <div className="whitespace-nowrap">Devices: <span className="text-green-600">{devices.size}</span></div>
        <div className="whitespace-nowrap">Connections: <span className="text-purple-600">{connections.size}</span></div>
        <div className="whitespace-nowrap">Connection Mode: <span className={connectionMode ? 'text-green-600' : 'text-red-600'}>{connectionMode ? 'ON' : 'OFF'}</span></div>
        <div className="whitespace-nowrap">Connection Start: <span className="text-yellow-600">{connectionStart || 'None'}</span></div>
        <div className="whitespace-nowrap">Last Action: <span className="text-blue-600">{debug.lastAction || 'None'}</span></div>
        {debug.lastError && <div className="text-red-600 whitespace-nowrap">Error: {debug.lastError}</div>}
      </div>
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center p-1 sm:p-2 hover:bg-gray-100"
      >
        {isOpen ? <ChevronRight size={16} /> : <ChevronLeft size={16} />}
      </button>
    </div>
  );
};

export default DebugPanel;