import React, { useState } from 'react';
import { Code, Facebook, Github, Apple, Eye, EyeOff } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ForgotPassword from '../../Components/Modals/ForgotPassword';
import SidePanel from '../../Components/Account/SidePanel';
import ReCAPTCHA from 'react-google-recaptcha';

// Input sanitization utilities
const sanitizeInput = {
  email: (value) => {
    if (!value) return '';
    return value.trim().toLowerCase().replace(/[<>]/g, '');
  },
  password: (value) => {
    if (!value) return '';
    return value.trim();
  }
};

// Input validation utilities
const validateInput = {
  email: (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) return 'Email is required';
    if (!emailRegex.test(email)) return 'Invalid email format';
    return '';
  },
  password: (password) => {
    if (!password) return 'Password is required';
    if (password.length < 6) return 'Password must be at least 6 characters';
    return '';
  }
};

const Login = ({onLoginSuccess}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleInputChange = (setter) => (e) => {
    const { name, value } = e.target;
    const sanitizedValue = name === 'email' 
      ? sanitizeInput.email(value)
      : sanitizeInput.password(value);
    
    setter(sanitizedValue);
    
    // Clear field-specific error
    setFieldErrors(prev => ({
      ...prev,
      [name]: ''
    }));
    setError('');
  };

  const validateForm = () => {
    const newErrors = {
      email: validateInput.email(email),
      password: validateInput.password(password)
    };

    // Remove empty error messages
    Object.keys(newErrors).forEach(key => {
      if (!newErrors[key]) delete newErrors[key];
    });

    setFieldErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setFieldErrors({});
    setIsLoading(true);

    if (!recaptchaToken) {
      setError('Please complete the reCAPTCHA verification');
      setIsLoading(false);
      return;
    }

    if (!validateForm()) {
      setIsLoading(false);
      // Reset reCAPTCHA on validation error
      if (window.grecaptcha) {
        window.grecaptcha.reset();
        setRecaptchaToken(null);
      }
      return;
    }

    try {
      const sanitizedData = {
        email: sanitizeInput.email(email),
        password: sanitizeInput.password(password),
        recaptchaToken
      };

      const response = await axios.post(
        'https://codeforcambodia.codes/codecademy/user/login', 
        sanitizedData
      );

      if (response.status === 200 && response.data.token) {
        onLoginSuccess(response.data.token);
        navigate('/dashboard');
      }
    } catch (error) {
      // Reset reCAPTCHA on any error
      if (window.grecaptcha) {
        window.grecaptcha.reset();
        setRecaptchaToken(null);
      }

      if (axios.isAxiosError(error) && error.response) {
        if (error.response.data.errors) {
          // Handle validation errors from backend
          const backendErrors = {};
          error.response.data.errors.forEach(err => {
            backendErrors[err.path] = err.msg;
          });
          setFieldErrors(backendErrors);
          setError('Please fix the highlighted errors');
        } else {
          setError(error.response.data.message || 'An error occurred during login');
        }
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Utility function for input className
  const getInputClassName = (fieldName) => `
    appearance-none block w-full px-3 py-2 border 
    ${fieldErrors[fieldName] ? 'border-red-300' : 'border-gray-300'} 
    rounded-md shadow-sm placeholder-gray-400 
    focus:outline-none focus:ring-blue-500 focus:border-blue-500 
    sm:text-sm
    ${fieldName === 'password' ? 'pr-10' : ''}
  `;

  return (
    <div className="flex min-h-screen bg-white text-gray-800 font-mono">
      <div className="flex-1 flex flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="text-left">
            <Link to="/" className="mt-6 text-3xl font-extrabold text-center text-gray-900 block hover:text-blue-600 transition-colors">
              <span className="text-blue-600">CodeForCambodia</span> 
            </Link>
          </div>

          <div className="mt-3 bg-gray-100 p-6 rounded-lg border border-gray-300 shadow-sm">
            <div className="mb-4 text-sm text-gray-500">
              <span className="text-orange-600">function</span> <span className="text-blue-600">login</span>() {'{'}
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  <span className="text-purple-600">let</span> email <span className="text-purple-600">=</span>
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={handleInputChange(setEmail)}
                    className={getInputClassName('email')}
                  />
                  {fieldErrors.email && (
                    <div className="text-red-600 text-sm mt-1">{fieldErrors.email}</div>
                  )}
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  <span className="text-purple-600">let</span> password <span className="text-purple-600">=</span>
                </label>
                <div className="mt-1 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={handleInputChange(setPassword)}
                    className={getInputClassName('password')}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {showPassword ? <EyeOff className="h-5 w-5 text-gray-400" /> : <Eye className="h-5 w-5 text-gray-400" />}
                  </button>
                  {fieldErrors.password && (
                    <div className="text-red-600 text-sm mt-1">{fieldErrors.password}</div>
                  )}
                </div>
              </div>

              {error && (
                <div className="text-red-600 text-base text-center space-y-2">
                  <div>{error}</div>
                  <div className="text-gray-600 text-base Nokora">
                    ត្រូវការជំនួយ? អាចទាក់ទងតាម {' '}
                    <a 
                      href="https://t.me/codeforcambodia" 
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-600 underline"
                    >
                      Telegram
                    </a>
                  </div>
                </div>
              )}

              <div className="flex justify-center  flex-col items-center text-sm Nokora">
                  <span className="text-orange-500">សុំចុចលើគ្រីស</span>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                />
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isLoading || !recaptchaToken}
                  className="w-full text-yellow-300 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                >
                  {isLoading ? 'Processing...' : 'signIn()'}
                </button>
              </div>
            </form>
            <div className="mt-4 text-sm text-gray-500">{'}'}</div>
          </div>

          {/* Rest of your JSX remains the same */}
          <div className="mt-4 text-center">
            <Link to="/register" className="text-blue-600 hover:text-blue-800">
              <span className="text-orange-600 Nokora">អត់មានគណនី?</span><br></br> <span className="text-blue-600">createAccount</span>() {'{'}...{'}'}
            </Link>
          </div>
          <div className="mt-4 text-center">
            <button
              onClick={() => setIsForgotPasswordOpen(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Code className="mr-2 h-4 w-4" />
              <span className="text-red-600">forgotPassword</span>() {'{'}...{'}'}
            </button>
          </div>
        </div>
      </div>
      <SidePanel/>
      {isForgotPasswordOpen && <ForgotPassword onClose={() => setIsForgotPasswordOpen(false)} />}
    </div>
  );
};

export default Login;