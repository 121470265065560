import React from 'react';
import { X, Play, Share2, Users, AwardIcon, DollarSign, TestTube, TestTube2, Tag } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const PremiumTutorialModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl w-full max-w-lg mx-auto shadow-xl">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b">
          <h3 className="text-lg font-semibold Nokora">របៀបទទួលបាន Pro</h3>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        {/* Video Tutorial Section */}
        <div className="p-4">
          <div className="relative w-full bg-gray-900 rounded-lg overflow-hidden" style={{ paddingBottom: '56.25%' }}>
            <video
              className="absolute inset-0 w-full h-full object-cover"
              controls
              autoPlay
            >
              <source src="https://codeforcambodia.s3.us-east-005.backblazeb2.com/subscriptionCompressed.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        {/* Steps Section */}
        <div className="px-4 py-2">
          <div className="space-y-3">
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                <TestTube2 className="w-4 h-4 text-blue-600" />
              </div>
              <div>
                <p className="Nokora text-sm text-gray-600">ការជាវរបស់អ្នកនឹងជួយដល់ការស្រាវជ្រាវ និងមេរៀនថ្មីៗសម្រាប់សិស្សខ្មែរគ្រប់រូប</p>
              </div>
            </div>
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                <Tag className="w-4 h-4 text-blue-600" />
              </div>
              <div>
                <p className="Nokora text-sm text-gray-600">យើងផ្តល់ជូនការសាកល្បងដោយឥតគិតថ្លៃ ៧ថ្ងៃ</p>
              </div>
            </div>

            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                <DollarSign className="w-4 h-4 text-blue-600" />
              </div>
              <div>
                <p className="Nokora text-sm text-gray-600">ប្រសិនបើអ្នកជាវ ១ឆ្នាំ តម្លៃត្រឹមតែ $2.99/ខែ ប៉ុណ្ណោះ</p>
              </div>
            </div>
          </div>
        </div>

        {/* Action Button */}
        <div className="p-4 border-t">
          <button
            className="w-full bg-blue-600 text-white rounded-lg py-2 px-4 hover:bg-blue-700 transition-colors Nokora font-medium"
            onClick={() => navigate('/subscription')}
          >
            បង់ប្រាក់
          </button>
          <a 
            href="https://t.me/codeforcambodia_support" 
            target="_blank"
            rel="noopener noreferrer" 
            className="block text-center mt-3 text-blue-600 hover:text-blue-800 transition-colors duration-200 text-sm Nokora font-medium"
          >
            <span className="flex items-center justify-center">
              <svg className="w-4 h-4 mr-1" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.562 8.161c-.18 1.897-.962 6.502-1.359 8.627-.168.9-.5 1.201-.82 1.23-.697.064-1.226-.461-1.901-.903-1.056-.692-1.653-1.123-2.678-1.799-1.185-.781-.417-1.21.258-1.911.177-.184 3.247-2.977 3.307-3.23.007-.032.015-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.139-5.062 3.345-.479.329-.913.489-1.302.481-.428-.009-1.252-.242-1.865-.442-.751-.244-1.349-.374-1.297-.789.027-.216.324-.437.892-.663 3.498-1.524 5.831-2.529 6.998-3.015 3.333-1.386 4.025-1.627 4.477-1.635.099-.002.321.023.465.141.121.099.195.231.214.375.012.094.027.271.015.417z"/>
              </svg>
              ទាក់ទងតាម Telegram
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PremiumTutorialModal;