import React, { useEffect } from 'react'
import Editor from '@monaco-editor/react'

const ReusableEditor = ({
    isDragging,
    handleDragStart,
    handleDrag,
    handleDragStop,
    isMobileView,
    editorRef,

    activeCodeTab,
    code,
    onChange,
    handleEditorMount,
    options = {},
    ...props 
}) => {
    const language = {
        html: 'html',
        css: 'css',
        javascript: 'javascript',
        js: 'javascript',
        php: 'php',
    }[activeCodeTab] //assigned language based on activeCodeTab
    
    const defaultOptions = {
        automaticLayout: true,
    }
    const mergedOptions = options ? { ...defaultOptions, ...options } : {}; //Merge default Options with Options
    return (
        <Editor
            height="100%"
            defaultLanguage={language}
            language={language}
            value={code}
            onChange={onChange}
            onMount={handleEditorMount}
            options={mergedOptions}
            {...props} //pass additional props 
        />
    );
}
export default ReusableEditor