// utils/courseAccess.js
import axios from 'axios';

/**
 * Checks if a course is free or premium and if the user has access
 * @param {number} courseId - The ID of the course to check
 * @returns {Promise<{isFree: boolean, hasAccess: boolean}>} Object containing course access status
 * @throws {Error} If there's an error checking course access
 */

export const checkCourseAccess = async (courseId) => {
  const token = localStorage.getItem('token');
  
  // Check cache first
  const cached = localStorage.getItem(`courseAccess_${courseId}`);
  const cacheTimestamp = localStorage.getItem(`courseAccessTimestamp_${courseId}`);
  const CACHE_DURATION = 1000 * 60 * 180; // 180 minutes


  // Use cache if it's still valid
  if (cached && cacheTimestamp && Date.now() - parseInt(cacheTimestamp) < CACHE_DURATION) {
    console.log('remaining time: ', Date.now() - parseInt(cacheTimestamp));
    return JSON.parse(cached);
  }
  
  try {
    const response = await axios.get(`https://codeforcambodia.codes/codecademy/auth/course-access/${courseId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (response.data.success) {      
      const accessData = {
        isFree: response.data.courseAccess.isFree,
        hasAccess: response.data.courseAccess.hasAccess
      };

      // Update cache
      localStorage.setItem(`courseAccess_${courseId}`, JSON.stringify(accessData));
      localStorage.setItem(`courseAccessTimestamp_${courseId}`, Date.now().toString());

      return accessData;
    }

    throw new Error('Failed to get course access status');
  } catch (error) {
    console.error('Error checking course access:', error);
    // Re-throw the error to be handled by the calling component
    throw new Error(error.response?.data?.message || 'Error checking course access');
  }
};

// Usage example:
/*
try {
  const { isFree, hasAccess } = await checkCourseAccess(courseId);
  if (!isFree && !hasAccess) {
    // Handle premium course without access
  }
} catch (error) {
  // Handle error
}
*/