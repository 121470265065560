export class TerminalCommandHandler {
    constructor(fileSystem, serverState, simulationContext) {
      this.fileSystem = fileSystem;
      this.serverState = serverState;
      this.simulationContext = simulationContext;
    }
  
    async handleCommand(command) {
      const [cmd, ...args] = command.trim().split(' ');
  
      switch (cmd) {
        case 'ls':
          return this.listFiles(args[0] || '/');
        
        case 'npm':
          return this.handleNpm(args);
        
        case 'node':
          return this.handleNode(args);
        
        case 'cat':
          return this.catFile(args[0]);
        
        case 'help':
          return this.showHelp();
        
        case 'clear':
          // Special case - terminal should handle this
          return '';
        
        default:
          throw new Error(`Command not found: ${cmd}. Type 'help' for available commands.`);
      }
    }
  
    listFiles(path) {
      let current = this.fileSystem['/'];
      const parts = path.split('/').filter(Boolean);
      
      for (const part of parts) {
        current = current.children[part];
        if (!current) {
          throw new Error(`Directory not found: ${path}`);
        }
      }
  
      const entries = Object.entries(current.children)
        .map(([name, item]) => {
          const type = item.type === 'folder' ? 'd' : '-';
          const color = item.type === 'folder' ? '\x1b[34m' : '\x1b[0m';
          return `${type} ${color}${name}\x1b[0m`;
        });
  
      return entries.join('\n');
    }
  
    catFile(path) {
      if (!path) {
        throw new Error('Please specify a file to read');
      }
  
      let current = this.fileSystem['/'];
      const parts = path.split('/').filter(Boolean);
      const fileName = parts.pop();
  
      for (const part of parts) {
        current = current.children[part];
        if (!current) {
          throw new Error(`Directory not found: ${path}`);
        }
      }
  
      const file = current.children[fileName];
      if (!file || file.type !== 'file') {
        throw new Error(`File not found: ${fileName}`);
      }
  
      return file.content;
    }
  
    async handleNpm(args) {
      const [command, ...cmdArgs] = args;
  
      switch (command) {
        case 'start':
          if (!this.serverState.running) {
            if (this.simulationContext.startServer) {
              await this.simulationContext.startServer();
              return 'Starting server...\nServer is now running on port 3000';
            }
            throw new Error('Server start function not available');
          }
          return 'Server is already running';
  
        case 'install':
          if (!cmdArgs.length) {
            return 'Please specify a package to install';
          }
          return `Added package ${cmdArgs[0]}`;
  
        case 'stop':
          if (this.serverState.running) {
            if (this.simulationContext.stopServer) {
              await this.simulationContext.stopServer();
              return 'Server stopped';
            }
            throw new Error('Server stop function not available');
          }
          return 'Server is not running';
  
        default:
          throw new Error(`Unknown npm command: ${command}`);
      }
    }
  
    async handleNode(args) {
      const [file] = args;
      if (!file) {
        throw new Error('Please specify a file to run');
      }
  
      if (file === 'server.js') {
        if (!this.serverState.running) {
          if (this.simulationContext.startServer) {
            await this.simulationContext.startServer();
            return 'Server started on port 3000';
          }
          throw new Error('Server start function not available');
        }
        return 'Server is already running';
      }
  
      throw new Error(`Cannot find module: ${file}`);
    }
  
    showHelp() {
      return `
  Available commands:
    ls [path]          List directory contents
    cat <file>         Display file contents
    npm start          Start the server
    npm install <pkg>  Install a package
    npm stop           Stop the server
    node <file>        Run a JavaScript file
    help               Show this help message
    clear              Clear the terminal
      `.trim();
    }
  }