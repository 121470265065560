import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Code, Terminal, Search, Filter, ChevronDown, X, ChevronUp } from 'lucide-react';
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader';
import Footer from '../Components/Footer';
import CodeLoadingAnimation from '../Components/CodeLoadingAnimation';

// LearningPathCard component
const LearningPathCard = ({ learningPath, onClick, color }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [progress, setProgress] = useState(null);

  const toggleDescription = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const fetchProgress = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      try {
        const response = await axios.get(
          `https://codeforcambodia.codes/codecademy/learningpath/overall-progress/${learningPath.id}`,
          { headers }
        );
        setProgress(response.data.progress);
      } catch (error) {
        console.error('Error fetching learning path progress:', error);
      }
    };

    fetchProgress();
  }, [learningPath.id]);

  return (
    <motion.div
      className={`bg-white border border-gray-200 rounded-lg overflow-hidden cursor-pointer transition-all duration-300 hover:border-gray-300`}
      onClick={onClick}
    >
      <div className={`bg-${color} text-white text-xs font-semibold px-3 py-1`}>
        Career path
      </div>
      <div className="p-5">
        <div className="flex items-center mb-4">
          <img
            src={learningPath.icon}
            alt={learningPath.learning_path_name}
            className="w-10 h-10 mr-2"
          />
          <h3 className="text-xl font-bold text-gray-900 Nokora">{learningPath.learning_path_name}</h3>
        </div>
        <div className="relative">
          <p className={`Nokora text-gray-600 text-sm ${isExpanded ? '' : 'line-clamp-5'}`}>
            {learningPath.description}
          </p>
          <button
            onClick={toggleDescription}
            className="text-blue-500 hover:text-blue-600 text-sm font-medium focus:outline-none"
          >
            {isExpanded ? (
              <>
                Show less <ChevronUp className="inline-block w-4 h-4 ml-1" />
              </>
            ) : (
              <>
                Show more <ChevronDown className="inline-block w-4 h-4 ml-1" />
              </>
            )}
          </button>
        </div>
        <div className="flex items-center justify-between text-sm mt-4">
          <div className="flex items-center text-gray-500">
            <Terminal className="w-4 h-4 mr-1" />
            <span className="text-green-500 font-medium">{learningPath.level}</span>
          </div>
        </div>

        {/* Progress Bar */}
        {progress && (
          <div className="mt-4">
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${progress.overall_progress}%` }}
              ></div>
            </div>
            <span className="text-gray-600 text-sm">
              Progress: {parseFloat(progress.overall_progress).toFixed(2)}%
            </span>
          </div>
        )}
      </div>
    </motion.div>
  );
};

// Main AllLearningPaths component
export default function AllLearningPaths() {
  const [learningPaths, setLearningPaths] = useState([]);
  const [filteredPaths, setFilteredPaths] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterLevel, setFilterLevel] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchLearningPaths();
  }, []);

  useEffect(() => {
    filterPaths();
  }, [searchTerm, filterLevel, learningPaths]);

  const fetchLearningPaths = async () => {
    // Check cache first
    const cached = localStorage.getItem('learningPaths');
    const cacheTimestamp = localStorage.getItem('learningPathsTimestamp');
    const CACHE_DURATION = 1000 * 60 * 60; // 1 hour

    if (cached && cacheTimestamp && Date.now() - parseInt(cacheTimestamp) < CACHE_DURATION) {
      setLearningPaths(JSON.parse(cached));
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.get('https://codeforcambodia.codes/codecademy/path');
      const paths = response.data.learningPaths;
      
      // Update cache
      localStorage.setItem('learningPaths', JSON.stringify(paths));
      localStorage.setItem('learningPathsTimestamp', Date.now().toString());
      
      setLearningPaths(paths);
    } catch (error) {
      console.error('Error fetching learning paths:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterPaths = () => {
    let filtered = learningPaths;
    if (searchTerm) {
      filtered = filtered.filter(
        (path) =>
          path.learning_path_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          path.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (filterLevel) {
      filtered = filtered.filter((path) => path.level === filterLevel);
    }
    setFilteredPaths(filtered);
  };

  const handleCardClick = (pathId) => {
    const token = localStorage.getItem('token');

    if (token) {
      navigate(`/learning-path/${pathId}`);

    } else {
      navigate('/login')
    }
  };

  const colors = ['green-500', 'purple-500', 'blue-500', 'orange-500'];

  const pageVariants = {
    initial: { opacity: 0, y: -20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: 20 },
  };

  if (isLoading) {
    return <CodeLoadingAnimation />;
  }

  return (
    <motion.div
      className="min-h-screen bg-gray-50 font-mono"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={{ duration: 0.5 }}
    >
      <CodeForCambodiaHeader />
      <div className="container mx-auto px-4 py-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-8"
        >
          <h1 className="text-4xl font-bold Nokora text-gray-900 inline-flex items-center justify-center">
            <Code className="mr-2 text-blue-500" />
            ផែនទីជំនាញ
            <Code className="ml-2 text-green-500" />
          </h1>
          <p className="Nokora mt-2 text-gray-600">// រើសជំនាញរៀនខាងលើ</p>
        </motion.div>

        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div className="relative flex-grow mr-4">
              <input
                type="text"
                placeholder="Search learning paths..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <div className="relative">
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="px-4 py-2 bg-white border border-gray-300 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <Filter className="mr-2" />
                Filter
                <ChevronDown className="ml-2" />
              </button>
              <AnimatePresence>
                {isFilterOpen && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10"
                  >
                    <div className="p-2">
                      <h3 className="text-sm font-semibold mb-2">Level</h3>
                      {['beginner', 'intermediate', 'advanced'].map((level) => (
                        <button
                          key={level}
                          onClick={() => {
                            setFilterLevel(level === filterLevel ? '' : level);
                            setIsFilterOpen(false);
                          }}
                          className={`block w-full text-left px-2 py-1 rounded ${
                            level === filterLevel
                              ? 'bg-blue-100 text-blue-700'
                              : 'hover:bg-gray-100'
                          }`}
                        >
                          {level}
                        </button>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          {filterLevel && (
            <div className="mt-2 flex items-center">
              <span className="text-sm text-gray-600 mr-2">Filtered by:</span>
              <span className="bg-blue-100 text-blue-700 px-2 py-1 rounded-full text-sm flex items-center">
                {filterLevel}
                <X
                  className="ml-1 cursor-pointer"
                  onClick={() => setFilterLevel('')}
                  size={14}
                />
              </span>
            </div>
          )}
        </div>

        <div className="relative">
          {['top-[-8px]', 'bottom-[-8px]'].map((position) => (
            <motion.div
              key={position}
              className={`absolute left-0 right-0 ${position} h-px bg-gray-200`}
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ duration: 0.7, ease: 'easeOut' }}
            />
          ))}

          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-8 mb-8"
            initial="hidden"
            animate="visible"
            variants={{
              visible: {
                transition: {
                  staggerChildren: 0.1,
                },
              },
            }}
          >
            {filteredPaths.map((path, index) => (
              <motion.div
                key={path.id}
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0 },
                }}
              >
                <LearningPathCard
                  learningPath={path}
                  onClick={() => handleCardClick(path.id)}
                  color={colors[index % colors.length]}
                />
              </motion.div>
            ))}
          </motion.div>
        </div>

        {filteredPaths.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-center text-gray-600 mt-8"
          >
            No learning paths found. Try adjusting your search or filters.
          </motion.div>
        )}
      </div>
      <Footer />
    </motion.div>
  );
}
