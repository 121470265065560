import { useState, useRef } from 'react';

export const usePacketAnalyzer = () => {
  const [networkData, setNetworkData] = useState([]);
  const [isMonitoring, setIsMonitoring] = useState(false);
  const intervalRef = useRef(null);
  const startTimeRef = useRef(null);

  const startMonitoring = () => {
    startTimeRef.current = Date.now();
    setIsMonitoring(true);

    // Simulate packet generation
    intervalRef.current = setInterval(() => {
      const newPacket = generatePacket();
      handleNewPacket(newPacket);
    }, 1000);
  };

  const stopMonitoring = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setIsMonitoring(false);
  };

  const handleNewPacket = (packet) => {
    setNetworkData(prev => {
      const newData = [...prev.slice(-5000), packet];
      return newData;
    });
  };

  const generatePacket = () => {
    const protocols = ['TCP', 'UDP', 'HTTP', 'DNS'];
    const protocol = protocols[Math.floor(Math.random() * protocols.length)];

    return {
      packetId: Date.now(),
      timestamp: Date.now(),
      protocol,
      srcIP: `192.168.1.${Math.floor(Math.random() * 255)}`,
      dstIP: `192.168.1.${Math.floor(Math.random() * 255)}`,
      srcPort: Math.floor(Math.random() * 65535),
      dstPort: Math.floor(Math.random() * 65535),
      bytes: Math.floor(Math.random() * 1500),
      flags: protocol === 'TCP' ? {
        SYN: Math.random() > 0.8,
        ACK: Math.random() > 0.5,
        FIN: Math.random() > 0.9,
        RTR: Math.random() > 0.95
      } : {},
      state: protocol === 'TCP' ? ['SYN_SENT', 'SYN_RECEIVED', 'ESTABLISHED'][Math.floor(Math.random() * 3)] : null
    };
  };

  return {
    networkData,
    isMonitoring,
    startMonitoring,
    stopMonitoring
  };
};