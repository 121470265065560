'use client'

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Terminal, Code, RefreshCw, Play, Maximize2, Minimize2, Search, X, Check, LoaderIcon, TerminalIcon, Bug, Settings, Layout } from 'lucide-react'
import parse from 'html-react-parser'
import axios from 'axios'
import ReactConfetti from 'react-confetti'
import Editor from '@monaco-editor/react'
import ReusableEditor from '../../Components/Challenge/Editor/ReusableEditor'
import DevToolsModal from '../../Components/Modals/DevToolsModal'
import { debounce } from 'lodash'
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader'
import options from '../../Components/Lessons/OptionsQuill'
import VerificationResult from '../../Components/Lessons/WebDev/VerificationResult'
import ReusablePreview from '../../Components/Challenge/Editor/ReusablePreview'
import FreeTrialHeader from '../../Components/Headers/FreeTrialHeader'

const Button = React.memo(({ children, onClick, disabled, className, icon: Icon }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-2 sm:px-3 py-1 sm:py-2 rounded-md text-xs sm:text-sm font-medium transition-colors duration-150 flex items-center ${className}`}
  >
    {Icon && <Icon size={14} className="mr-1 sm:mr-2" />}
    {children}
  </button>
))

const Tab = React.memo(({ active, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm font-medium border-b-2 flex-1 ${active
      ? 'text-blue-600 border-blue-500'
      : 'text-gray-600 hover:text-gray-800 border-transparent'
      }`}
  >
    {children}
  </button>
))

const SPLIT_RATIOS = [25, 50, 75];

const Dropdown = React.memo(({ label, icon: Icon, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        className="text-gray-600 hover:bg-gray-200"
        icon={Icon}
      >
        <span className="hidden sm:inline Nokora">{label}</span>
      </Button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
          <div className="py-1">
            {children}
          </div>
        </div>
      )}
    </div>
  );
});

export default function Component({ lesson, isSubscribed }) {
  const [activeSection, setActiveSection] = useState('instructions')
  const [activeCodeTab, setActiveCodeTab] = useState('html')
  const [html, setHtml] = useState(lesson.starter_code_html || '')
  const [css, setCss] = useState(lesson.starter_code_css || '')
  const [javascript, setJavascript] = useState(lesson.starter_code_js || '')
  const [output, setOutput] = useState('')
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [verificationResult, setVerificationResult] = useState(null)
  const [isVerifying, setIsVerifying] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)
  const [error, setError] = useState(null)
  const [splitPosition, setSplitPosition] = useState(50)
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)
  const [isMobileView, setIsMobileView] = useState(false)

  const editorRef = useRef(null)
  const containerRef = useRef(null)
  const resizeTimeoutRef = useRef(null)

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth < 768)
    }
    checkMobileView()
    window.addEventListener('resize', checkMobileView)
    return () => window.removeEventListener('resize', checkMobileView)
  }, [])

  const handleResize = useCallback(
    debounce(() => {
      if (editorRef.current) {
        editorRef.current.layout()
      }
    }, 100),
    []
  )

  useEffect(() => {
    updateOutput()
  }, [html, css, javascript])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      handleResize.cancel()
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(
      debounce(() => {
        if (editorRef.current) {
          editorRef.current.layout();
        }
      }, 10)
    );

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  const updateOutput = () => {
    const combinedOutput = `
      <html>
        <head>
          <style>${css}</style>
        </head>
        <body>${html}
          <script>${javascript}</script>
        </body>
      </html>
    `
    setOutput(combinedOutput)
  }

  const toggleFullScreen = useCallback(() => {
    setIsFullScreen((prev) => !prev)
  }, [])

  const openInspectElementModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const verifyCode = async () => {
    setIsVerifying(true)
    setVerificationResult(null)
    setError(null)
    try {
      const response = await axios.post('https://codeforcambodia.codes/codecademy/compile/verify-html-css', {
        html: html,
        css: css,
        js: javascript,
        expectedOutput: lesson.sample_output
      })

      setVerificationResult(response.data)
      if (response.data.correct) {
        setShowConfetti(true)
        setTimeout(() => setShowConfetti(false), 5000)
      }
      setIsVerificationModalOpen(true)
    } catch (error) {
      console.error('Error verifying code:', error)
      setVerificationResult({
        correct: false,
        message: error.response?.data?.message || 'An error occurred',
        output: ''
      })
      setIsVerificationModalOpen(true)
    }
    setIsVerifying(false)
  }

  const handleEditorMount = (editor, monaco) => {
    editorRef.current = editor
    setTimeout(() => {
      editor.layout()
    }, 0)
  }

  const handleSplitChange = useCallback((ratio) => {
    setSplitPosition(ratio);
    if (editorRef.current) {
      setTimeout(() => editorRef.current.layout(), 0);
    }
  }, []);

  const renderCodeEditor = () => {
    return (
      <div className="flex-1 md:h-[68vh] h-[90vh] pb-24 md:pb-0">
        <div className="h-full flex flex-col sm:flex-row">
          <div style={{
            width: isMobileView ? '100%' : `${splitPosition}%`,
            display: isMobileView && splitPosition === 0 ? 'none' : 'block',
            height: isMobileView ? '50%' : '100%'
          }} className="h-full">

            <ReusableEditor
              activeCodeTab={activeCodeTab}
              code={activeCodeTab === 'html' ? html : activeCodeTab === 'css' ? css : javascript}
              handleEditorMount={handleEditorMount}
              onChange={(value) =>
                activeCodeTab === 'html'
                  ? setHtml(value)
                  : activeCodeTab === 'css'
                    ? setCss(value)
                    : setJavascript(value)
              }
              options={{
                minimap: { enabled: false },
                scrollBeyondLastLine: false,
                fontSize: isMobileView ? 12 : 14,
                lineNumbers: 'on',
                renderLineHighlight: 'all',
                tabSize: 2,
                scrollbar: {
                  vertical: 'visible',
                  horizontal: 'visible'
                }
              }}
              theme="vs-light"
            />
          </div>
          <div style={{
            width: isMobileView ? '100%' : `${100 - splitPosition}%`,
            display: isMobileView && splitPosition === 100 ? 'none' : 'block',
            height: isMobileView ? '50%' : '100%'
          }} className="h-full">
            {renderOutput('Your Output', output, 'h-full')}
          </div>
        </div>
      </div>
    )
  }

  const renderOutput = (title, content, styling, extra) => (
    <ReusablePreview
      preview={
      <iframe
        srcDoc={content}
        title={title.toLowerCase()}
        sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"
        className={`w-full h-[calc(100%-2rem)] bg-white`}
      />
    }
    />
  )

  return (
    <div className="bg-white text-gray-800 font-sans min-h-screen flex flex-col">
      {showConfetti && <ReactConfetti />}
      {isSubscribed ? <CodeForCambodiaHeader /> : <FreeTrialHeader/>}
      <header className="bg-gray-100 p-2 flex items-center space-x-2 border-b border-gray-200 sticky top-0 z-10">
        <div className="flex space-x-1">
          <div className="w-3 h-3 rounded-full bg-red-500"></div>
          <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
          <div className="w-3 h-3 rounded-full bg-green-500"></div>
        </div>
        <h1 className="text-xs sm:text-sm flex-grow text-center font-semibold">{lesson.lesson_name}</h1>
      </header>

      <div className={`flex flex-grow ${isFullScreen ? 'fixed inset-0 z-50 bg-white' : ''}`}>
        {!isFullScreen && (
          <nav className={`bg-gray-100 w-12 md:w-48 flex flex-col border-r border-gray-200 sticky ${isMobileView ? 'hidden' : ''}`}>
            {[
              { id: 'instructions', icon: Terminal, label: 'Instructions' },
              { id: 'code', icon: Code, label: 'Code' },
              { id: 'sample', icon: Play, label: 'Sample' }
            ].map(({ id, icon: Icon, label }) => (
              <Button
                key={id}
                onClick={() => setActiveSection(id)}
                className={`justify-start w-full py-2 sm:py-3 ${activeSection === id ? 'bg-blue-100 text-blue-600' : 'text-gray-600 hover:bg-gray-200'
                  }`}
                icon={Icon}
              >
                <span className="hidden md:inline">{label}</span>
              </Button>
            ))}
          </nav>
        )}

        <main className={`flex-grow p-2 sm:p-4 pt-0 overflow-hidden ${isFullScreen ? 'h-screen' : 'h-full'} pb-4`} ref={containerRef}>
          {isMobileView && !isFullScreen && (
            <div className="flex justify-center mb-4">
              {['instructions', 'code', 'sample'].map((section) => (
                <Button
                  key={section}
                  onClick={() => setActiveSection(section)}
                  className={`mx-1 ${activeSection === section ? 'bg-blue-100 text-blue-600' : 'text-gray-600 hover:bg-gray-200'
                    }`}
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </Button>
              ))}
            </div>
          )}

          {activeSection === 'instructions' && !isFullScreen && (
            <div className="space-y-4 overflow-auto h-full Nokora pt-4 pb-24 md:pb-16">
              {lesson?.instructions ? (
                <article className="prose prose-sm sm:prose lg:prose-lg max-w-none">
                  {parse(lesson.instructions, options)}
                </article>
              ) : (
                <div className="flex items-center justify-center h-32 sm:h-64 bg-gray-100 rounded">
                  <p className="text-gray-600 italic text-xs sm:text-base">
                    No content available for this lesson.
                  </p>
                </div>
              )}
            </div>
          )}

          {activeSection === 'code' && (
            <div className="flex flex-col h-full">
              <div className="flex flex-col sm:flex-row justify-between items-stretch sm:items-center border-b border-gray-200 pb-2 gap-2">
                <div className="flex w-full sm:w-auto">
                  {['html', 'css', 'js'].map((tab) => (
                    <Tab
                      key={tab}
                      active={activeCodeTab === tab}
                      onClick={() => setActiveCodeTab(tab)}
                    >
                      {tab.toUpperCase()}
                    </Tab>
                  ))}
                </div>
                <div className={`flex items-center flex-wrap gap-1 ${isMobileView ? 'justify-center' : 'justify-end'}`}>
                  {isMobileView ? (
                    <Button
                      onClick={() => handleSplitChange(splitPosition === 100 ? 50 : 100)}
                      className="text-gray-600 hover:bg-gray-200"
                      icon={Layout}
                    >
                      {splitPosition === 100 ? 'Show Preview' : 'Show Editor'}
                    </Button>
                  ) : (
                    <Dropdown label="ទំហំ" icon={Layout} className="Nokora">
                      {SPLIT_RATIOS.map(ratio => (
                        <button
                          key={ratio}
                          onClick={() => handleSplitChange(ratio)}
                          className={`
                            w-full px-4 py-2 text-left text-sm
                            ${Math.round(splitPosition) === ratio
                              ? 'bg-blue-50 text-blue-600'
                              : 'text-gray-700 hover:bg-gray-100'
                            }
                          `}
                        >
                          {ratio}%
                        </button>
                      ))}
                    </Dropdown>
                  )}

                  <Button
                    onClick={verifyCode}
                    disabled={isVerifying}
                    className={`
                      font-mono text-xs sm:text-sm py-1 sm:py-2 rounded-sm
                      transition-all duration-200 ease-in-out
                      ${isVerifying
                        ? 'bg-zinc-100 text-zinc-400 cursor-not-allowed'
                        : 'bg-white text-zinc-800 hover:bg-zinc-100 hover:border-zinc-400 active:bg-zinc-200'
                      }
                      focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-opacity-50
                    `}
                  >
                    <div className="flex items-center justify-center space-x-1 sm:space-x-2">
                      {isVerifying ? (
                        <LoaderIcon className="w-3 h-3 sm:w-4 sm:h-4 animate-spin" />
                      ) : (
                        <TerminalIcon className="w-3 h-3 sm:w-4 sm:h-4" />
                      )}
                      <span className="hidden sm:inline">
                        {isVerifying ? 'Verifying...' : 'Verify Code'}
                      </span>
                    </div>
                  </Button>

                  <Dropdown label="Settings" icon={Settings}>
                    <button
                      onClick={() => window.open('https://t.me/+EaKiMA8GiM0wMjI1', '_blank')}
                      className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center Nokora"
                    >
                      <Bug className="w-4 h-4 mr-2" />
                      មានបញ្ហា
                    </button>
                    <button
                      onClick={openInspectElementModal}
                      className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                    >
                      <Search className="w-4 h-4 mr-2" />
                      DevTools
                    </button>
                    <button
                      onClick={() => {
                        setHtml(lesson.starter_code_html || '');
                        setCss(lesson.starter_code_css || '');
                        setJavascript(lesson.starter_code_js || '');
                        setVerificationResult(null);
                        setError(null);
                      }}
                      className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                    >
                      <RefreshCw className="w-4 h-4 mr-2" />
                      Reset
                    </button>
                  </Dropdown>

                  <Button
                    onClick={toggleFullScreen}
                    className="text-gray-600 hover:bg-gray-200"
                    icon={isFullScreen ? Minimize2 : Maximize2}
                  />
                </div>
              </div>
              <div className="flex-1 overflow-hidden" ref={containerRef}>
                {renderCodeEditor()}
              </div>
              <VerificationResult
                verificationResult={verificationResult}
                error={error}
                isOpen={isVerificationModalOpen}
                onClose={() => setIsVerificationModalOpen(false)}
              />
            </div>
          )}

          {activeSection === 'sample' && !isFullScreen && (
            <div className="h-[calc(75vh)] pt-4 pb-24 md:pb-0">
              {renderOutput('Sample Output', lesson.sample_output, 'h-full')}
            </div>
          )}
        </main>
      </div>

      {isModalOpen && (
        <DevToolsModal isOpen={isModalOpen} onClose={closeModal} />
      )}
    </div>
  )
}