import React from 'react'
const ReusablePreview = ({
  splitPosition,
  isDragging,
  preview,
}) => {
  return (
    <div className="h-full border border-gray-200 rounded-md overflow-hidden">
      <div className="bg-gray-100 px-4 py-2 flex items-center space-x-1 border-b border-gray-200">
        <div className="w-2 h-2 rounded-full bg-red-500"></div>
        <div className="w-2 h-2 rounded-full bg-yellow-500"></div>
        <div className="w-2 h-2 rounded-full bg-green-500"></div>
      </div>
      <div className="h-[calc(100%-2.5rem)] overflow-auto">
        {preview}
      </div>
    </div>
  )
}
export const PhpReusablePreview = ({ className, outputHtml }) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: outputHtml }}
    >
    </div>
  )
}
export default ReusablePreview