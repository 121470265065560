import NetworkState from './NetworkState';
import { useRef } from 'react';

export const useNetworkManager = () => {
    const networkState = useRef(new NetworkState()).current;
  
    const addDevice = (data) => {
      try {
        const device = networkState.addDevice(data.type, data.position.x, data.position.y);
        return device;
      } catch (error) {
        return { error: error.message };
      }
    };
  
    const addConnection = (data) => {
      try {
        const fromDevice = networkState.devices.get(data.fromDevice);
        const toDevice = networkState.devices.get(data.toDevice);
        
        if (!fromDevice || !toDevice) return { error: 'One or more devices not found' };
        
        const connectionId = networkState.addConnection(fromDevice, toDevice);
        return networkState.connections.get(connectionId);
      } catch (error) {
        return { error: error.message };
      }
    };
  
    const updateDevicePosition = (data) => {
      const device = networkState.devices.get(data.deviceId);
      if (device) {
        device.position = data.position;
        return true;
      }
      return false;
    };
  
    const deleteConnection = (connectionId) => {
      return networkState.deleteConnection(connectionId);
    };
  
    const executeCommand = (data) => {
      try {
        return networkState.executeCommand(data.command, data.deviceId);
      } catch (error) {
        return { error: error.message, input: data.command };
      }
    };
  
    const reset = () => {
        networkState.reset();
        return true;
    };
  
    return {
      networkState,
      addDevice,
      addConnection,
      updateDevicePosition,
      deleteConnection,
      executeCommand,
      reset
    };
  };