'use client'

import React, { useState } from 'react';
import { Terminal, Code, FileText } from 'lucide-react';
import parse from 'html-react-parser';
import JupyterLiteEmbed from '../../Components/Lessons/Jupyter/JupyterLab';
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader';
import FreeTrialHeader from '../../Components/Headers/FreeTrialHeader';
import Footer from '../../Components/Footer';
import options from '../../Components/Lessons/OptionsQuill';

const Tab = React.memo(({ active, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm font-medium border-b-2 flex-1 ${
      active
        ? 'text-blue-600 border-blue-500'
        : 'text-gray-600 hover:text-gray-800 border-transparent'
    }`}
  >
    {children}
  </button>
));

export default function JupyterLesson({ lesson, isSubscribed }) {
  const [activeSection, setActiveSection] = useState('instructions');

  return (
    <div className="flex flex-col bg-white">
      {isSubscribed ? <CodeForCambodiaHeader /> : <FreeTrialHeader />}
      
      <div className="flex flex-col min-h-[75vh] bg-white text-gray-800 font-sans">
        <div className="flex border-b border-gray-200">
          <Tab
            active={activeSection === 'instructions'}
            onClick={() => setActiveSection('instructions')}
          >
            Instructions
          </Tab>
          <Tab
            active={activeSection === 'notebook'}
            onClick={() => setActiveSection('notebook')}
          >
            Jupyter Notebook
          </Tab>
        </div>

        {activeSection === 'instructions' ? (
          <div className="flex-grow overflow-auto p-4">
            <div className="prose max-w-none">
              {lesson?.instructions ? 
                parse(lesson.instructions, options) : 
                <p className="text-gray-600 italic">No instructions available for this lesson.</p>
              }
            </div>
          </div>
        ) : (
          <div className="flex-grow">
            <JupyterLiteEmbed />
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}