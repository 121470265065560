import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Circle, ChevronUp, ChevronDown, BookOpen, Clock, ClipboardList, FileText, Code, BarChart2 } from 'lucide-react';
import { useState } from 'react';
import { checkCourseAccess } from './functions/courseAccess';

const CourseCardMac = ({ course, courseProgress = null, progressLoading = false }) => {
  const [expandedDescriptions, setExpandedDescriptions] = useState({})
  const [courseAccessStatus, setCourseAccessStatus] = useState(() => {
    // Initialize from cache if available
    const cached = localStorage.getItem(`courseAccess_${course.id}`);
    return cached ? JSON.parse(cached) : { isFree: true, hasAccess: true };
  });

  // Safely access properties using optional chaining
  const currentModuleId = courseProgress?.current_module_id;
  const currentLessonId = courseProgress?.current_lesson_id;
  const progressPercentage = courseProgress?.progress_percentage || 0;

  const isExpanded = expandedDescriptions[course.id]
  const token = localStorage.getItem('token');


  useEffect(() => {
    const checkAccess = async () => {
      if (!token) return;

      // Check cache first
      const cached = localStorage.getItem(`courseAccess_${course.id}`);
      const cacheTimestamp = localStorage.getItem(`courseAccessTimestamp_${course.id}`);
      const CACHE_DURATION = 1000 * 60 * 60; // 1 hour

      // Use cache if it's still valid
      if (cached && cacheTimestamp && Date.now() - parseInt(cacheTimestamp) < CACHE_DURATION) {
        setCourseAccessStatus(JSON.parse(cached));
        return;
      }

      try {
        const access = await checkCourseAccess(course.id);
        setCourseAccessStatus(access);
        // Update cache
        localStorage.setItem(`courseAccess_${course.id}`, JSON.stringify(access));
        localStorage.setItem(`courseAccessTimestamp_${course.id}`, Date.now().toString());
      } catch (err) {
        console.error('Error checking course access:', err);
      }
    };
    
    checkAccess();
  }, [course.id, token]);

  const toggleDescription = (e, courseId) => {
    e.stopPropagation(); // Prevent event bubbling
    setExpandedDescriptions(prev => ({
      ...prev,
      [courseId]: !prev[courseId]
    }))
  }

  const navigate = useNavigate();

  const handleCardClick = () => {
    if (course.status === 1) {
      navigate(`/modules/${course.id}/course`);
    }
  };

  return (
    <div className="bg-white rounded-lg overflow-hidden border border-gray-200 transition-all duration-300 hover:border-blue-500 cursor-pointer relative" onClick={handleCardClick}>
      <div className="bg-gray-200 px-4 py-2 flex items-center">
        <Circle size={12} fill="#ff5f56" stroke="none" className="mr-2" />
        <Circle size={12} fill="#ffbd2e" stroke="none" className="mr-2" /> 
        <Circle size={12} fill="#27c93f" stroke="none" className="mr-2" />
        <span className="text-sm text-gray-600 flex-grow text-center Nokora">
          {course.status === 0 ? 'មកដល់ឆាប់ៗ' : token ? (courseAccessStatus.isFree ? 'Free course' : 'Premium course') : 'CodeForCambodia'}
        </span>
      </div>
      {course.status === 1 && token && (
        <div className={`absolute top-0 right-0 m-2 px-2 py-1 text-xs font-bold rounded ${courseAccessStatus.isFree ? 'bg-green-500 text-white' : 'bg-yellow-500 text-gray-900'}`}>
          {courseAccessStatus.isFree ? 'FREE' : 'PREMIUM'}
        </div>
      )}
      <div className="p-6">
        <h2 className="text-xl font-bold mb-2 text-blue-600 Nokora">{course.course_name}</h2>
        <div className={`text-gray-600 mb-2 Nokora ${isExpanded ? '' : 'line-clamp-3'}`}>
          {course.description}
        </div>
        <button 
          onClick={(e) => toggleDescription(e, course.id)}
          className="text-blue-500 Nokora hover:text-blue-700 text-sm flex items-center mb-4"
        >
          {isExpanded ? (
            <>
              <ChevronUp size={16} className="mr-1" />
              បន្ថយ
            </>
          ) : (
            <>
              <ChevronDown size={16} className="mr-1" />
              អានបន្ថែម
            </>
          )}
        </button>
        <div className="grid grid-cols-2 gap-2 text-sm text-gray-500 mb-4 Nokora">
          <span className="flex items-center">
            <BookOpen className="w-4 h-4 mr-1" />
            {course.module_count} ជំពូក
          </span>
          <span className="flex items-center">
            <Clock className="w-4 h-4 mr-1" />
            {course.lesson_count} មេរៀន
          </span>
          <span className="flex items-center">
            <ClipboardList className="w-4 h-4 mr-1" />
            {course.quiz_count} ប្រលង
          </span>
          <span className="flex items-center">
            <FileText className="w-4 h-4 mr-1" />
            {course.document_count} ឯកសារ
          </span>
          <span className="flex items-center col-span-2">
            <Code className="w-4 h-4 mr-1" />
            {course.coding_count} លំហាត់កូដ
          </span>
        </div>

        {/* Progress Section */}
        {progressLoading ? (
          <div className="mb-4">
            <div className="animate-pulse space-y-2">
              <div className="flex items-center gap-1">
                <div className="w-4 h-4 bg-gray-200 rounded"></div>
                <div className="h-4 bg-gray-200 rounded flex-grow"></div>
              </div>
              <div className="h-2 bg-gray-200 rounded-full"></div>
              <div className="h-3 bg-gray-200 rounded w-2/3"></div>
            </div>
          </div>
        ) : courseProgress && (
          <div className="mb-4">
            <div className="flex items-center justify-between text-sm text-gray-500 mb-1">
              <span className="flex items-center Nokora">
                <BarChart2 className="w-4 h-4 mr-1" />
                វឌ្ឍនភាពការរៀន: {courseProgress.progress_percentage}%
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div 
                className="bg-blue-600 h-2 rounded-full" 
                style={{width: `${courseProgress.progress_percentage}%`}}
              ></div>
            </div>
            <p className="text-xs text-gray-500 Nokora mt-1">
              កំពុងរៀន: {courseProgress.current_lesson_name}
            </p>
          </div>
        )}

        {/* Action Button */}
        {course.status === 1 ? (
          <Link 
            to={
              courseProgress 
                ? (!courseAccessStatus.isFree && !courseAccessStatus.hasAccess)
                  ? `/modules/${course.id}/course`  // If premium course and no access, go to course page
                  : `/courses/${course.id}/modules/${currentModuleId}/lessons/${currentLessonId}` // If has access, go to current lesson
                : `/modules/${course.id}/course` // If no progress, go to course page
            }
            onClick={(e) => e.stopPropagation()}
            className={`block Nokora w-full bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded text-center transition-colors duration-300 ${!courseProgress && 'bg-green-500 hover:bg-green-600'}`}
          >
            {courseProgress ? 'រៀនបន្តរ' : 'រៀនឥឡូវ'}
          </Link>
        ) : (
          <button 
            onClick={(e) => e.stopPropagation()}
            className="block w-full Nokora bg-gray-300 text-gray-500 font-medium py-2 px-4 rounded text-center cursor-not-allowed"
            disabled
          >
            មកដល់ឆាប់ៗ
          </button>
        )}
      </div>
    </div>
  );
};

export default CourseCardMac;