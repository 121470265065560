export default class ShowCommands {
    constructor(networkState) {
        this.networkState = networkState;
    }

    canHandle(cmd) {
        return ['show'].includes(cmd);
    }

    execute(cmd, args, deviceId) {
        if (cmd === 'show') {
            return this.handleShow(args, deviceId);
        }
        return { error: 'Unknown show command' };
    }

    handleShow(args, deviceId) {
        const device = this.networkState.devices.get(deviceId);
        
        switch(args[0]) {
            case 'interfaces':
                return this.showInterfaces(device);
            case 'ip':
                return this.showIp(args.slice(1), device);
            case 'running-config':
                return this.showRunningConfig(device);
            default:
                return { error: 'Invalid show command' };
        }
    }

    showInterfaces(device) {
        return {
            success: true,
            output: Object.entries(device.interfaces).map(([name, info]) =>
                `${name} is ${info.status}${info.ip ? `, IP address is ${info.ip}` : ''}${info.subnet ? `, subnet mask ${info.subnet}` : ''}`
            ),
            prompt: this.networkState.getCommandPrompt(device.id)
        };
    }

    showIp(args, device) {
        if (args[0] === 'interface') {
            return {
                success: true,
                output: Object.entries(device.interfaces).map(([name, info]) =>
                    `${name.padEnd(20)} ${info.ip || 'unassigned'} ${
                        info.status.padEnd(10)
                    } ${info.subnet || ''}`
                ),
                prompt: this.networkState.getCommandPrompt(device.id)
            };
        }
        return { error: 'Invalid show ip command' };
    }

    showRunningConfig(device) {
        return {
            success: true,
            output: this.generateRunningConfig(device),
            prompt: this.networkState.getCommandPrompt(device.id)
        };
    }

    generateRunningConfig(device) {
        const config = [
            '!',
            `hostname ${device.config.hostname}`,
            '!'
        ];

        // Add interface configurations
        Object.entries(device.interfaces).forEach(([name, info]) => {
            config.push(`interface ${name}`);
            if (info.ip) {
                config.push(` ip address ${info.ip} ${info.subnet}`);
            }
            if (info.status === 'up') {
                config.push(' no shutdown');
            } else {
                config.push(' shutdown');
            }
            config.push('!');
        });

        // Add routing protocols if configured
        if (device.config.routing) {
            Object.entries(device.config.routing).forEach(([protocol, config]) => {
                config.push(`router ${protocol}`);
                config.networks?.forEach(network => {
                    config.push(` network ${network}`);
                });
                config.push('!');
            });
        }

        return config;
    }

    showHelp(mode, deviceType) {
        const commands = {
            user: [
                'enable - Enter privileged mode',
                'show interfaces - Show interface status',
                'show ip interface - Show IP interface status',
                'exit - Exit current mode'
            ],
            privileged: [
                'configure terminal - Enter configuration mode',
                'show running-config - Show current configuration',
                'show interfaces - Show interface status',
                'show ip interface - Show IP interface status',
                'exit - Return to user mode'
            ],
            config: [
                'interface <interface-name> - Configure interface',
                'hostname <name> - Set device hostname',
                'exit - Return to privileged mode',
                'end - Return to privileged mode'
            ],
            'config-if': [
                'ip address <ip-address> <subnet-mask> - Set interface IP address',
                'no shutdown - Enable interface',
                'shutdown - Disable interface',
                'exit - Return to config mode',
                'end - Return to privileged mode'
            ]
        };

        return {
            success: true,
            output: [
                'Available commands:',
                ...commands[mode]
            ]
        };
    }
}

