export const fetchSubscriptionStatus = async (token) => {
    const response = await fetch('https://codeforcambodia.codes/codecademy/stripe/subscription-status', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    const data = await response.json();
    
    // Store with timestamp
    localStorage.setItem('lastKnownSubscription', JSON.stringify({
        ...data,
        timestamp: Date.now()
    }));
    
    return data;
};

export const getLastKnownSubscription = () => {
    const cached = localStorage.getItem('lastKnownSubscription');
    if (!cached) return null;
    
    const data = JSON.parse(cached);
    const CACHE_VALIDITY_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

    if (Date.now() - data.timestamp > CACHE_VALIDITY_DURATION) {
        console.log('Cache expired');
        return null; // Cache expired
    }


    return data;
};

export const clearSubscriptionCache = () => {
    localStorage.removeItem('lastKnownSubscription');

    // Remove all courseAccess and courseAccessTimestamp entries
    Object.keys(localStorage).forEach(key => {
        if (key.startsWith('courseAccess_') || key.startsWith('courseAccessTimestamp_')) {
            localStorage.removeItem(key);
        }
    });
};