import React, { useState } from 'react';
import { Send, ChevronDown, ChevronUp, Globe, Code, Server, X, Plus, Settings } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const RequestPanel = ({ onSend }) => {
  const [method, setMethod] = useState('GET');
  const [url, setUrl] = useState('/');
  const [showBody, setShowBody] = useState(false);
  const [body, setBody] = useState('');
  const [headers, setHeaders] = useState([{ key: '', value: '' }]);
  const [queryParams, setQueryParams] = useState([{ key: '', value: '' }]);

  const handleSend = () => {
    const headersObj = headers.reduce((acc, { key, value }) => {
      if (key.trim() && value.trim()) {
        acc[key] = value;
      }
      return acc;
    }, {});

    let finalUrl = url;
    const queryString = queryParams
      .filter(({ key, value }) => key.trim() && value.trim())
      .map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
    
    if (queryString) {
      finalUrl += (url.includes('?') ? '&' : '?') + queryString;
    }

    let parsedBody = null;
    if (body.trim()) {
      try {
        parsedBody = JSON.parse(body);
      } catch (error) {
        alert('Invalid JSON in request body');
        return;
      }
    }

    onSend({
      method,
      url: finalUrl,
      headers: headersObj,
      body: parsedBody
    });
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="border rounded-lg bg-white shadow-sm"
    >
      <div className="p-4 space-y-4">
        <div className="flex flex-col lg:flex-row gap-2">
          <div className="flex items-center gap-2 w-full lg:w-auto">
            <Globe className="w-4 h-4 text-gray-500" />
            <select
              value={method}
              onChange={(e) => setMethod(e.target.value)}
              className="border rounded px-3 py-1.5 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500 text-sm"
            >
              <option>GET</option>
              <option>POST</option>
              <option>PUT</option>
              <option>DELETE</option>
              <option>PATCH</option>
            </select>
          </div>
          <div className="flex-1 relative">
            <Code className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter URL"
              className="w-full border rounded pl-10 pr-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500 text-sm"
            />
          </div>
          <motion.button
            onClick={handleSend}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center justify-center gap-2 shadow-sm text-sm"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <Send className="w-4 h-4" />
            <span className="hidden sm:inline">Send Request</span>
          </motion.button>
        </div>

        <div className="space-y-2">
          <button
            onClick={() => setShowBody(!showBody)}
            className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 transition-colors duration-200"
          >
            <Settings className="w-4 h-4" />
            {showBody ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
            Request Configuration
          </button>
          
          <AnimatePresence>
            {showBody && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                className="space-y-4 overflow-hidden"
              >
                <div className="space-y-2">
                  <div className="text-sm font-medium text-gray-700">Query Parameters</div>
                  {queryParams.map((param, index) => (
                    <div key={index} className="flex flex-col sm:flex-row gap-2">
                      <input
                        placeholder="Key"
                        value={param.key}
                        onChange={(e) => {
                          const newParams = [...queryParams];
                          newParams[index].key = e.target.value;
                          setQueryParams(newParams);
                        }}
                        className="flex-1 border rounded px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500"
                      />
                      <input
                        placeholder="Value"
                        value={param.value}
                        onChange={(e) => {
                          const newParams = [...queryParams];
                          newParams[index].value = e.target.value;
                          setQueryParams(newParams);
                        }}
                        className="flex-1 border rounded px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500"
                      />
                      <button
                        onClick={() => {
                          const newParams = queryParams.filter((_, i) => i !== index);
                          setQueryParams(newParams);
                        }}
                        className="text-red-500 hover:text-red-700 px-2 transition-colors duration-200"
                      >
                        ×
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={() => setQueryParams([...queryParams, { key: '', value: '' }])}
                    className="text-sm text-blue-500 hover:text-blue-700 transition-colors duration-200"
                  >
                    + Add Query Parameter
                  </button>
                </div>

                <div className="space-y-2">
                  <div className="text-sm font-medium text-gray-700">Headers</div>
                  {headers.map((header, index) => (
                    <div key={index} className="flex flex-col sm:flex-row gap-2">
                      <input
                        placeholder="Key"
                        value={header.key}
                        onChange={(e) => {
                          const newHeaders = [...headers];
                          newHeaders[index].key = e.target.value;
                          setHeaders(newHeaders);
                        }}
                        className="flex-1 border rounded px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500"
                      />
                      <input
                        placeholder="Value"
                        value={header.value}
                        onChange={(e) => {
                          const newHeaders = [...headers];
                          newHeaders[index].value = e.target.value;
                          setHeaders(newHeaders);
                        }}
                        className="flex-1 border rounded px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500"
                      />
                      <button
                        onClick={() => {
                          const newHeaders = headers.filter((_, i) => i !== index);
                          setHeaders(newHeaders);
                        }}
                        className="text-red-500 hover:text-red-700 px-2 transition-colors duration-200"
                      >
                        ×
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={() => setHeaders([...headers, { key: '', value: '' }])}
                    className="text-sm text-blue-500 hover:text-blue-700 transition-colors duration-200"
                  >
                    + Add Header
                  </button>
                </div>

                <div className="space-y-2">
                  <div className="text-sm font-medium text-gray-700">Request Body (JSON)</div>
                  <textarea
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    placeholder="{}"
                    rows={4}
                    className="w-full border rounded px-3 py-2 font-mono text-sm focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500"
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
};

const ResponsePanel = ({ response }) => {
  if (!response) return null;

  return (
    <div className="border rounded-lg bg-white shadow-sm">
      <div className="p-4 space-y-4">
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium text-gray-700">Status:</span>
          <span className={`px-2 py-1 rounded text-sm ${
            response.status >= 200 && response.status < 300 
              ? 'bg-green-100 text-green-800'
              : 'bg-red-100 text-red-800'
          }`}>
            {response.status}
          </span>
        </div>

        {response.headers && Object.keys(response.headers).length > 0 && (
          <div className="space-y-2">
            <div className="text-sm font-medium text-gray-700">Response Headers</div>
            <div className="border rounded bg-gray-50 p-2">
              {Object.entries(response.headers || {}).map(([key, value]) => (
                <div key={key} className="text-sm">
                  <span className="font-medium">{key}:</span> {value}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="space-y-2">
          <div className="text-sm font-medium text-gray-700">Response Body</div>
          <pre className="border rounded bg-gray-50 p-2 overflow-auto max-h-96 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
            <code className="text-sm">
              {typeof response.body === 'string' 
                ? response.body 
                : JSON.stringify(response.body, null, 2)}
            </code>
          </pre>
        </div>
      </div>
    </div>
  );
};

export default function BrowserSimulator({ onRequest }) {
  const [response, setResponse] = useState(null);

  const handleRequest = async (config) => {
    const result = await onRequest(config);
    setResponse(result);
  };

  return (
    <div className="h-full flex flex-col gap-4 p-4 bg-gray-50 overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
      <RequestPanel onSend={handleRequest} />
      <ResponsePanel response={response} />
    </div>
  );
}