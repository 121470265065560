import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { BookOpen, Clock, BarChart2, Circle, ChevronDown, ChevronUp, FileText, ClipboardList, Code, Search, Filter } from 'lucide-react'
import Footer from '../Components/Footer'
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader'
import CodeLoadingAnimation from '../Components/CodeLoadingAnimation'
import CourseCardMac from '../Components/CourseCardMac'
import { checkCourseAccess } from '../Components/functions/courseAccess'

const AllCourses = () => {
  const [courses, setCourses] = useState([])
  const [progress, setProgress] = useState([])
  const [coursesLoading, setCoursesLoading] = useState(true)
  const [progressLoading, setProgressLoading] = useState(true)
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState('all') // 'all', 'free', or 'premium'
  const [courseAccessStatus, setCourseAccessStatus] = useState({})
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  useEffect(() => {
    const BACKEND_BASE_URL = 'https://codeforcambodia.codes'
    
    const fetchCourses = async () => {
      try {
        const coursesResponse = await axios.get(`${BACKEND_BASE_URL}/codecademy/fetch/courses`)
        setCourses(coursesResponse.data)
        setCoursesLoading(false)
        
        // Check access status for all courses concurrently
        const token = localStorage.getItem('token')
        if (token) {
          try {
            const accessStatuses = {}
            const courseIds = coursesResponse.data.map(course => course.id)
            
            // Initiate all access checks concurrently
            const accessPromises = courseIds.map(id =>
              checkCourseAccess(id).catch(err => {
                console.error(`Error checking access for course ${id}:`, err)
                // Return default access status even if check fails
                const course = coursesResponse.data.find(course => course.id === id)
                return { isFree: course?.is_free ?? true } // Default to true if course not found
              })
            )
            
            // Wait for all access checks to complete
            const accessResults = await Promise.all(accessPromises)
            
            // Map results back to course IDs
            accessResults.forEach((access, index) => {
              accessStatuses[courseIds[index]] = access
            })
            
            setCourseAccessStatus(accessStatuses)
          } catch (err) {
            console.error('Error checking course access statuses:', err)
            // Set default access status for all courses
            const defaultStatuses = {}
            coursesResponse.data.forEach(course => {
              defaultStatuses[course.id] = { isFree: course.is_free }
            })
            setCourseAccessStatus(defaultStatuses)
          }
        } else {
          // If no token, set default access status based on course.is_free
          const defaultStatuses = {}
          coursesResponse.data.forEach(course => {
            defaultStatuses[course.id] = { isFree: course.is_free }
          })
          setCourseAccessStatus(defaultStatuses)
        }
      } catch (err) {
        console.error('Error fetching courses:', err)
        setError('Error fetching courses')
        setCoursesLoading(false)
      }
    }
    

    const fetchProgress = async () => {
      const token = localStorage.getItem('token')
      if (token) {
        try {
          const headers = { Authorization: `Bearer ${token}` }
          const progressResponse = await axios.get(`${BACKEND_BASE_URL}/codecademy/user/progress`, { headers })
          setProgress(progressResponse.data.courses)
        } catch (progressError) {
          console.error('Error fetching progress data:', progressError)
        }
        setProgressLoading(false)
      } else {
        setProgressLoading(false)
      }
    }

    fetchCourses()
    fetchProgress()
  }, [])

  const filteredCourses = useMemo(() => {
    return courses
      .filter(course => {
        const matchesSearch = course.course_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                              course.description.toLowerCase().includes(searchTerm.toLowerCase())
        
        if (filter === 'all') return matchesSearch
        
        const isFree = courseAccessStatus[course.id]?.isFree ?? course.is_free
        
        return matchesSearch && (
          (filter === 'free' && isFree) ||
          (filter === 'premium' && !isFree)
        )
      })
      .sort((a, b) => a.course_index - b.course_index)
  }, [courses, courseAccessStatus, searchTerm, filter])
  

  if (coursesLoading) {
    return <CodeLoadingAnimation />
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 text-red-500">
        {error}
      </div>
    )
  }

  return (
    <div className="bg-gray-100 min-h-screen font-mono text-gray-800">
      <CodeForCambodiaHeader />
      
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold mb-8 text-center">
          <span className="text-blue-600">const</span>{' '}
          <span className="text-green-600 Nokora">ថ្នាក់ទាំងអស់</span>{' '}
          <span className="text-blue-600">=</span>{' '}
          <span className="text-orange-500">{'['}</span>
        </h1>

        {/* Updated Search and Filter Section */}
        <div className="mb-8">
          <div className="relative  mx-auto flex gap-2">
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="ស្វែងរកវគ្គសិក្សា..."
                className="w-full px-4 py-2 pl-10 pr-4 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500 Nokora"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            
            <div className="relative">
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="px-4 py-2 rounded-lg bg-white border border-gray-300 hover:bg-gray-50 flex items-center gap-2"
              >
                <Filter className="h-5 w-5" />
                <span className="font-mono hidden sm:inline">filter</span>
              </button>

              {isFilterOpen && (
                <div className="absolute font-mono right-0 mt-2 w-48 rounded-lg bg-white shadow-lg border border-gray-200 z-10">
                  <button
                    onClick={() => {
                      setFilter('all')
                      setIsFilterOpen(false)
                    }}
                    className={`w-full text-left px-4 py-2  first:rounded-t-lg hover:bg-gray-50 ${
                      filter === 'all' ? 'bg-blue-50 text-blue-600' : ''
                    }`}
                  >
                    all
                  </button>
                  <button
                    onClick={() => {
                      setFilter('free')
                      setIsFilterOpen(false)
                    }}
                    className={`w-full text-left px-4 py-2  hover:bg-gray-50 ${
                      filter === 'free' ? 'bg-blue-50 text-blue-600' : ''
                    }`}
                  >
                    free
                  </button>
                  <button
                    onClick={() => {
                      setFilter('premium')
                      setIsFilterOpen(false)
                    }}
                    className={`w-full text-left px-4 py-2  last:rounded-b-lg hover:bg-gray-50 ${
                      filter === 'premium' ? 'bg-blue-50 text-blue-600' : ''
                    }`}
                  >
                    premium
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Courses Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredCourses.map((course) => {
            const courseProgress = progress.find(p => p.course_id === course.id)
            return (
              <CourseCardMac
                key={course.id}
                course={course}
                courseProgress={courseProgress}
                progressLoading={progressLoading}
              />
            )
          })}
        </div>

        {filteredCourses.length === 0 && (
          <div className="text-center py-8 Nokora text-gray-500">
            រកមិនឃើញវគ្គសិក្សាដែលត្រូវនឹងការស្វែងរក
          </div>
        )}

        <div className="text-center mt-8 text-2xl">
          <span className="text-orange-500">{']'}</span>
          <span className="text-gray-800">;</span>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default AllCourses