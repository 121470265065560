import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ThumbsUp, ThumbsDown, Code, Maximize2, Minimize2, User, Calendar, Eye } from 'lucide-react';
import Header from '../../Components/Headers/CodeForCambodiaHeader';
import Footer from '../../Components/Footer';
import CodeLoadingAnimation from '../../Components/CodeLoadingAnimation';

const SubmissionPage = () => {
  const { submissionId } = useParams();
  const [submission, setSubmission] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userVoted, setUserVoted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeTab, setActiveTab] = useState('preview');

  useEffect(() => {
    const fetchSubmission = async () => {
      try {
        const response = await axios.get(
          `https://codeforcambodia.codes/codecademy/challenges/submission/${submissionId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        setSubmission(response.data.data);
        setUserVoted(response.data.data.user_voted === 1);
      } catch (err) {
        console.error('Error fetching submission:', err);
        setError('Failed to load submission');
      } finally {
        setLoading(false);
      }
    };

    fetchSubmission();
  }, [submissionId]);

  const castVote = async () => {
    try {
      await axios.post(
        `https://codeforcambodia.codes/codecademy/challenges/vote/submissions/${submissionId}/vote`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setSubmission((prev) => ({
        ...prev,
        vote_count: prev.vote_count + 1,
      }));
      setUserVoted(true);
    } catch (error) {
      console.error('Error casting vote:', error);
      alert(error.response?.data?.message || 'Error casting vote');
    }
  };

  const removeVote = async () => {
    try {
      await axios.delete(
        `https://codeforcambodia.codes/codecademy/challenges/vote/submissions/${submissionId}/vote`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setSubmission((prev) => ({
        ...prev,
        vote_count: prev.vote_count - 1,
      }));
      setUserVoted(false);
    } catch (error) {
      console.error('Error removing vote:', error);
      alert(error.response?.data?.message || 'Error removing vote');
    }
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  };

  if (loading) return <CodeLoadingAnimation />;

  if (error)
    return (
      <div className="text-center text-red-600 p-4">
        {error}
      </div>
    );

  if (!submission)
    return (
      <div className="text-center p-4">
        Submission not found.
      </div>
    );

  const { html_code, css_code, js_code, project_title, first_name, last_name, description, vote_count, created_at } = submission;

  return (
    <>
      {isFullScreen ? (
        <div className="fixed inset-0 bg-white z-50">
          <button
            onClick={toggleFullScreen}
            className="absolute top-4 right-4 z-10 p-2 rounded-md bg-gray-100 hover:bg-gray-200 text-gray-600"
          >
            <Minimize2 size={20} />
          </button>
          <iframe
            srcDoc={`
              <!DOCTYPE html>
              <html>
                <head>
                  <meta charset="utf-8">
                  <style>${css_code || ''}</style>
                </head>
                <body>
                  ${html_code || ''}
                  <script>${js_code || ''}</script>
                </body>
              </html>
            `}
            className="w-full h-full"
            title="Project Preview"
          />
        </div>
      ) : (
        <div className="min-h-screen flex flex-col bg-white">
          <Header />
          <div className="flex-grow">
            <div className="container mx-auto px-4 py-8">
              <div className="bg-gray-50 border border-gray-200 rounded-lg overflow-hidden">
                {/* Top Bar */}
                <div className="h-12 border-b bg-gray-50 flex items-center justify-between px-4">
                  <div className="flex items-center gap-2">
                    <span className="text-sm font-semibold text-gray-700">{project_title || 'Untitled Project'}</span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <div className="flex items-center text-sm text-gray-600">
                      <User className="w-4 h-4 mr-1" />
                      {first_name} {last_name}
                    </div>

                    <div className="flex items-center text-sm text-gray-600">
                      <Eye className="w-4 h-4 mr-1" />
                      {vote_count} {vote_count === 1 ? 'Vote' : 'Votes'}
                    </div>
                    <button
                      onClick={toggleFullScreen}
                      className="p-2 rounded-md text-gray-600 hover:bg-gray-100"
                    >
                      <Maximize2 size={20} />
                    </button>
                  </div>
                </div>

                {/* Content Area */}
                <div className="p-4">
                  <div className="flex space-x-2 mb-4">
                    <button
                      onClick={() => setActiveTab('preview')}
                      className={`px-4 py-2 rounded-md ${
                        activeTab === 'preview' 
                          ? 'bg-blue-600 text-white' 
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      Preview
                    </button>
                    <button
                      onClick={() => setActiveTab('code')}
                      className={`px-4 py-2 rounded-md ${
                        activeTab === 'code' 
                          ? 'bg-blue-600 text-white' 
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      Code
                    </button>
                                      {/* Vote Button */}
                    <button
                      onClick={userVoted ? removeVote : castVote}
                      className={`flex items-center px-6 py-3 rounded-md text-white font-medium transition-colors ${
                        userVoted ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-600 hover:bg-blue-700'
                      }`}
                    >
                      {userVoted ? <ThumbsDown className="mr-2" /> : <ThumbsUp className="mr-2" />}
                      {userVoted ? 'Remove Vote' : 'Vote for this Project'}
                    </button>
                  </div>

                  {activeTab === 'preview' ? (
                    <div className="border border-gray-200 rounded-lg overflow-hidden h-[600px]">
                      <iframe
                        srcDoc={`
                          <!DOCTYPE html>
                          <html>
                            <head>
                              <meta charset="utf-8">
                              <style>${css_code || ''}</style>
                            </head>
                            <body>
                              ${html_code || ''}
                              <script>${js_code || ''}</script>
                            </body>
                          </html>
                        `}
                        className="w-full h-full"
                        title="Project Preview"
                      />
                    </div>
                  ) : (
                    <div className="space-y-4">
                      <div>
                        <h3 className="text-lg font-semibold mb-2 flex items-center">
                          <Code className="mr-2" /> HTML
                        </h3>
                        <pre className="bg-gray-100 p-4 rounded-md overflow-x-auto">
                          <code>{html_code}</code>
                        </pre>
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold mb-2 flex items-center">
                          <Code className="mr-2" /> CSS
                        </h3>
                        <pre className="bg-gray-100 p-4 rounded-md overflow-x-auto">
                          <code>{css_code}</code>
                        </pre>
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold mb-2 flex items-center">
                          <Code className="mr-2" /> JavaScript
                        </h3>
                        <pre className="bg-gray-100 p-4 rounded-md overflow-x-auto">
                          <code>{js_code}</code>
                        </pre>
                      </div>
                    </div>
                  )}


                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default SubmissionPage;