import React, { useState } from 'react';
import { Cable, RefreshCw } from 'lucide-react';
import ToolbarButton from './ToolbarButton';

const Toolbar = ({ selectedDevice, connectionMode, onDeviceSelect, onConnectionModeToggle, onReset, className }) => {
  const [showResetModal, setShowResetModal] = useState(false);
  const deviceTypes = ['router', 'switch', 'pc'];

  const handleReset = () => {
    setShowResetModal(true);
  };

  return (
    <>
      <div className={`flex flex-col p-2 bg-white border-r border-gray-200 ${className}`}>
        {deviceTypes.map((deviceType) => (
          <ToolbarButton
            key={deviceType}
            type={deviceType}
            isSelected={selectedDevice === deviceType}
            onClick={() => onDeviceSelect(deviceType)}
          />
        ))}
        <ToolbarButton
          type="connect"
          isSelected={connectionMode}
          onClick={onConnectionModeToggle}
          icon={<Cable className="w-5 h-5" />}
        />
        <ToolbarButton
          type="reset"
          onClick={handleReset}
          icon={<RefreshCw className="w-5 h-5" />}
        />
      </div>

      {showResetModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-sm mx-4">
            <h3 className="text-lg font-semibold mb-4">Reset Network</h3>
            <p className="text-gray-600 mb-6 Nokora">
              Reset ហើយ បាត់ហើយ អត់ save ទេ ច្បាស់អត់?
            </p>
            <div className="flex justify-end gap-3">
              <button
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md transition-colors"
                onClick={() => setShowResetModal(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-600 text-white hover:bg-red-700 rounded-md transition-colors"
                onClick={() => {
                  onReset();
                  setShowResetModal(false);
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Toolbar;