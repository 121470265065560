"use client"

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { motion } from 'framer-motion'
import { Clock, Book, ChevronRight, Code, FileText, HelpCircle, ChevronDown, ChevronUp } from 'lucide-react'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from '../Components/Footer'
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader'
import CourseCardMac from '../Components/CourseCardMac'
import CodeLoadingAnimation from '../Components/CodeLoadingAnimation'
import LearningPathHeader from '../Components/LearningPath/LearningPathHeader'
import { MultiStepLoader } from '../Components/ui/multi-step-loader'
import LearningPathStatus from '../Components/LearningPath/LearningPathStatus'

export default function LearningPathPage() {
  const [learningPath, setLearningPath] = useState(null)
  const [courses, setCourses] = useState({ courses: [] })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [progress, setProgress] = useState([])
  const [modules, setModules] = useState([])
  const [isFetchingModules, setIsFetchingModules] = useState(false)
  const [pathProgress, setPathProgress] = useState(null)
  const [progressStatus, setProgressStatus] = useState({
    isLoadingProgress: false,
    isSaving: false,
    error: null
  });

  const navigate = useNavigate()
  const { pathId } = useParams()
  const token = localStorage.getItem('token')
  
  const headers = {
    Authorization: `Bearer ${token}`
  }

  const fetchPathProgress = async () => {
    if (!token) return;
    
    // Check cache first
    const cacheKey = `pathProgress_${pathId}`;
    const cached = localStorage.getItem(cacheKey);
    const cacheTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);
    const CACHE_DURATION = 1000 * 60 * 15; // 15 minutes

    if (cached && cacheTimestamp && Date.now() - parseInt(cacheTimestamp) < CACHE_DURATION) {
      const progressData = JSON.parse(cached);
      setPathProgress(progressData);
      
      if (!progressData?.current_course_id && courses?.courses?.length > 0) {
        const currentCourseId = courses.courses[0].id;
        saveProgressInBackground(pathId, currentCourseId);
      }
      return;
    }
    
    setProgressStatus(prev => ({ ...prev, isLoadingProgress: true, error: null }));
    try {
      const progressResponse = await axios.get(
        `https://codeforcambodia.codes/codecademy/learningpath/overall-progress/${pathId}`,
        { headers }
      );
      const progressData = progressResponse.data.progress;
      
      // Cache the progress data
      localStorage.setItem(cacheKey, JSON.stringify(progressData));
      localStorage.setItem(`${cacheKey}_timestamp`, Date.now().toString());
      
      setPathProgress(progressData);

      if (!progressData?.current_course_id && courses?.courses?.length > 0) {
        const currentCourseId = courses.courses[0].id;
        saveProgressInBackground(pathId, currentCourseId);
      }
    } catch (error) {
      console.log('Error fetching progress:', error);
      setProgressStatus(prev => ({ ...prev, error: error.message }));
    } finally {
      setProgressStatus(prev => ({ ...prev, isLoadingProgress: false }));
    }
  };

  const saveProgressInBackground = async (learningPathId, courseId) => {
    setProgressStatus(prev => ({ ...prev, isSaving: true, error: null }));
    try {
      await axios.post(
        `https://codeforcambodia.codes/codecademy/learningpath/save`,
        {
          learning_path_id: learningPathId,
          course_id: courseId
        },
        { headers }
      );
      
      // Fetch updated progress without blocking
      const updatedProgressResponse = await axios.get(
        `https://codeforcambodia.codes/codecademy/learningpath/overall-progress/${pathId}`,
        { headers }
      );
      setPathProgress(updatedProgressResponse.data.progress);
    } catch (error) {
      console.error('Background save failed:', error);
      setProgressStatus(prev => ({ ...prev, error: error.message }));
    } finally {
      setProgressStatus(prev => ({ ...prev, isSaving: false }));
    }
  };

  const fetchModules = async ({ courseId }) => {
    try {
      setIsFetchingModules(true)
      const response = await axios.get(`https://codeforcambodia.codes/codecademy/fetch/courses/${courseId}/modules`)
  
      const modulesWithLessons = await Promise.all(response.data.map(async (module) => {
        const lessonsResponse = await axios.get(`https://codeforcambodia.codes/codecademy/fetch/courses/${courseId}/modules/${module.id}/lessons`)
        if (!lessonsResponse.data) {
          throw new Error(`Failed to fetch lessons for module ${module.id}`)
        }
        return { ...module, lessons: lessonsResponse.data }
      }))
      
      setModules(modulesWithLessons)
      return modulesWithLessons
    } catch (error) {
      console.error(error)
      return []
    } finally {
      setIsFetchingModules(false)
    }
  }
  
  // Fetch progress after initial render
  useEffect(() => {
    if (courses.courses.length > 0) {
      fetchPathProgress();
    }
  }, [courses.courses.length]);
  
  const startLearningPath = async () => {
    if (!courses.courses.length) return;
    setIsFetchingModules(true);

    try {
      let existingProgress = null;
      const progressResponse = await axios.get(
        `https://codeforcambodia.codes/codecademy/learningpath/overall-progress/${pathId}`,
        { headers }
      ).catch(() => ({ data: { progress: null } }));
      
      existingProgress = progressResponse.data.progress;
      
      let selectedCourse = null;
      let navigationPath = '';

      if (existingProgress?.current_course_id) {
        const currentCourseId = existingProgress.current_course_id;
        const courseProgress = progress.find(p => p.course_id === currentCourseId);

        if (courseProgress) {
          navigationPath = `/courses/${currentCourseId}/modules/${courseProgress.current_module_id}/lessons/${courseProgress.current_lesson_id}`;
          // Start navigation immediately
          navigate(navigationPath);
          // Save progress in background
          saveProgressInBackground(pathId, currentCourseId);
          return;
        }

        // Fetch modules if needed
        const fetchedModules = await fetchModules({ courseId: currentCourseId });
        if (fetchedModules.length > 0) {
          navigationPath = `/courses/${currentCourseId}/modules/${fetchedModules[0].id}/lessons/${fetchedModules[0].lessons[0].id}`;
          navigate(navigationPath);
          saveProgressInBackground(pathId, currentCourseId);
          return;
        }
      }

      // Find first incomplete course
      selectedCourse = courses.courses.find(course => {
        const courseProgress = progress.find(p => p.course_id === course.id);
        return !courseProgress || courseProgress.progress_percentage < 100;
      });

      if (selectedCourse) {
        const courseProgress = progress.find(p => p.course_id === selectedCourse.id);
        if (courseProgress) {
          navigationPath = `/courses/${selectedCourse.id}/modules/${courseProgress.current_module_id}/lessons/${courseProgress.current_lesson_id}`;
          navigate(navigationPath);
        } else {
          const fetchedModules = await fetchModules({ courseId: selectedCourse.id });
          if (fetchedModules.length > 0) {
            navigationPath = `/courses/${selectedCourse.id}/modules/${fetchedModules[0].id}/lessons/${fetchedModules[0].lessons[0].id}`;
            navigate(navigationPath);
          }
        }
        // Save progress in background after navigation
        saveProgressInBackground(pathId, selectedCourse.id);
      }
    } catch (err) {
      console.error('Error in startLearningPath:', err);
      setProgressStatus(prev => ({ ...prev, error: err.message }));
    } finally {
      setIsFetchingModules(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [coursesResponse, pathResponse] = await Promise.all([
          axios.get(`https://codeforcambodia.codes/codecademy/path/courses/${pathId}`),
          axios.get(`https://codeforcambodia.codes/codecademy/path/${pathId}`)
        ]);
        
        setCourses(coursesResponse.data);
        setLearningPath(pathResponse.data.learningPaths[0]);
  
        if (token) {
          const progressResponse = await axios.get(`https://codeforcambodia.codes/codecademy/user/progress`, { headers });
          setProgress(progressResponse.data.courses);
        }
      } catch (err) {
        setError('Failed to load learning path');
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [pathId, token]);

  if (loading) return <CodeLoadingAnimation />
  if (error) return <div className="text-center py-10 text-red-500 font-mono">{error}</div>
  if (!courses || !learningPath) return <div className="text-center py-10 font-mono">No learning path found</div>
  if (!token) return (
    <div className="flex justify-center items-center h-screen">
      <a href="/login" className="text-center text-red-500 py-10 font-mono hover:underline">
        Please login to continue
      </a>
    </div>
  )

  return (
    <div className="min-h-screen bg-white font-mono">
      <CodeForCambodiaHeader />
      <MultiStepLoader loading={isFetchingModules} />
      <LearningPathStatus 
        isLoadingProgress={progressStatus.isLoadingProgress}
        isSaving={progressStatus.isSaving}
        error={progressStatus.error}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 pt-6 relative">
        {/* Decorative elements */}
        <div className="absolute left-0 top-0 h-full w-px bg-gray-200 flex flex-col items-center">
          {[...Array(20)].map((_, i) => (
            <div key={i} className="w-2 h-2 bg-blue-500 rounded-full my-8"></div>
          ))}
        </div>
        <div className="absolute right-0 top-0 h-full w-px bg-gray-200 flex flex-col items-center">
          {[...Array(20)].map((_, i) => (
            <div key={i} className="w-2 h-2 bg-blue-500 rounded-full my-8"></div>
          ))}
        </div>
        
        <div className="relative z-10">
          <LearningPathHeader 
            learningPath={learningPath} 
            coursesCount={courses.courses.length} 
            saveLearningPath={startLearningPath} 
            pathProgress={pathProgress} 
            refreshProgress={fetchPathProgress}
            status={courses.courses[0].status}
          />
          
          <div className="text-gray-500 mb-4 pl-4 Nokora border-l-4 border-gray-300">
            {`// ថ្នាក់ក្នុងជំនាញ ${learningPath.learning_path_name}`}
          </div>
          
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 px-4">
            {courses.courses.map((course) => (
              <CourseCardMac 
                key={course.id} 
                course={course} 
                courseProgress={progress.find(p => p.course_id === course.id)} 
              />
            ))}
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  )
}
