import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Search } from 'lucide-react';

const Advertisement = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="container mx-auto px-4 py-12 font-mono">
      <div 
        onClick={() => setShowModal(true)}
        className="block relative overflow-hidden rounded-2xl bg-white border border-blue-200 hover:border-blue-300 transition-all duration-300 shadow-sm hover:shadow-md transform hover:-translate-y-1 cursor-pointer"
      >
        <div className="relative z-10 p-8">
          <div className="flex items-center justify-between">
            <div className="flex-1">
              <h2 className="text-3xl font-bold mb-4 text-blue-800 Nokora">
                ស្រាវជ្រាវគ្រប់ឯកសារកូដដោយងាយៗ
              </h2>
              <p className="text-lg text-gray-600 mb-6 Nokora">
                ស្រាវជ្រាវឯកសារកូដជាមួយ search engine ដោយចូលប្រើ និងស្វែងរកមេរៀន និងឯកសារភ្លាមៗតាមអ្វីអ្នកស្រាវជ្រាវ។
              </p>
              <div className="inline-flex items-center space-x-2 text-blue-600 font-semibold">
                <code className="text-blue-400">{'<'}</code>
                <span className="Nokora">ស្រាវជ្រាវ</span>
                <code className="text-blue-400">{'/>'}</code>
                <Search className="w-5 h-5 animate-pulse" />
              </div>
            </div>
            
            <div className="hidden md:block">
              <div className="w-32 h-32 rounded-full bg-blue-50 flex items-center justify-center border border-blue-100">
                <Search className="w-16 h-16 text-blue-500" />
              </div>
            </div>
          </div>
        </div>

        {/* Decorative elements */}
        <div className="absolute top-0 left-0 w-full h-full opacity-5">
          <div className="absolute top-0 right-0 w-40 h-40 bg-blue-200 rounded-full transform translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 left-0 w-32 h-32 bg-blue-200 rounded-full transform -translate-x-1/2 translate-y-1/2"></div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-md w-full relative">
            <div className="border-b pb-4 mb-4">
              <h3 className="text-xl font-bold text-blue-800 Nokora flex items-center gap-2">
                <span>កំពុងជួសជុល</span>
                <span className="animate-pulse">🔧</span>
              </h3>
            </div>
            
            <p className="text-gray-600 mb-6 Nokora">
              សូមអភ័យទោស មុខងារនេះកំពុងជួសជុល សូមរង់ចាំ។
            </p>

            <div className="flex justify-end">
              <button 
                onClick={() => setShowModal(false)}
                className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 
                transition-all duration-300 transform hover:-translate-y-0.5 
                focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 
                shadow-sm hover:shadow-md Nokora flex items-center gap-2"
              >
                <span>យល់ព្រម</span>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-4 w-4" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M5 13l4 4L19 7" 
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Advertisement;
