export default class ConnectionManager {
    constructor(networkState) {
        this.networkState = networkState;
    }

    getAvailableInterface(device) {
        console.log(`Finding available interface for device ${device.id}`);
        
        // Get all currently used interfaces for this device
        const usedInterfaces = new Set(
            Array.from(this.networkState.connections.values())
                .filter(conn => conn.fromDevice === device.id || conn.toDevice === device.id)
                .map(conn => conn.fromDevice === device.id ? conn.from : conn.to)
        );

        console.log('Used interfaces:', Array.from(usedInterfaces));

        // Find first unused interface
        const availableInterface = Object.keys(device.interfaces)
            .find(interfaceName => !usedInterfaces.has(interfaceName));

        console.log('Selected interface:', availableInterface);
        return availableInterface;
    }

    addConnection(fromDevice, toDevice) {
        console.log('Processing connection request between:', fromDevice.id, 'and', toDevice.id);

        const fromInterface = this.getAvailableInterface(fromDevice);
        const toInterface = this.getAvailableInterface(toDevice);

        if (!fromInterface || !toInterface) {
            throw new Error('No available interfaces');
        }

        // Sort device IDs to ensure consistent connection IDs regardless of connection direction
        const [firstDevice, secondDevice] = [fromDevice.id, toDevice.id].sort();
        const connectionId = `${firstDevice}-${fromInterface}-${secondDevice}-${toInterface}`;
        
        // Check if connection already exists in either direction
        const existingConnection = Array.from(this.networkState.connections.values()).find(
            conn => (conn.fromDevice === fromDevice.id && conn.toDevice === toDevice.id) ||
                   (conn.fromDevice === toDevice.id && conn.toDevice === fromDevice.id)
        );

        if (existingConnection) {
            // If connection exists, create a new unique ID by adding a counter
            const existingConnections = Array.from(this.networkState.connections.values())
                .filter(conn => conn.id.startsWith(`${firstDevice}-`));
            connectionId = `${firstDevice}-${fromInterface}-${secondDevice}-${toInterface}-${existingConnections.length + 1}`;
        }

        const connection = {
            id: connectionId,
            fromDevice: fromDevice.id,
            toDevice: toDevice.id,
            from: fromInterface,
            to: toInterface
        };

        // Set interfaces as connected
        fromDevice.interfaces[fromInterface].status = 'up';
        toDevice.interfaces[toInterface].status = 'up';

        this.networkState.connections.set(connectionId, connection);
        
        console.log('Connection created:', connection);
        return connectionId;
    }

    deleteConnection(connectionId) {
        const connection = this.networkState.connections.get(connectionId);
        if (connection) {
            try {
                const fromDevice = this.networkState.devices.get(connection.fromDevice);
                const toDevice = this.networkState.devices.get(connection.toDevice);
                
                if (fromDevice && fromDevice.interfaces[connection.from]) {
                    fromDevice.interfaces[connection.from].status = 'down';
                }
                if (toDevice && toDevice.interfaces[connection.to]) {
                    toDevice.interfaces[connection.to].status = 'down';
                }
                
                this.networkState.connections.delete(connectionId);
                return true;
            } catch (error) {
                console.error('Error deleting connection:', error);
                return false;
            }
        }
        return false;
    }

    getSerializedConnections() {
        return Object.fromEntries(this.networkState.connections);
    }
}

