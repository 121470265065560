import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Trophy, Medal, Star, Zap, Code, Crown, Flame, Calendar, HelpCircle, ChevronDown, ChevronUp, Diamond } from 'lucide-react'
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader'
import Footer from '../Components/Footer'
import CodeLoadingAnimation from '../Components/CodeLoadingAnimation'

const StreakLeaderboardPage = () => {
  const [leaderboard, setLeaderboard] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isExplanationOpen, setIsExplanationOpen] = useState(false)

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await axios.get('https://codeforcambodia.codes/codecademy/leaderboard/streak')
        setLeaderboard(response.data.leaderboard)
        setLoading(false)
      } catch (err) {
        setError('Error fetching leaderboard data')
        setLoading(false)
      }
    }

    fetchLeaderboard()
  }, [])

  if (loading) return <CodeLoadingAnimation/>
  if (error) return <div className="min-h-screen flex items-center justify-center bg-white text-red-500">{error}</div>

  return (
    <div className="bg-white min-h-screen font-mono">
      <CodeForCambodiaHeader />
      <main className="container mx-auto px-4 py-8 sm:py-12">
        <h1 className="text-3xl sm:text-4xl font-bold mb-4 text-center flex flex-col sm:flex-row items-center justify-center">
          <Trophy className="text-yellow-500 mb-2 sm:mb-0 sm:mr-4" size={48} />
          <span className="text-blue-600">Streak</span>
          <span className="text-green-600">Masters</span>
          <span className="text-orange-500">Leaderboard</span>
        </h1>
        <div className="text-xl sm:text-2xl font-semibold mb-6 sm:mb-8 text-center flex items-center justify-center text-gray-700">
          <Flame className="text-red-500 mr-2 hidden sm:inline" size={24} />
          <span className='Nokora'>អ្នកក្លាហានដែលមានស្ទ្រីកយូរជាងគេ</span>
          <Flame className="text-red-500 ml-2 hidden sm:inline" size={24} />
        </div>
        <p className="text-lg sm:text-xl text-center mb-8 sm:mb-12 text-gray-600 Nokora">
          គោរពអ្នកដែលមានវិន័យរៀនកូដជាប់លាប់លើ CodeForCambodia!
        </p>

        <div className="bg-blue-50 border-2 border-blue-200 rounded-lg mb-8 overflow-hidden transition-all duration-300 ease-in-out">
          <button
            onClick={() => setIsExplanationOpen(!isExplanationOpen)}
            className="w-full p-4 flex items-center justify-between text-blue-800 hover:bg-blue-100 transition-colors duration-200"
          >
            <div className="flex items-center">
              <HelpCircle size={24} className="mr-2" />
              <h2 className="text-2xl font-bold">What is a Streak?</h2>
            </div>
            {isExplanationOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
          </button>
          {isExplanationOpen && (
            <div className="p-6 border-t-2 border-blue-200 Nokora">
                <p className="mb-4 text-blue-700">
                Streak តំណាងឱ្យចំនួនថ្ងៃជាប់គ្នាដែលអ្នកបានរៀនសូត្រយ៉ាងសកម្មនៅលើ CodeForCambodia។ វាជាការវាស់វែងនៃភាពប្តេជ្ញាចិត្តរបស់អ្នកចំពោះការសរសេរកូដ។
                </p>
                <h3 className="text-xl font-semibold mb-2 text-blue-800">របៀបបង្កើតនិងរក្សា streak របស់អ្នក៖</h3>
                <ul className="list-disc list-inside text-blue-700 space-y-2">
                <li>Streak របស់អ្នកកើនឡើង 1 រាល់ថ្ងៃដែលអ្នកសកម្ម</li>
                <li>ប្រសិនបើអ្នកខកខានមួយថ្ងៃ streak របស់អ្នកនឹងកំណត់ឡើងវិញទៅ 0</li>
                <li>Streak ដែលវែងបំផុតដែលអ្នកធ្លាប់សម្រេចបានត្រូវបានកត់ត្រានិងបង្ហាញនៅលើក្តារពិន្ទុនេះ</li>
                </ul>
                <p className="mt-4 text-blue-800 font-semibold">
                រក្សា streak របស់អ្នកឱ្យនៅរស់ដើម្បីឡើងឋានៈនិងក្លាយជាអ្នកជំនាញ streak!
                </p>
            </div>
            )}
        </div>

        <div className="border-2 border-gray-200 rounded-lg overflow-hidden shadow-lg">
          <div className="p-4 sm:p-6 space-y-4">
            <div className="flex items-center space-x-2 text-gray-500 mb-2">
              <Code size={20} />
              <span className="text-blue-600">streakLeaderboard</span> = [
            </div>
            {leaderboard.map((entry, index) => (
              <div 
                key={entry.id} 
                className={`flex flex-col sm:flex-row items-start sm:items-center justify-between p-4 ${
                  index < 3 ? 'border-2 rounded-lg transform transition-all duration-300 hover:scale-105' : 'border-l-4'
                } ${
                  index === 0 ? 'border-yellow-500 bg-yellow-50' :
                  index === 1 ? 'border-gray-400 bg-gray-50' :
                  index === 2 ? 'border-orange-500 bg-orange-50' :
                  'border-blue-500'
                }`}
              >
                <div className="flex items-center space-x-4 mb-2 sm:mb-0">
                  <div className="text-2xl font-bold w-12 text-center flex items-center justify-center">
                    {index === 0 ? <Crown className="text-yellow-500" size={40} /> :
                     index === 1 ? <Medal className="text-gray-400" size={36} /> :
                     index === 2 ? <Medal className="text-orange-500" size={32} /> :
                     <span className="text-blue-500">{`#${index + 1}`}</span>}
                  </div>
                  <div>
                    <div className="flex items-center gap-2">
                      <h2 className={`text-lg sm:text-xl font-semibold ${index < 3 ? 'sm:text-2xl' : ''}`}>{`${entry.first_name} ${entry.last_name}`}</h2>
                      {entry.subscription_status.is_premium === true && (
                        <div className="flex items-center gap-2">
                          <Crown className="text-amber-400 " size={24} />
                          <div className="flex flex-col">
                            <span className="text-sm font-mono font-bold bg-yellow-500 text-white px-4 py-1.5 rounded-t-lg transform transition-all duration-300 border-t-2 border-x-2 border-amber-400 shadow-lg hover:shadow-amber-400/50">
                              <span className="">PRO</span> <span className="">👑</span>
                            </span>
                            <span className="text-[10px] bg-amber-100 text-amber-800 px-2 py-0.5 rounded-b-lg border-b-2 border-x-2 border-amber-400">
                              Joined by 10,000+ learners
                            </span>
                          </div>
                          <Diamond className="text-amber-400 animate-pulse" size={16} />
                        </div>
                      )}
                    </div>
                    <p className={`text-gray-600 ${index < 3 ? 'text-base sm:text-lg' : 'text-sm'}`}>
                      {index === 0 ? 'Streak Legend' : index === 1 ? 'Streak Master' : index === 2 ? 'Streak Prodigy' : 'Streak Warrior'}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-4 mt-2 sm:mt-0">
                  <div className="text-right">
                    <p className={`font-semibold ${index < 3 ? 'text-xl sm:text-2xl' : 'text-lg'}`}>{entry.longest_streak} days</p>
                    <p className={`text-gray-600 ${index < 3 ? 'text-sm sm:text-base' : 'text-xs sm:text-sm'}`}>Longest Streak</p>
                  </div>
                  {index < 3 && (
                    <div className="flex space-x-1">
                      {[...Array(3 - index)].map((_, i) => (
                        <Star key={i} className="text-yellow-500" size={24} fill="currentColor" />
                      ))}
                    </div>
                  )}
                  <Zap className={`${
                    index === 0 ? 'text-yellow-500' :
                    index === 1 ? 'text-gray-400' :
                    index === 2 ? 'text-orange-500' :
                    'text-blue-500'
                  }`} size={24} />
                </div>
              </div>
            ))}
            <div className="flex items-center space-x-2 text-gray-500 mt-2">
              ];
            </div>
          </div>
        </div>

        <div className="mt-8 sm:mt-12 text-center text-gray-700">
          <p className="flex items-center justify-center text-lg sm:text-xl font-semibold">
            <Calendar className="mr-2" size={24} />
            Every Day Counts in Your Coding Journey!
          </p>
          <p className="mt-2 text-base sm:text-lg">
            Can you maintain the longest streak and become the ultimate Streak Legend?
          </p>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default StreakLeaderboardPage