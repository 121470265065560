import { AlertCircle } from 'lucide-react';

// CancelPage.js
export function CancelPage() {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
        <div className="max-w-md w-full text-center">
          <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            Subscription Cancelled
          </h1>
          <p className="text-gray-600 mb-6">
            The subscription process was cancelled. No charges were made.
          </p>
          <button
            onClick={() => window.location.href = '/'}
            className="bg-gray-600 text-white px-6 py-2 rounded-md hover:bg-gray-700"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }