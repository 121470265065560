import { clearSubscriptionCache } from '../../Components/Stripe/fetchSubscriptionStatus';
import { Check } from 'lucide-react';
import { useEffect } from 'react';

export function SuccessPage() {
    useEffect(() => {
      clearSubscriptionCache(); // Clear the cache when payment is successful
    }, []);

    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
            <div className="max-w-md w-full text-center">
                <Check className="w-12 h-12 text-green-500 mx-auto mb-4" />
                <h1 className="text-3xl font-bold text-gray-900 mb-2">
                    Subscription Successful!
                </h1>
                <p className="text-gray-600 mb-6">
                    Thank you for subscribing. You should receive a confirmation email shortly.
                </p>
                <button
                    onClick={() => {
                        clearSubscriptionCache(); // Clear again just to be safe
                        window.location.href = 'https://codeforcambodia.com';
                    }}
                    className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700"
                >
                    Return Home
                </button>
            </div>
        </div>
    );
}