import React, { useState, useEffect, useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { AlertCircle, Check, Lock, Loader, Terminal, Code, Sparkles, Gift, Clock, DollarSign, Send, CreditCard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { ManageSubscriptionPage } from './Stripe/ManageSubscriptionPage';
import Header from '../Components/Headers/CodeForCambodiaHeader';
import Footer from '../Components/Footer';
import { fetchSubscriptionStatus, getLastKnownSubscription } from '../Components/Stripe/fetchSubscriptionStatus';

const stripePromise = loadStripe('pk_live_51QBrqgI1Z6b4hdvbod5fdLb9L0X8sAWOBbCEpr7hObepzg6DQh869kgsqr4VALyStrcTEV8MjfMJMpGV6WaqQrJn00nwTOCCyb');

const PaymentOption = ({ icon: Icon, text, onClick, primary }) => (
  <button
    onClick={onClick}
    className={`w-full mt-3 py-2 px-4 rounded-lg flex items-center justify-center space-x-2 text-sm font-medium transition duration-300 ease-in-out transform hover:-translate-y-1 ${primary ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-white border-2 border-blue-600 text-blue-600 hover:bg-blue-50'}`}
  >
    <Icon className="w-4 h-4" />
    <span className="truncate Nokora">{text}</span>
  </button>
);

const plans = [
  {
    id: 'monthly',
    name: 'បង់រាល់ខែ',
    price: '$6.99',
    period: 'រាល់ខែ',
    totalPrice: '$6.99/ខែ',
    originalPrice: '$9.99',
    features: [
      '🌟 គ្រប់មុខជំនាញក្នុងកញ្ចប់មួយខែ',
      '📚 ចូលដំណើរការវគ្គសិក្សា Premium',
      '💬 ការគាំទ្រពីសហគមន៍ពិសេស',
      '🎯 ផែនការផ្ដល់ការណែនាំអាជីព',
      '💡 អនុវត្តគម្រោងកម្រិតខ្ពស់',
      '🆕 វគ្គសិក្សាថ្មី៖ Networking, APIs, AI/ML'
    ],
    description: 'ជម្រើសងាយស្រួលសម្រាប់ការចាប់ផ្តើម។ បង់ត្រឹមតែ $6.99 ក្នុងមួយខែដើម្បីទទួលបានការចូលដំណើរការតាមតំរូវការ។',
    popular: false,
    tag: 'សាកល្បង',
    color: 'blue',
    ctaNew: 'ចង់ $6.99/month',
    ctaExisting: 'បង់ $6.99/month'
  },
  {
    id: 'annual',
    name: 'បង់រាល់ឆ្នាំ',
    price: '$35.99',
    period: 'រាល់ឆ្នាំ',
    totalPrice: '$35.99/ឆ្នាំ',
    originalPrice: '$83.88',
    billingPeriod: 'បង់មួយឆ្នាំម្តង',
    savings: 'Save $48 (57% off)',
    bonusTag: '🎁 7 Months FREE!',
    features: [
      '🌟 គ្រប់យ៉ាងក្នុងកញ្ចប់មួយខែ',
      '📚 ចូលដំណើរការវគ្គសិក្សា Premium',
      '💰 អ្នកសន្សំបាន $48 (57% កាត់បន្ថយ)',
      '🎯 រួមគ្រប់កម្មវិធីដែលនឹងមានបន្ថែមឆ្នាំក្រោយ',
      '🔥 ជម្រើសដែលអាចសន្សំបានច្រើនជាងការបង់រាល់ខែ ($6.99) ឬ បង់រាល់បីខែ ($14.99)',
      '⏳ ការផ្ដល់ជូនពិសេសសម្រាប់ការបើកដំបូង',
      '🆕 វគ្គសិក្សាថ្មី៖ Networking, APIs, AI/ML'
    ],
    description: 'ជម្រើសដ៏សន្សំសំចៃបំផុតសម្រាប់អ្នកចង់ចំណេញជាងការបង់រាល់ខែ។',
    popular: true,
    tag: 'និយមទិញ',
    calloutFeature: '#1 Choice in Cambodia',
    color: 'green',
    ctaNew: 'ចា់ $35.99/year',
    ctaExisting: 'បង់ $35.99/year'
  },
  {
    id: 'quarterly',
    name: 'បង់រាល់បីខែ',
    price: '$14.99',
    period: 'រាល់បីខែ',
    totalPrice: '$14.99/បីខែ',
    billingPeriod: 'បង់ 3 ខែម្តង',
    originalPrice: '$20.97',
    savings: 'Save $6 (29% off)',
    bonusTag: '🎁 1 Month FREE!',
    features: [
      '🌟 គ្រប់យ៉ាងក្នុងកញ្ចប់មួយខែ',
      '📚 ចូលដំណើរការវគ្គសិក្សា Premium',
      '💬 ការគាំទ្រពីសហគមន៍ពិសេស',
      '🎯 ផែនការផ្ដល់ការណែនាំអាជីព',
      '💡 អនុវត្តគម្រោងកម្រិតខ្ពស់',
      '🆕 វគ្គសិក្សាថ្មី៖ Networking, APIs, AI/ML',
      '💰 អ្នកសន្សំបាន $6 (29% កាត់បន្ថយ)',
      '🔥 ជម្រើសសន្សំបានច្រើនជាងការបង់រាល់ខែ ($6.99)',
      '📅 ល្អសម្រាប់ការសន្សំប្រចាំត្រីមាស'
    ],
    description: 'ជម្រើសដល្អសម្រាប់អ្នកចង់សន្សំជាងការបង់រាល់ខែ។',
    popular: false,
    tag: 'តម្លៃសន្សំ',
    color: 'purple',
    ctaNew: 'ចា់ $14.99/3 months',
    ctaExisting: 'បង់ $14.99/3 months'
  },
  
];


const ValueTag = ({ children }) => (
  <div className="Nokora bg-blue-100 text-blue-600 px-2 py-1 rounded-full text-xs md:text-sm font-medium inline-flex items-center">
    <Sparkles className="w-3 h-3 md:w-4 md:h-4 mr-1" />
    <span className="truncate">{children}</span>
  </div>
);

const PricingCard = ({ plan, onSubscribe, loading }) => {
  return (
    <div className={`relative bg-white rounded-xl overflow-hidden transition-all duration-300 hover:scale-105 ${plan.popular ? 'border-4 border-green-400' : 'border border-gray-200'}`}>
      {plan.popular && (
        <div className="absolute top-0 left-0 right-0 bg-gradient-to-r from-green-600 to-green-500 text-white py-1.5 text-xs md:text-sm font-medium text-center">
          <Sparkles className="inline-block w-3 h-3 md:w-4 md:h-4 mr-1" />Most Popular Choice
        </div>
      )}
      <div className={`p-4 md:p-6 ${plan.popular ? 'pt-8 md:pt-10' : 'pt-4 md:pt-6'}`}>
        <div className="flex items-center justify-between mb-4">
          <ValueTag>{plan.tag}</ValueTag>
          {plan.calloutFeature && <div className="text-xs text-blue-600 font-medium truncate ml-2 ">{plan.calloutFeature}</div>}
        </div>
        <h3 className="text-xl md:text-2xl font-bold text-gray-900 mb-2 Nokora">{plan.name}</h3>
        <div className="mb-4 md:mb-6 bg-gradient-to-br from-gray-50 to-white p-4 md:p-6 rounded-xl border border-gray-100">
          <div className="flex items-baseline flex-wrap">
            <span className="text-3xl md:text-5xl font-bold text-blue-600">{plan.price}</span>
            <span className="text-sm md:text-base text-gray-600 ml-2 Nokora">{plan.period}</span>
          </div>
          <div className="Nokora text-xs md:text-sm text-gray-600 mt-1">
            {plan.totalPrice} | {plan.billingPeriod}
            {plan.originalPrice && (
              <span className="ml-2 text-red-500">
                <span className="line-through text-base md:text-lg font-medium decoration-2">{plan.originalPrice}</span>
              </span>
            )}
          </div>
          {(plan.savings || plan.bonusTag) && (
            <div className="mt-3 flex flex-wrap gap-2">
              {plan.savings && <div className="bg-green-100 text-green-600 px-2 py-1 rounded-full text-xs md:text-sm font-medium inline-flex items-center"><DollarSign className="w-3 h-3 md:w-4 md:h-4 mr-1" />{plan.savings}</div>}
            </div>
          )}
        </div>
        <div className="mb-4 md:mb-6">
          <ul className="space-y-2 md:space-y-3">
            {plan.features.map((feature, index) => (
              <li key={index} className="flex items-center text-xs md:text-sm Nokora"><span className="truncate">{feature}</span></li>
            ))}
          </ul>
        </div>
        <div className="space-y-2">
          <PaymentOption icon={CreditCard} text={loading ? 'Processing...' : plan.ctaExisting} onClick={() => onSubscribe(plan.id)} primary={true} />
          <div className="relative py-2">
            <div className="absolute inset-0 flex items-center"><div className="w-full border-t border-gray-200"></div></div>
            <div className="relative flex justify-center text-xs md:text-sm"><span className="px-2 bg-white text-gray-500">or</span></div>
          </div>
          <PaymentOption icon={Send} text="បង់តាម Telegram" onClick={() => window.open('https://t.me/codeforcambodia_support', '_blank')} primary={false} />
        </div>
      </div>
    </div>
  );
};

export default function SubscriptionPage() {
  const [loading, setLoading] = useState(false);
  const [checkingSubscription, setCheckingSubscription] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [hasSubscription, setHasSubscription] = useState(false);

  useEffect(() => {
    checkSubscription();
  }, []);

  const checkSubscription = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const data = await fetchSubscriptionStatus(token);
      setHasSubscription(data.hasSubscription);
    } catch (err) {
      console.error('Error checking subscription:', err);
      setError('Failed to check subscription status');
      setHasSubscription(false);
    } finally {
      setCheckingSubscription(false);
    }
  };

  const handleSubscribe = async (planId) => {
    try {
      setLoading(true);
      setError('');
      
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await fetch('https://codeforcambodia.codes/codecademy/stripe/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          plan: planId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const { sessionId } = await response.json();
      const stripe = await stripePromise;
      
      await stripe.redirectToCheckout({
        sessionId,
      });
    } catch (err) {
      setError('Something went wrong. Please try again.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  if (checkingSubscription && !hasSubscription) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col items-center space-y-4">
          <Loader className="w-8 h-8 animate-spin text-blue-600" />
          <p className="text-gray-600">Loading subscription details...</p>
        </div>
      </div>
    );
  }

  if (hasSubscription) {
    return <ManageSubscriptionPage />;
  }

  return (
    <div className="font-mono min-h-screen">
      <Header/>
      <div className="bg-gray-50 py-6 md:py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-6 md:mb-8">
            <div className="inline-block bg-green-100 rounded-full px-3 md:px-4 py-1.5 md:py-2 text-green-700 font-semibold text-sm md:text-base mb-4">
              <Sparkles className="inline-block w-3 h-3 md:w-4 md:h-4 mr-2" />
              Special Offer: Save up to 57% Today! 🎉
            </div>
            <h1 className="text-2xl md:text-4xl font-extrabold text-gray-900 mb-4">Invest in Your Future with<span className="text-blue-600"> CodeForCambodia</span></h1>
            <p className="text-base md:text-lg text-gray-600 max-w-2xl mx-auto mb-4">Join over 8,000+ developers who've transformed their careers through our program.</p>
            <div className="flex flex-wrap justify-center gap-4 md:space-x-6 mb-6 md:mb-8 text-xs md:text-sm">
              <div className="flex items-center text-gray-600"><Check className="w-3 h-3 md:w-4 md:h-4 text-green-500 mr-1" />No Credit Card Required</div>
              <div className="flex items-center text-gray-600"><Check className="w-3 h-3 md:w-4 md:h-4 text-green-500 mr-1" />Cancel Anytime</div>
              <div className="flex items-center text-gray-600"><Check className="w-3 h-3 md:w-4 md:h-4 text-green-500 mr-1" />No Refund</div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-7xl mx-auto">
            {plans.map((plan) => (
              <PricingCard key={plan.id} plan={plan} onSubscribe={handleSubscribe} loading={loading} />
            ))}
          </div>

          <div className="mt-6 md:mt-8 text-center">
            <div className="inline-flex items-center space-x-2 bg-gray-100 px-3 md:px-4 py-1.5 md:py-2 rounded-full text-xs md:text-sm">
              <Lock className="w-3 h-3 md:w-4 md:h-4 text-gray-600" />
              <span className="text-gray-600">Secure payments powered by Stripe</span>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}