import React, { useState } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { Link, useNavigate } from 'react-router-dom';
import LoginLink from './LoginLink';
import ReCAPTCHA from 'react-google-recaptcha';

const RightPanel = ({ onRegisterSuccess }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    first_name: '',
    last_name: '',
    age: '',
    gender: '',
    referral_email: '',
    honeypot: ''
  });
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNext = (e) => {
    e.preventDefault();
    setFieldErrors({});
    setError('');

    // Validate first step
    if (!formData.email || !formData.password || !formData.confirmPassword) {
      setError('All fields are required');
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setFieldErrors({ confirmPassword: 'Passwords do not match' });
      return;
    }

    setStep(2);
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (!recaptchaToken) {
      setError('Please complete the reCAPTCHA verification');
      setIsLoading(false);
      return;
    }

    try {
      const registeration_tries = localStorage.getItem('registeration_tries') || 0;
      const registrationData = {
        first_name: formData.first_name.trim(),
        last_name: formData.last_name.trim(),
        age: parseInt(formData.age),
        gender: formData.gender.trim(),
        email: formData.email.trim().toLowerCase(),
        password: formData.password,
        registeration_tries: parseInt(registeration_tries) + 1,
        honeypot: formData.honeypot,
        recaptchaToken
      };
    
      if (formData.referral_email.trim()) {
        registrationData.referral_email = formData.referral_email.trim().toLowerCase();
      }
    
      const response = await axios.post(
        'https://codeforcambodia.codes/codecademy/user/register', 
        registrationData
      );

      if (response.data.token) {
        localStorage.setItem('registeration_tries', parseInt(registeration_tries) + 1);
        onRegisterSuccess(response.data.token);
        navigate('/dashboard');
      }
    } catch (error) {
      if (window.grecaptcha) {
        window.grecaptcha.reset();
        setRecaptchaToken(null);
      }

      if (error.response?.data?.errors) {
        const backendErrors = {};
        error.response.data.errors.forEach(err => {
          backendErrors[err.path] = err.msg;
        });
        setFieldErrors(backendErrors);
        setError('Please fix the highlighted errors');
      } else {
        setError(error.response?.data?.message || 'An error occurred during registration');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gray-50 p-8 rounded-lg border border-gray-200 shadow-sm relative">
      <input 
        type="text"
        name="honeypot"
        value={formData.honeypot}
        onChange={handleChange}
        style={{ display: 'none' }}
        tabIndex="-1"
        autoComplete="off"
      />

      <div className="flex items-center justify-center">
        <AnimatePresence>
          {step === 2 && (
            <motion.button
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
              onClick={() => setStep(1)}
              className="flex mr-2 items-center text-sm font-medium mb-3 text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
              </svg>
            </motion.button>
          )}
        </AnimatePresence>
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.1 }}
          className="text-base lg:text-2xl font-bold text-blue-500 mb-3 text-center"
        >
          Join CodeForCambodia for free
        </motion.h2>
      </div>

      <form onSubmit={step === 1 ? handleNext : handleSubmit} className="space-y-4">
        <AnimatePresence mode="wait">
          {step === 1 ? (
            <motion.div
              key="step1"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.3 }}
            >
              <StepOne
                formData={formData}
                handleChange={handleChange}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                showConfirmPassword={showConfirmPassword}
                setShowConfirmPassword={setShowConfirmPassword}
                fieldErrors={fieldErrors}
              />
            </motion.div>
          ) : (
            <motion.div
              key="step2"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.3 }}
            >
              <StepTwo
                formData={formData}
                handleChange={handleChange}
                fieldErrors={fieldErrors}
              />
            </motion.div>
          )}
        </AnimatePresence>
        
        {error && (
          <div className="text-red-600 text-sm text-center space-y-2">
            <div>{error}</div>
            <div className="text-gray-600 text-base Nokora">
              ត្រូវការជំនួយ? អាចទាក់ទងតាម {' '}
              <a 
                href="https://t.me/codeforcambodia" 
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-600 underline"
              >
                Telegram
              </a>
            </div>
          </div>
        )}
        
        <div className="flex justify-center w-full mt-4 mb-2">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            className="flex flex-col items-center"
          >
            <span className="text-orange-500 text-sm Nokora">សុំចុចលើគ្រីស</span>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={handleRecaptchaChange}
              theme="light"
              size="normal"
              className="shadow-sm rounded-lg overflow-hidden"
            />
          </motion.div>
        </div>

        <div>
          <button
            type="submit"
            disabled={isLoading || !recaptchaToken}
            className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-white disabled:opacity-50"
          >
            {isLoading ? 'Processing...' : step === 1 ? (
              <>
                Next
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </>
            ) : (
              'createAccount()'
            )}
          </button>
        </div>
      </form>
      <LoginLink/>
      <div className="mt-4 text-center text-sm text-gray-600">

        By signing up, you agree to our <Link to="/privacy-policy" className="text-blue-500 underline hover:text-blue-600">Privacy Policy</Link>
      </div>
    </div>
  );
};

export default RightPanel;