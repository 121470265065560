import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ArrowLeft, BookOpen, ExternalLink } from 'lucide-react';
import Footer from '../Components/Footer';
import Header from '../Components/Headers/CodeForCambodiaHeader';
import options from '../Components/Lessons/OptionsQuill';
import parse from 'html-react-parser';

const SearchResultDetail = () => {
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [document, setDocument] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        setLoading(true);
        const response = await fetch(`https://codeforcambodia.codes/codecademy/search/document/${documentId}`);
        if (!response.ok) {
          throw new Error('Document not found');
        }
        const data = await response.json();
        setDocument(data);
        
        // Get search query from localStorage if it exists
        const storedQuery = localStorage.getItem('lastSearchQuery');
        if (storedQuery) {
          setSearchQuery(storedQuery);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (documentId) {
      fetchDocument();
    }
  }, [documentId]);

  const handleBackToSearch = () => {
    // Use the stored search state from sessionStorage
    const lastSearchState = sessionStorage.getItem('lastSearchState');
    if (lastSearchState) {
      const { query, page } = JSON.parse(lastSearchState);
      navigate(`/search?q=${encodeURIComponent(query)}&page=${page}`);
    } else {
      navigate('/search');
    }
  };

  const handleGoToCourse = () => {
    if (document) {
      navigate(document.coursePath);
    }
  };

  if (loading) {
    return (
      <>
        <Header />
        <div className="max-w-4xl mx-auto p-4 min-h-[80vh] flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
        <Footer />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Header />
        <div className="max-w-4xl mx-auto p-4 min-h-[80vh]">
          <div className="bg-red-50 rounded-lg shadow-sm p-6">
            <h2 className="text-xl font-semibold text-red-600 mb-2">Error</h2>
            <p className="text-red-700">{error}</p>
            <button
              onClick={() => navigate('/search')}
              className="mt-4 flex items-center text-red-600 hover:text-red-800"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back to Search
            </button>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  if (!document) {
    return null;
  }

  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-4 min-h-[80vh]">
        {/* Navigation Bar */}
        <div className="flex items-center justify-between mb-6">
          <button
            onClick={handleBackToSearch}
            className="flex items-center text-gray-600 hover:text-gray-800"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Search Results
          </button>
          {searchQuery && (
            <span className="text-sm text-gray-500">
              Search: "{searchQuery}"
            </span>
          )}
        </div>

        {/* Breadcrumb */}
        <Link to={document.coursePath} className="text-sm text-gray-600 flex items-center gap-2 hover:text-blue-600">
          <span>{document.courseName}</span>
          <span>→</span>
          <span>{document.moduleName}</span>
          <span>→</span>
          <span>{document.lessonName}</span>

        </Link>

        {/* Main Content */}
        <div className="bg-white rounded-lg mb-6  px-2">
          <div className="border-b border-gray-400 p-6">
            <Link to={document.coursePath}>
              <h1 className="text-2xl font-semibold Nokora hover:text-blue-600 transition-colors duration-200 group">
                <span className="inline-flex items-center gap-2">
                  {document.lessonName}
                  <span className="text-gray-400 group-hover:text-blue-500 transition-colors duration-200">
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </span>
                </span>
              </h1>
            </Link>
          </div>
          <div className="p-6">
            <div 
              className="prose prose-lg max-w-none Nokora"
              
            >
              {parse(document.content, options)}
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between items-center">
          <button
            onClick={handleGoToCourse}
            className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors Nokora"
          >
            <BookOpen className="w-4 h-4" />
            ទៅមេរៀន
          </button>
          
          <Link to={document.coursePath} className="flex items-center gap-2 text-sm text-gray-500 hover:text-blue-600 transition-colors">
            <ExternalLink className="w-4 h-4" />
            Found in: {document.courseName} - {document.moduleName}
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SearchResultDetail;