export default class ConfigurationCommands {
    constructor(networkState) {
        this.networkState = networkState;
    }

    canHandle(cmd) {
        return ['configure', 'hostname', 'router', 'line', 'password', 'enable', 'service', 'dhcp'].includes(cmd);
    }

    execute(cmd, args, deviceId) {
        const device = this.networkState.devices.get(deviceId);

        if (cmd === 'dhcp') {
            return this.handleDhcp(args, deviceId);
        }
        
        // Don't process configuration commands for PCs
        if (device.type === 'pc') {
            return { 
                error: 'Command not supported on PC devices',
                input: `${cmd} ${args.join(' ')}`,
                prompt: device.name
            };
        }

        switch (cmd) {
            case 'configure':
                return this.handleConfigure(args, deviceId);
            case 'hostname':
                return this.handleHostname(args, deviceId);
            case 'router':
                return this.handleRouter(args, deviceId);
            case 'line':
                return this.handleLine(args, deviceId);
            case 'password':
                return this.handlePassword(args, deviceId);
            case 'enable':
                return this.handleEnable(args, deviceId);
            case 'service':
                return this.handleService(args, deviceId);
            default:
                return { error: 'Unknown configuration command' };
        }
    }

    handleConfigure(args, deviceId) {
        if (args[0] === 'terminal') {
            this.networkState.configMode.set(deviceId, { mode: 'config' });
            return {
                success: true,
                output: 'Enter configuration commands, one per line. End with CNTL/Z.',
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        }
        return { error: 'Invalid configure command' };
    }
    

    handleHostname(args, deviceId) {
        const device = this.networkState.devices.get(deviceId);
        const mode = this.networkState.configMode.get(deviceId);

        if (mode.mode === 'config' && args.length > 0) {
            const newHostname = args[0];
            if (/^[a-zA-Z0-9\-]+$/.test(newHostname)) {
                device.config.hostname = newHostname;
                return {
                    success: true,
                    output: '',
                    prompt: this.networkState.getCommandPrompt(deviceId)
                };
            } else {
                return { error: 'Invalid hostname format' };
            }
        }
        return { error: 'Invalid hostname command or context' };
    }

    handleDhcp(args, deviceId) {
        const device = this.networkState.devices.get(deviceId);
        const mode = this.networkState.configMode.get(deviceId);

        if (device.type !== 'router') {
            return {
                error: 'DHCP server can only be configured on routers',
                input: `dhcp ${args.join(' ')}`
            };
        }

        if (mode.mode !== 'config') {
            return {
                error: 'DHCP configuration requires config mode',
                input: `dhcp ${args.join(' ')}`
            };
        }

        if (args[0] === 'pool') {
            const poolName = args[1];
            this.networkState.dhcpPools.set(deviceId, {
                name: poolName,
                range: this.generateIpRange('192.168.1.100', '192.168.1.200'),
                subnet: '255.255.255.0',
                gateway: device.interfaces['FastEthernet0/0'].ip
            });

            return {
                success: true,
                output: `DHCP pool ${poolName} configured`,
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        }

        return { error: 'Invalid DHCP command' };
    }

    generateIpRange(start, end) {
        // Helper to generate IP range array
        const startParts = start.split('.').map(Number);
        const endParts = end.split('.').map(Number);
        const ips = [];
        
        let current = startParts;
        while (current.join('.') <= end) {
            ips.push(current.join('.'));
            current[3]++;
            for (let i = 3; i > 0; i--) {
                if (current[i] > 255) {
                    current[i] = 0;
                    current[i-1]++;
                }
            }
        }
        
        return ips;
    }

    handleRouter(args, deviceId) {
        const mode = this.networkState.configMode.get(deviceId);
        if (mode.mode !== 'config') {
            return { error: 'Router command can only be used in global configuration mode' };
        }
        if (args[0] === 'rip') {
            this.networkState.configMode.set(deviceId, { mode: 'config-router', routingProtocol: 'rip' });
            return {
                success: true,
                output: 'Configuring RIP routing protocol',
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        } else if (args[0] === 'ospf' && args[1]) {
            this.networkState.configMode.set(deviceId, { mode: 'config-router', routingProtocol: 'ospf', processId: args[1] });
            return {
                success: true,
                output: `Configuring OSPF routing protocol with process ID ${args[1]}`,
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        }
        return { error: 'Unsupported routing protocol or missing parameters' };
    }

    handleLine(args, deviceId) {
        const mode = this.networkState.configMode.get(deviceId);
        if (mode.mode !== 'config') {
            return { error: 'Line command can only be used in global configuration mode' };
        }
        if (args[0] === 'console' && args[1] === '0') {
            this.networkState.configMode.set(deviceId, { mode: 'config-line', lineType: 'console', lineNumber: 0 });
            return {
                success: true,
                output: '',
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        }
        return { error: 'Invalid line command' };
    }

    handlePassword(args, deviceId) {
        const mode = this.networkState.configMode.get(deviceId);
        const device = this.networkState.devices.get(deviceId);

        if (mode.mode === 'config-line' && args.length > 0) {
            device.config.linePassword = args[0];
            return {
                success: true,
                output: '',
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        }
        return { error: 'Password command is invalid in this context' };
    }

    handleEnable(args, deviceId) {
        const device = this.networkState.devices.get(deviceId);
        
        // If device is a PC, return immediately
        if (device.type === 'pc') {
            return {
                error: 'Command not supported on PC devices',
                input: 'enable',
                prompt: device.name
            };
        }

        // If no args, handle enable mode
        if (args.length === 0) {
            this.networkState.configMode.set(deviceId, { mode: 'privileged' });
            return {
                success: true,
                output: '',
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        }

        // Handle enable password configuration
        const mode = this.networkState.configMode.get(deviceId);
        if (mode.mode === 'config' && args[0] === 'password' && args[1]) {
            device.config.enablePassword = args[1];
            return {
                success: true,
                output: '',
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        }
        return { error: 'Invalid enable command' };
    }

    handleService(args, deviceId) {
        const mode = this.networkState.configMode.get(deviceId);
        const device = this.networkState.devices.get(deviceId);

        if (mode.mode === 'config' && args[0] === 'password-encryption') {
            device.config.servicePasswordEncryption = true;
            return {
                success: true,
                output: '',
                prompt: this.networkState.getCommandPrompt(deviceId)
            };
        }
        return { error: 'Invalid service command' };
    }
}

