import React, { useState, useEffect } from 'react'
import axios from 'axios'
import LearningPathCard from './LearningPathCard'
import { useNavigate } from 'react-router-dom'
import { Code, Terminal } from 'lucide-react'
import { motion } from 'framer-motion'

export default function LearningPathsGrid() {
  const [learningPaths, setLearningPaths] = useState([])
  const [selectedPathId, setSelectedPathId] = useState(null)
  const [courses, setCourses] = useState([])

  useEffect(() => {
    fetchLearningPaths()
  }, [])

  const fetchLearningPaths = async () => {
    try {
      const response = await axios.get('https://codeforcambodia.codes/codecademy/path')
      setLearningPaths(response.data.learningPaths)
    } catch (error) {
      console.error('Error fetching learning paths:', error)
    }
  }

  const navigate = useNavigate()

  const handleCardClick = (pathId) => {
    navigate(`/learning-path/${pathId}`)
  }

  const colors = ['green-500', 'purple-500', 'blue-500', 'orange-500']

  return (
    <div className="container mx-auto px-4 py-8 font-mono bg-gray-50">
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center mb-8"
      >
        <h1 className="text-3xl font-bold Nokora text-gray-900 inline-flex items-center">
          <Code className="mr-2 text-blue-500" />
          ផែនទីជំនាញ
          <Code className="ml-2 text-green-500" />
        </h1>
        <div className="mt-2 text-gray-600">
            
          <p className="Nokora">// រើសជំនាញរៀនខាងលើ</p>
        </div>
      </motion.div>
      
      <div className="relative">
        {/* Decorative top and bottom lines */}
        {['top-[-8px]', 'bottom-[-8px]'].map((position) => (
          <motion.div 
            key={position}
            className={`absolute left-0 right-0 ${position} h-px bg-gray-200`}
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.7, ease: "easeOut" }}
          />
        ))}
        
        <div className="px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-6 mt-8 mb-8">
          {learningPaths.map((path, index) => (
            <motion.div
              key={path.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <LearningPathCard 
                learningPath={path} 
                onClick={() => {
                  const token = localStorage.getItem('token');
                  if (token) {
                    handleCardClick(path.id)
                  } else {
                    navigate('/login')
                  }
                }}
                color={colors[index % colors.length]}
              />
            </motion.div>
          ))}
        </div>
      </div>
      

    </div>
  )
}
