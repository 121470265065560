export default class DeviceManager {
    constructor(networkState) {
        this.networkState = networkState;
    }

    addDevice(device) {
        const deviceObj = {
            id: device.id,
            type: device.type,
            position: device.position,
            interfaces: this.createInterfaces(device.type),
            config: this.getDefaultConfig(device.type)
        };

        this.networkState.devices.set(deviceObj.id, deviceObj);
        return this.serializeDevice(deviceObj);
    }

    deleteDevice(deviceId) {
        const device = this.networkState.devices.get(deviceId);
        if (!device) return false;

        // Delete associated connections
        const connectionsToDelete = Array.from(this.networkState.connections.values())
            .filter(conn => conn.fromDevice === deviceId || conn.toDevice === deviceId)
            .map(conn => conn.id);

        connectionsToDelete.forEach(connId => {
            this.networkState.connectionManager.deleteConnection(connId);
        });

        this.networkState.devices.delete(deviceId);
        return true;
    }

    getSerializedDevices() {
        const serializedDevices = {};
        this.networkState.devices.forEach((device, id) => {
            serializedDevices[id] = this.serializeDevice(device);
        });
        return serializedDevices;
    }

    serializeDevice(device) {
        return {
            ...device,
            interfaces: Object.fromEntries(Object.entries(device.interfaces)),
            config: Object.fromEntries(Object.entries(device.config))
        };
    }

    createInterfaces(deviceType) {
        switch(deviceType) {
            case 'router':
                return {
                    'FastEthernet0/0': { status: 'down', ip: null, subnet: null },
                    'FastEthernet0/1': { status: 'down', ip: null, subnet: null },
                    'GigabitEthernet0/0': { status: 'down', ip: null, subnet: null }
                };
            case 'switch':
                return {
                    'FastEthernet0/1': { status: 'down', vlan: 1 },
                    'FastEthernet0/2': { status: 'down', vlan: 1 },
                    'FastEthernet0/3': { status: 'down', vlan: 1 },
                    'FastEthernet0/4': { status: 'down', vlan: 1 }
                };
            case 'pc':
                return {
                    'Ethernet0': { status: 'down', ip: null, subnet: null, gateway: null }
                };
            default:
                return {};
        }
    }

    getDefaultConfig(deviceType) {
        switch(deviceType) {
            case 'router':
                return {
                    hostname: 'Router',
                    vlans: {},
                    routes: []
                };
            case 'switch':
                return {
                    hostname: 'Switch',
                    vlans: {
                        1: { name: 'default' }
                    }
                };
            case 'pc':
                return {
                    hostname: 'PC',
                    ip: null,
                    subnet: null,
                    gateway: null
                };
            default:
                return {};
        }
    }

    updateDevicePosition(deviceId, position) {
        const device = this.networkState.devices.get(deviceId);
        if (device) {
            device.position = position;
            return true;
        }
        return false;
    }
}

