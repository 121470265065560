import React, { useState, useEffect } from 'react';
import { FileText, Code, Play, Maximize2 } from 'lucide-react';
import parse from 'html-react-parser';
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader';
import FreeTrialHeader from '../../Components/Headers/FreeTrialHeader';
import Footer from '../../Components/Footer';
import options from '../../Components/Lessons/OptionsQuill';

// Reusable Button component
const Button = React.memo(({ children, onClick, className, icon: Icon }) => (
  <button
    onClick={onClick}
    className={`px-2 sm:px-3 py-1 sm:py-2 rounded-md text-xs sm:text-sm font-medium transition-colors duration-150 flex items-center ${className}`}
  >
    {Icon && <Icon size={14} className="mr-1 sm:mr-2" />}
    {children}
  </button>
));

export default function CustomLesson({ lesson, isSubscribed }) {
  const [isLoading, setIsLoading] = useState(false);
  const [customHtml, setCustomHtml] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    const iframeElement = document.querySelector('.lesson-iframe');
    
    if (!document.fullscreenElement) {
      if (iframeElement.requestFullscreen) {
        iframeElement.requestFullscreen();
      } else if (iframeElement.webkitRequestFullscreen) {
        iframeElement.webkitRequestFullscreen();
      } else if (iframeElement.msRequestFullscreen) {
        iframeElement.msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    async function fetchHtmlContent() {
      if (lesson?.file_url) {
        setIsLoading(true);
        try {
          const response = await fetch(lesson.file_url);
          const htmlContent = await response.text();
          setCustomHtml(htmlContent);
        } catch (error) {
          console.error('Error loading custom HTML:', error);
          setCustomHtml('<p>Error loading content. Please try again later.</p>');
        } finally {
          setIsLoading(false);
        }
      }
    }
    
    fetchHtmlContent();
  }, [lesson]);

  return (
    <div className="min-h-screen flex flex-col bg-white text-gray-800 font-sans">
      {isSubscribed ? <CodeForCambodiaHeader /> : <FreeTrialHeader />}

      <div className="flex-grow flex">
        {/* Instructions Panel - 1/3 width */}
        <div className="w-1/3 bg-white overflow-y-auto border-r border-gray-200">
          <div className="max-w-none p-4 prose">
            {lesson?.instructions ? 
              parse(lesson.instructions, options) : 
              <p className="text-gray-600 italic">No instructions available for this lesson.</p>
            }
          </div>
        </div>

        {/* Custom HTML Panel - 2/3 width */}
        <div className="w-2/3 relative">
          {!isLoading && (
            <button
              onClick={toggleFullscreen}
              className="absolute top-2 right-2 p-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors duration-150 z-10"
            >
              <Maximize2 size={20} />
            </button>
          )}
          {isLoading ? (
            <div className="flex items-center justify-center h-full">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
            </div>
          ) : (
            <iframe
              srcDoc={customHtml}
              className="w-full h-full border-0 lesson-iframe"
              title="Custom Lesson Content"
              sandbox="allow-scripts allow-same-origin"
            />
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}
