export default class BasicCommands {
    constructor(networkState) {
        this.networkState = networkState;
    }

    canHandle(cmd) {
        return ['enable', 'exit', 'end'].includes(cmd);
    }

    execute(cmd, args, deviceId) {
        switch (cmd) {
            case 'enable':
                return this.handleEnable(deviceId);
            case 'exit':
                return this.handleExit(deviceId);
            case 'end':
                return this.handleEnd(deviceId);
            default:
                return { error: 'Unknown basic command' };
        }
    }

    handleEnable(deviceId) {
        // Remove the incorrect mode check
        // Transition to privileged mode from any mode
        this.networkState.configMode.set(deviceId, { mode: 'privileged' });
        return {
            success: true,
            output: '',
            prompt: this.networkState.getCommandPrompt(deviceId)
        };
    }

    handleExit(deviceId) {
        const currentMode = this.networkState.configMode.get(deviceId) || { mode: 'user' };
        let newMode;

        switch (currentMode.mode) {
            case 'privileged':
                newMode = { mode: 'user' };
                break;
            case 'config':
            case 'config-if':
            case 'config-router':
            case 'config-line':
                newMode = { mode: 'privileged' };
                break;
            case 'user':
                return { success: true, output: 'Logout', prompt: '' };
            default:
                return { error: 'Cannot exit from current mode' };
        }

        this.networkState.configMode.set(deviceId, newMode);
        return {
            success: true,
            output: '',
            prompt: this.networkState.getCommandPrompt(deviceId)
        };
    }

    handleEnd(deviceId) {
        this.networkState.configMode.set(deviceId, { mode: 'privileged' });
        return {
            success: true,
            output: '',
            prompt: this.networkState.getCommandPrompt(deviceId)
        };
    }
}
