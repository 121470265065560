import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { ArrowRight, Code, Briefcase, CheckCircle, MessageSquare, ClipboardList, Search, FileUpIcon, SmileIcon } from 'lucide-react'
import HandsOnLearning from '../Components/HomePage/HandsOnLearning'
import CourseList from '../Components/HomePage/CourseList'
import axios from 'axios'
import Hero from '../Components/HomePage/Hero'
import Footer from '../Components/Footer'
import FeaturesAndCTA from '../Components/HomePage/FeaturesAndCTA'
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader'
import CTASection from '../Components/HomePage/CTASection'
import TestimonialsSection from '../Components/HomePage/TestimonialsSection'
import WhatsRightForYou from '../Components/HomePage/WhatsRightForYou'
import LearningPathsGrid from '../Components/HomePage/LearningPathsGrid'
import Advertisement from '../Components/HomePage/Advertisement'
import ChallengePromotion from '../Components/HomePage/ChallengePromotion'
import NewsCompanyMention from '../Components/HomePage/NewsCompanyMention'

export default function HomePage({onRegisterSuccess}) {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get('https://codeforcambodia.codes/codecademy/fetch/courses');
        setCourses(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching courses');
        setLoading(false);
      }
    };

    fetchCourses();
  }, [0]);

  return (
    <div className="min-h-screen bg-white text-white">
      <CodeForCambodiaHeader/>
      <Hero onRegisterSuccess={onRegisterSuccess}/>

      {/* <ChallengePromotion/>  Add this line */}
      <NewsCompanyMention/>
      <CourseList courses={courses}/>

      {/* <WhatsRightForYou/> */}
      <LearningPathsGrid/>

      <Advertisement/>

      <HandsOnLearning/>

      <TestimonialsSection/>
      
      <CTASection/>
      
      <Footer/>
    </div>
  )
}