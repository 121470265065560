import React, { useEffect, useState, useRef } from 'react';
import { Terminal, Minimize2, Maximize2, X } from 'lucide-react';
import Draggable from 'react-draggable';

const CLI = ({ device, onClose, executeCommand }) => {
  const [input, setInput] = useState('');
  const [history, setHistory] = useState([]);
  const [prompt, setPrompt] = useState('');
  const [commandHistory, setCommandHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [isMinimized, setIsMinimized] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(false);
  const inputRef = useRef(null);
  const historyRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      
      if (!mobile) {
        // Center position for desktop
        setPosition({
          x: (window.innerWidth / 2) - 400,
          y: (window.innerHeight / 2) - 225
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setHistory([
      { 
        type: 'output', 
        content: `${device.type} Console, Press RETURN to get started.`
      }
    ]);

    const deviceName = device.config?.hostname || device.type;
    setPrompt(`${deviceName}>`);
  }, [device]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      
      const command = input.trim();
      if (command) {
        setHistory(prev => [
          ...prev,
          { type: 'command', content: `${prompt}${command}` }
        ]);
        
        setCommandHistory(prev => [...prev, command]);
        setHistoryIndex(-1);
        
        const result = executeCommand({ command, deviceId: device.id });
        
        if (result.error) {
          setHistory(prev => [
            ...prev,
            { type: 'error', content: `%Error: ${result.error}` }
          ]);
        } else if (result.output) {
          if (Array.isArray(result.output)) {
            setHistory(prev => [
              ...prev,
              ...result.output.map(line => ({ type: 'output', content: line }))
            ]);
          } else {
            setHistory(prev => [
              ...prev,
              { type: 'output', content: result.output }
            ]);
          }
        }
        
        if (result.prompt) {
          setPrompt(result.prompt);
        }
        
        setInput('');
      }
    } 
    else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (historyIndex < commandHistory.length - 1) {
        const newIndex = historyIndex + 1;
        setHistoryIndex(newIndex);
        setInput(commandHistory[commandHistory.length - 1 - newIndex]);
      }
    }
    else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (historyIndex > 0) {
        const newIndex = historyIndex - 1;
        setHistoryIndex(newIndex);
        setInput(commandHistory[commandHistory.length - 1 - newIndex]);
      } else if (historyIndex === 0) {
        setHistoryIndex(-1);
        setInput('');
      }
    }
    else if (e.key === 'Tab') {
      e.preventDefault();
      const commands = [
        'enable',
        'configure',
        'interface',
        'show',
        'ip',
        'no',
        'end',
        'exit',
        'hostname'
      ];
      
      const match = commands.find(cmd => 
        cmd.startsWith(input.toLowerCase())
      );
      
      if (match) {
        setInput(match);
      }
    }
  };

  useEffect(() => {
    if (historyRef.current) {
      historyRef.current.scrollTop = historyRef.current.scrollHeight;
    }
    inputRef.current?.focus();
  }, [history]);

  const containerStyles = isMobile ? {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    margin: 0,
    zIndex: 50
  } : {
    position: 'fixed',
    width: '100%',
    maxWidth: '64rem',
    zIndex: 50
  };

  return (
    <Draggable
      handle=".cli-handle"
      position={isMobile ? { x: 0, y: 0 } : position}
      disabled={isMobile}
      onStop={(e, data) => !isMobile && setPosition({ x: data.x, y: data.y })}
    >
      <div style={containerStyles} className="bg-white border border-gray-200 rounded-lg shadow-lg">
        <div 
          className="cli-handle flex justify-between items-center px-4 py-2 bg-gray-50 border-b border-gray-200 rounded-t-lg select-none"
          style={{ cursor: isMobile ? 'default' : 'move' }}
        >
          <div className="flex items-center gap-2">
            <Terminal className="w-4 h-4 text-gray-600" />
            <span className="text-gray-700 truncate">
              {device.config?.hostname || device.type} CLI
            </span>
          </div>
          <div className="flex items-center gap-2">
            {!isMobile && (
              <button 
                className="text-gray-600 hover:text-gray-800 transition-colors"
                onClick={() => setIsMinimized(!isMinimized)}
              >
                {isMinimized ? <Maximize2 className="w-4 h-4" /> : <Minimize2 className="w-4 h-4" />}
              </button>
            )}
            <button 
              className="text-gray-600 hover:text-red-600 transition-colors"
              onClick={onClose}
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        </div>

        <div className={`${isMinimized ? 'hidden' : 'flex flex-col'}`} 
             style={{ height: isMobile ? 'calc(100vh - 48px)' : '450px' }}>
          <div 
            ref={historyRef}
            className="flex-1 overflow-y-auto p-4 font-mono text-sm text-blue-600"
          >
            {history.map((entry, i) => (
              <div 
                key={i} 
                className={`whitespace-pre-wrap ${
                  entry.type === 'error' ? 'text-red-600' : 
                  entry.type === 'command' ? 'text-gray-800' : ''
                }`}
              >
                {entry.content}
              </div>
            ))}
          </div>

          <div className="flex items-center px-4 py-2 bg-gray-50 border-t border-gray-200">
            <span className="text-blue-600 font-mono mr-2">{prompt}</span>
            <input
              ref={inputRef}
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              className="flex-1 bg-transparent text-gray-800 font-mono focus:outline-none"
              autoFocus
            />
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default CLI;
