// Simulating Express's internal architecture

class Route {
    constructor(path) {
      this.path = path;
      this.stack = []; // [{ method: 'GET', handle: fn }, ...]
      this.paramNames = [];
      this.middleware = [];
      this.pattern = this.pathToPattern(path);
      this.errorHandlers = [];
    }
  
    pathToPattern(path) {
      // Support path parameters with custom patterns
      // e.g., :id(\d+) for numeric IDs
      const sanitizedPath = path.replace(/\/$/, '');
      const pattern = sanitizedPath.replace(/:[a-zA-Z]+(\([^)]+\))?/g, (match, pattern) => {
        const paramName = match.slice(1).split('(')[0];
        this.paramNames.push(paramName);
        return pattern ? `(${pattern.slice(1, -1)})` : '([^/]+)';
      });
      return new RegExp(`^${pattern}/?$`);
    }
  
    matches(reqPath) {
      if (this.path === '/' && (reqPath === '/' || reqPath === '')) {
        return {};
      }
  
      const match = reqPath.match(this.pattern);
      if (!match) return false;
  
      const params = {};
      this.paramNames.forEach((name, i) => {
        params[name] = match[i + 1];
      });
      return params;
    }
  
    // Add middleware specific to this route
    use(handler) {
      if (typeof handler !== 'function') {
        throw new Error('Middleware handler must be a function');
      }
      this.middleware.push(handler);
      return this;
    }
  
    // HTTP method handlers with middleware support
    get(handler) {
      console.log('Adding GET handler for route:', this.path);
      this._addHandler('GET', handler);
      return this;
    }
  
    post(handler) {
      this._addHandler('POST', handler);
      return this;
    }
  
    put(handler) {
      this._addHandler('PUT', handler);
      return this;
    }
  
    delete(handler) {
      this._addHandler('DELETE', handler);
      return this;
    }
  
    patch(handler) {
      this._addHandler('PATCH', handler);
      return this;
    }
  
    options(handler) {
      this._addHandler('OPTIONS', handler);
      return this;
    }
  
    head(handler) {
      this._addHandler('HEAD', handler);
      return this;
    }
  
    all(handler) {
      this._addHandler('ALL', handler);
      return this;
    }
  
    // Error handling middleware
    error(handler) {
      if (handler.length !== 4) {
        throw new Error('Error handling middleware must take exactly 4 arguments (err, req, res, next)');
      }
      this.errorHandlers.push(handler);
      return this;
    }
  
    _addHandler(method, handler) {
      if (typeof handler !== 'function') {
        throw new Error('Route handler must be a function');
      }
      this.stack.push({ method, handle: handler });
    }
  
    async handle(req, res) {
        console.log('Route handling request:', this.path);
    
        try {
            // Execute route-specific middleware first
            for (const middleware of this.middleware) {
                await new Promise((resolve, reject) => {
                    let nextCalled = false;
                    const next = (err) => {
                        nextCalled = true;
                        if (err) reject(err);
                        else resolve();
                    };
                    middleware(req, res, next);
                    // If response is ended during middleware, resolve immediately
                    if (res._ended) {
                        resolve();
                    }
                    // If next() wasn't called and response isn't ended, resolve anyway
                    if (!nextCalled && !res._ended) {
                        resolve();
                    }
                });

                // Check if middleware ended the response
                if (res._ended) {
                    return res.getResponse();
                }
            }
    
            // Find matching route handlers
            const layers = this.stack.filter(layer =>
                layer.method === req.method || layer.method === 'ALL'
            );
            console.log('Matching layers:', layers);
    
            if (layers.length === 0) {
                res.status(404).send('Not Found');
                return res.getResponse();
            }
    
            // Execute all matching handlers in sequence
            for (const layer of layers) {
                await new Promise((resolve, reject) => {
                    let nextCalled = false;
                    const next = (err) => {
                        nextCalled = true;
                        if (err) reject(err);
                        else resolve();
                    };

                    // Execute the handler
                    layer.handle(req, res, next);

                    // If response is ended during handler, resolve immediately
                    if (res._ended) {
                        resolve();
                    }
                    // If next() wasn't called and response isn't ended, resolve anyway
                    if (!nextCalled && !res._ended) {
                        resolve();
                    }
                });

                // If response was ended by this handler, return it
                if (res._ended) {
                    return res.getResponse();
                }
            }
    
            // If we get here and no response was sent, send a default response
            if (!res._ended) {
                res.status(200).send('OK');
            }
    
            return res.getResponse();
            
        } catch (err) {
            console.error('Error in route handler:', err);
            return this._handleError(err, req, res);
        }
    }
  }
  
  class Router {
    constructor() {
      this.routes = [];
      this.params = {};
      this.middleware = [];
      this.errorHandlers = [];
      this.basePath = '';
    }
  
    use(path, handler) {
      if (typeof path === 'function') {
        handler = path;
        path = '/';
      }
      console.log('Router.use called with path:', path);
      this.middleware.push({ path, handler });
      return this;
    }
  
    get(path, handler) {
      console.log('Router GET called with path:', path, 'basePath:', this.basePath);
      const fullPath = this.getFullPath(path);
      console.log('Full path for route:', fullPath);
      const route = this.route(fullPath);
      route.get(handler);
      return this;
    }

    post(path, handler) {
      console.log('Router POST called with path:', path);
      const fullPath = this.getFullPath(path);
      const route = this.route(fullPath);
      route.post(handler);
      return this;
    }

    put(path, handler) {
      console.log('Router PUT called with path:', path);
      const fullPath = this.getFullPath(path);
      const route = this.route(fullPath);
      route.put(handler);
      return this;
    }

    delete(path, handler) {
      console.log('Router DELETE called with path:', path);
      const fullPath = this.getFullPath(path);
      const route = this.route(fullPath);
      route.delete(handler);
      return this;
    }
  
    patch(path, handler) {
      console.log('Router PATCH called with path:', path);
      const fullPath = this.getFullPath(path);
      const route = this.route(fullPath);
      route.patch(handler);
      return this;
    }
  
    getFullPath(path) {
      // Handle edge cases with slashes
      if (this.basePath === '/' || this.basePath === '') {
        return path;
      }
      const normalizedBase = this.basePath.endsWith('/') ? this.basePath.slice(0, -1) : this.basePath;
      const normalizedPath = path.startsWith('/') ? path : '/' + path;
      return `${normalizedBase}${normalizedPath}`;
    }
  
    findRoute(path) {
      console.log('Finding route for path:', path, 'available routes:', this.routes.map(r => r.path));
      // Remove trailing slashes for comparison
      const normalizedPath = path.replace(/\/$/, '');
      return this.routes.find(route => route.path.replace(/\/$/, '') === normalizedPath);
    }
  
    route(path) {
      console.log('Creating/finding route for path:', path);
      let route = this.findRoute(path);
      if (!route) {
        route = new Route(path);
        this.routes.push(route);
      }
      return route;
    }
  
    async handle(req, res) {
      console.log('Router handling request:', req.method, req.path);
      console.log('Available routes:', this.routes.map(r => ({
        path: r.path,
        handlers: r.stack.map(h => ({ method: h.method, hasHandler: !!h.handle }))
      })));
  
      // First, handle middleware
      try {
        for (const { path, handler } of this.middleware) {
          // Check if the request path matches the middleware path
          if (req.path.startsWith(path)) {
            await new Promise((resolve, reject) => {
              const next = (err) => err ? reject(err) : resolve();
              handler(req, res, next);
            });
            
            if (res._ended) {
              return res.getResponse();
            }
          }
        }
      } catch (err) {
        return this._handleError(err, req, res);
      }
  
      // Then try to match routes
      for (const route of this.routes) {
        const params = route.matches(req.path);
        if (params !== false) {
          req.params = { ...req.params, ...params };
          
          try {
            // Execute param handlers if they exist
            for (const [name, handler] of Object.entries(this.params)) {
              if (name in params) {
                await new Promise((resolve, reject) => {
                  handler(req, res, () => resolve(), params[name]);
                });
              }
            }
            
            console.log('Route matched, executing handler for:', route.path);
            return await route.handle(req, res);
          } catch (err) {
            return this._handleError(err, req, res);
          }
        }
      }
  
      // No matching route found
      res.status(404).send('Not Found');
      return res.getResponse();
    }
  }
  
  class Response {
    constructor() {
      this._status = 200;
      this._headers = new Map();
      this._body = null;
      this._ended = false;
      this._cookies = new Map();
    }
  
    status(code) {
      this._status = code;
      return this;
    }
  
    set(header, value) {
      this._headers.set(header.toLowerCase(), value);
      return this;
    }
  
    get(header) {
      return this._headers.get(header.toLowerCase());
    }
  
    cookie(name, value, options = {}) {
      this._cookies.set(name, { value, options });
      return this;
    }
  
    clearCookie(name) {
      this.cookie(name, '', { expires: new Date(0) });
      return this;
    }
  
    send(body) {
      if (this._ended) {
        throw new Error('Cannot send response after it has ended');
      }
      this._body = body;
      this._ended = true;
      return this;
    }
  
    json(data) {
      this._headers.set('content-type', 'application/json');
      return this.send(JSON.stringify(data));
    }
  
    sendStatus(code) {
      const statusMessages = {
        200: 'OK',
        201: 'Created',
        204: 'No Content',
        400: 'Bad Request',
        401: 'Unauthorized',
        403: 'Forbidden',
        404: 'Not Found',
        500: 'Internal Server Error'
      };
      return this.status(code).send(statusMessages[code] || String(code));
    }
  
    redirect(status, url) {
      if (typeof status === 'string') {
        url = status;
        status = 302;
      }
      this._status = status;
      this._headers.set('location', url);
      this._ended = true;
      return this;
    }
  
    type(type) {
      const contentType = type.includes('/') ? type : {
        json: 'application/json',
        text: 'text/plain',
        html: 'text/html',
        xml: 'application/xml'
      }[type] || 'application/octet-stream';
  
      this._headers.set('content-type', contentType);
      return this;
    }
  
    getResponse() {
      // Convert cookies to Set-Cookie headers
      if (this._cookies.size > 0) {
        const cookieStrings = Array.from(this._cookies.entries()).map(([name, { value, options }]) => {
          let cookieString = `${name}=${value}`;
          if (options.expires) cookieString += `; Expires=${options.expires.toUTCString()}`;
          if (options.maxAge) cookieString += `; Max-Age=${options.maxAge}`;
          if (options.domain) cookieString += `; Domain=${options.domain}`;
          if (options.path) cookieString += `; Path=${options.path}`;
          if (options.secure) cookieString += '; Secure';
          if (options.httpOnly) cookieString += '; HttpOnly';
          if (options.sameSite) cookieString += `; SameSite=${options.sameSite}`;
          return cookieString;
        });
        this._headers.set('set-cookie', cookieStrings);
      }
  
      return {
        status: this._status,
        headers: Object.fromEntries(this._headers),
        body: this._body
      };
    }
  }
  
  class Request {
    constructor(method, path, body = null, headers = {}) {
      this.method = method.toUpperCase();
      const parsedUrl = this.parsePath(path);
      this.path = parsedUrl.pathname;
      this.url = path;
      this.body = body;
      this.headers = new Proxy(headers, {
        get: (target, prop) => target[prop.toLowerCase()],
        set: (target, prop, value) => {
          target[prop.toLowerCase()] = value;
          return true;
        }
      });
      this.params = {};
      // Parse query parameters into an object
      this.query = this._parseQueryString(parsedUrl.searchParams);
      this.cookies = this.parseCookies(headers.cookie);
      this._fresh = false;
      this._stale = true;
      this.xhr = headers['x-requested-with']?.toLowerCase() === 'xmlhttprequest';
    }
  
    _parseQueryString(searchParams) {
      const query = {};
      for (const [key, value] of searchParams.entries()) {
        // Handle array parameters (e.g., ?items=1&items=2)
        if (query[key]) {
          if (!Array.isArray(query[key])) {
            query[key] = [query[key]];
          }
          query[key].push(value);
        } else {
          query[key] = value;
        }
      }
      return query;
    }
  
    parsePath(path) {
      // Use URL constructor with a base URL to handle relative paths
      return new URL(path, 'http://localhost');
    }
  
    parseCookies(cookieHeader = '') {
      return Object.fromEntries(
        cookieHeader.split(';')
          .map(cookie => cookie.trim().split('='))
          .filter(([name]) => name)
      );
    }
  
    get(header) {
      return this.headers[header.toLowerCase()];
    }
  
    is(type) {
      const contentType = this.get('content-type') || '';
      if (Array.isArray(type)) {
        return type.some(t => this.is(t));
      }
      if (type.includes('/')) {
        return contentType.includes(type);
      }
      const mimeTypes = {
        json: 'application/json',
        text: 'text/plain',
        html: 'text/html',
        xml: 'application/xml'
      };
      return contentType.includes(mimeTypes[type] || type);
    }
  
    accepts(types) {
      const accept = this.get('accept') || '';
      if (!Array.isArray(types)) {
        types = [types];
      }
      return types.find(type => accept.includes(type));
    }
  }
  
  class Express {
    constructor() {
      this.router = new Router();
      this.middleware = [];
      this._settings = new Map();
      this.port = null;
      this.mountpath = '/';
      this.locals = {};
    }

    // Add json middleware method directly to the Express instance
    json() {
      return (req, res, next) => {
        if (req.headers['content-type']?.includes('application/json')) {
          try {
            if (typeof req.body === 'string') {
              req.body = JSON.parse(req.body);
            }
            next();
          } catch (err) {
            err.status = 400;
            next(err);
          }
        } else {
          next();
        }
      };
    }
  
    set(setting, value) {
      this._settings.set(setting, value);
      return this;
    }
  
    get(path, ...handlers) {
      if (typeof path === 'string') {
        console.log('Adding GET route:', path);
        const route = this.router.route(path);
        handlers.forEach(h => route.get(h));
        return this;
      }
      return this._settings.get(path);
    }
  
    use(path, handler) {
      if (typeof path === 'function') {
        handler = path;
        path = '/';
      }

      // Special handling for mounting routers
      if (handler instanceof Router) {
        // Set base path for the router
        handler.basePath = path === '/' ? '' : path;
        console.log('Mounting router at:', path, 'basePath:', handler.basePath);
      }

      this.middleware.push({ path, handler });
      return this;
    }
  
    route(path) {
      return this.router.route(path);
    }
  
    // HTTP method handlers
    post(path, ...handlers) {
      const route = this.route(path);
      handlers.forEach(h => route.post(h));
      return this;
    }
  
    put(path, ...handlers) {
      const route = this.route(path);
      handlers.forEach(h => route.put(h));
      return this;
    }
  
    delete(path, ...handlers) {
      const route = this.route(path);
      handlers.forEach(h => route.delete(h));
      return this;
    }
  
    patch(path, ...handlers) {
      const route = this.route(path);
      handlers.forEach(h => route.patch(h));
      return this;
    }
  
    options(path, ...handlers) {
      const route = this.route(path);
      handlers.forEach(h => route.options(h));
      return this;
    }
  
    head(path, ...handlers) {
      const route = this.route(path);
      handlers.forEach(h => route.head(h));
      return this;
    }
  
    all(path, ...handlers) {
      const route = this.route(path);
      handlers.forEach(h => route.all(h));
      return this;
    }
  
    static json(options = {}) {
      const { limit = '100kb', strict = true } = options;
  
      return (req, res, next) => {
        if (req.is('json')) {
          const contentLength = parseInt(req.get('content-length'), 10);
          if (contentLength > parseInt(limit)) {
            const err = new Error('Request entity too large');
            err.status = 413;
            return next(err);
          }
  
          if (typeof req.body === 'string') {
            try {
              req.body = strict ? JSON.parse(req.body) : eval('(' + req.body + ')');
            } catch (err) {
              err.status = 400;
              return next(err);
            }
          }
        }
        next();
      };
    }
  
    static urlencoded(options = {}) {
      const { extended = true, limit = '100kb' } = options;
  
      return (req, res, next) => {
        if (req.is('application/x-www-form-urlencoded')) {
          const contentLength = parseInt(req.get('content-length'), 10);
          if (contentLength > parseInt(limit)) {
            const err = new Error('Request entity too large');
            err.status = 413;
            return next(err);
          }
  
          if (typeof req.body === 'string') {
            try {
              const parsed = new URLSearchParams(req.body);
              req.body = Object.fromEntries(parsed);
            } catch (err) {
              err.status = 400;
              return next(err);
            }
          }
        }
        next();
      };
    }
  
    static raw(options = {}) {
      const { limit = '100kb', type = 'application/octet-stream' } = options;
  
      return (req, res, next) => {
        if (req.is(type)) {
          const contentLength = parseInt(req.get('content-length'), 10);
          if (contentLength > parseInt(limit)) {
            const err = new Error('Request entity too large');
            err.status = 413;
            return next(err);
          }
        }
        next();
      };
    }
  
    static text(options = {}) {
      const { limit = '100kb' } = options;
  
      return (req, res, next) => {
        if (req.is('text/*')) {
          const contentLength = parseInt(req.get('content-length'), 10);
          if (contentLength > parseInt(limit)) {
            const err = new Error('Request entity too large');
            err.status = 413;
            return next(err);
          }
        }
        next();
      };
    }
  
    static static(root, options = {}) {
      const {
        dotfiles = 'ignore',
        etag = true,
        extensions = false,
        fallthrough = true,
        index = 'index.html',
        lastModified = true,
        redirect = true
      } = options;
  
      return (req, res, next) => {
        if (req.method !== 'GET' && req.method !== 'HEAD') {
          if (fallthrough) return next();
          res.sendStatus(405);
          return;
        }
  
        // In a real implementation, this would use the file system
        // For simulation, we'll just handle basic cases
        const path = req.path === '/' ? index : req.path;
  
        // Simulate file not found
        if (path.includes('..') || (!fallthrough && !root[path])) {
          res.sendStatus(404);
          return;
        }
  
        // Simulate serving file
        if (root[path]) {
          if (etag) {
            res.set('ETag', `W/"${Date.now().toString(16)}"`);
          }
          if (lastModified) {
            res.set('Last-Modified', new Date().toUTCString());
          }
          res.type(path.split('.').pop() || 'text/plain');
          res.send(root[path]);
          return;
        }
  
        if (fallthrough) next();
        else res.sendStatus(404);
      };
    }
  
    async handle(req) {
      console.log('Express handling request:', req.method, req.path);
      const res = new Response();
  
      // Add convenience methods and properties
      req.app = this;
      res.app = this;
  
      try {
        for (const { path, handler } of this.middleware) {
          if (req.path.startsWith(path)) {
            if (handler instanceof Router) {
              console.log('Processing router middleware at path:', path);
              // Adjust path for router
              const originalPath = req.path;
              const routerPath = path === '/' ? originalPath : originalPath.slice(path.length) || '/';
              console.log('Adjusted path for router:', routerPath);

              // Create temporary request with adjusted path
              const routerReq = { ...req, path: routerPath };
              const result = await handler.handle(routerReq, res);

              if (res._ended) {
                return result;
              }
            } else {
              await new Promise((resolve, reject) => {
                handler(req, res, (err) => err ? reject(err) : resolve());
              });

              if (res._ended) {
                return res.getResponse();
              }
            }
          }
        }

        return await this.router.handle(req, res);
      } catch (err) {
        return this._handleError(err, req, res);
      }
    }
  
    async _handleError(err, req, res) {
      // Default error handler
      const env = this.get('env') || 'development';
  
      if (res._ended) return res.getResponse();
  
      res.status(err.status || 500);
      res.json({
        message: err.message,
        error: env === 'development' ? err : {}
      });
  
      return res.getResponse();
    }
  
    listen(port, callback) {
      this.port = port;
      if (callback && typeof callback === 'function') {
        callback();
      }
      return this;
    }
  
    engine(ext, fn) {
      this._engines = this._engines || {};
      this._engines[ext] = fn;
      return this;
    }
  
    mount(path, app) {
      if (typeof path !== 'string') {
        throw new Error('Mount path must be a string');
      }
  
      const subapp = app;
      subapp.mountpath = path;
  
      this.use(path, (req, res, next) => {
        const originalUrl = req.url;
        const originalPath = req.path;
  
        // Adjust request path for the subapp
        req.url = req.url.substr(path.length) || '/';
        req.path = req.path.substr(path.length) || '/';
  
        subapp.handle(req, res, (err) => {
          // Restore original url and path
          req.url = originalUrl;
          req.path = originalPath;
          next(err);
        });
      });
  
      return this;
    }
  
    error(handler) {
      if (handler.length !== 4) {
        throw new Error('Error handling middleware must take exactly 4 arguments');
      }
      this.router.error(handler);
      return this;
    }
  }
  
  export const express = () => {
    const app = new Express();
    
    // Add static methods directly to the express function
    express.json = Express.json;
    express.urlencoded = Express.urlencoded;
    express.raw = Express.raw;
    express.text = Express.text;
    express.static = Express.static;
    
    // Add Router factory
    express.Router = () => new Router();
    
    return app;
  };
  
  // Make sure these static methods are defined on Express class
  Object.assign(Express, {
    json(options = {}) {
      return (req, res, next) => {
        if (req.headers['content-type']?.includes('application/json')) {
          try {
            if (typeof req.body === 'string') {
              req.body = JSON.parse(req.body);
            }
            next();
          } catch (err) {
            err.status = 400;
            next(err);
          }
        } else {
          next();
        }
      };
    },
    
    // Keep other static methods...
    urlencoded: Express.urlencoded,
    raw: Express.raw,
    text: Express.text,
    static: Express.static
  });
  
  export { Request, Response };