'use client'

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { PhpWeb } from 'php-wasm/PhpWeb.mjs'
import Editor from '@monaco-editor/react'
import { debounce } from 'lodash'
import { DraggableCore } from 'react-draggable'
import Header from '../../Headers/CodeForCambodiaHeader'
import Footer from '../../Footer'
import options from '../OptionsQuill'
import parse from 'html-react-parser'
import Confetti from 'react-confetti'
import axios from 'axios'
import { Maximize2, Minimize2, RefreshCw, ToggleRight } from 'lucide-react'
import ReusableEditor from '../../Challenge/Editor/ReusableEditor'
import {PhpReusablePreview} from "../../Challenge/Editor/ReusablePreview"
import FreeTrialHeader from '../../Headers/FreeTrialHeader'
const RetroButton = ({ onClick, disabled, children, active, className }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`
      px-2 py-1 text-sm sm:text-base md:text-lg font-bold text-black
      border-t-2 border-l-2 border-[#ffffff] border-r-2 border-b-2 border-[#808080]
      ${active ? 'bg-[#a0a0a0]' : 'bg-[#c0c0c0]'}
      ${disabled
        ? 'opacity-50 cursor-not-allowed'
        : 'hover:bg-[#d0d0d0] active:border-t-2 active:border-l-2 active:border-[#808080] active:border-r-2 active:border-b-2 active:border-[#ffffff]'
      }
      ${className}
    `}
  >
    {children}
  </button>
)

const FeedbackMessage = ({ type, message }) => {
  const styles = {
    success: {
      container: 'bg-[#98ff98] border-[#006400] text-[#006400]',
      icon: '✓',
    },
    error: {
      container: 'bg-[#ff9898] border-[#8b0000] text-[#8b0000]',
      icon: '✕',
    },
  }

  const style = styles[type]

  return (
    <div
      className={`
        mt-2 p-2 border-2 border-inset
        flex items-center gap-2
        font-['VT323',_monospace] text-base
        ${style.container}
      `}
    >
      <span className="text-xl font-bold">{style.icon}</span>
      <span>{message}</span>
    </div>
  )
}

const SampleSection = ({ sampleCode }) => {
  const [phpCode] = useState(sampleCode || '')
  const [output, setOutput] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const phpRef = useRef(null)
  const outputRef = useRef(null)

  useEffect(() => {
    const initPhp = async () => {
      try {
        const php = new PhpWeb()
        phpRef.current = php

        php.addEventListener('ready', () => {
          console.log('PHP-WASM is ready in SampleSection!')
          runPhpCode()
        })

        php.addEventListener('output', (event) => {
          setOutput((prevOutput) => prevOutput + event.detail)
        })

        php.addEventListener('error', (event) => {
          setError((prevError) => prevError + event.detail)
        })
      } catch (err) {
        console.error('Failed to initialize PHP-WASM in SampleSection:', err)
        setError('Failed to initialize PHP-WASM. Please check your setup.')
      }
    }

    initPhp()
  }, [])

  const runPhpCode = async (formData = {}) => {
    if (phpRef.current && phpCode) {
      setIsLoading(true)
      setOutput('')
      setError('')
      try {
        await phpRef.current.run(phpCode, {
          post: formData,
        })
      } catch (err) {
        console.error('Failed to run PHP code in SampleSection:', err)
        setError('Failed to run PHP code. Please check your code and try again.')
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const formObject = {}
    formData.forEach((value, key) => {
      formObject[key] = value
    })

    // Re-run the PHP code, passing the form data
    runPhpCode(formObject)
  }

  useEffect(() => {
    if (outputRef.current) {
      // Modify the output HTML to adjust forms
      const parser = new DOMParser()
      const doc = parser.parseFromString(output, 'text/html')
      const forms = doc.querySelectorAll('form')
      forms.forEach((form) => {
        form.setAttribute('action', '')
        form.setAttribute('onsubmit', 'return false;')
      })
      const modifiedOutput = doc.body.innerHTML
      setOutput(modifiedOutput)

      const formsInOutput = outputRef.current.querySelectorAll('form')
      formsInOutput.forEach((form) => {
        form.addEventListener('submit', handleFormSubmit)
      })

      // Cleanup on unmount or when output changes
      return () => {
        formsInOutput.forEach((form) => {
          form.removeEventListener('submit', handleFormSubmit)
        })
      }
    }
  }, [output])

  return (
    <div className="flex flex-col h-full">
      <div className="bg-white border-2 border-inset p-2 h-full overflow-auto flex flex-col" ref={outputRef}>
        <div className="mt-2">
          <div className="bg-[#f0f0f0] p-2 rounded text-sm sm:text-base md:text-lg">
            {isLoading ? (
              <div className="flex items-center gap-2">
                <div className="animate-spin w-4 h-4 border-2 border-gray-800 border-t-transparent rounded-full"></div>
                <span>Running sample code...</span>
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: output }} />
            )}
          </div>
          {error && <FeedbackMessage type="error" message={error} />}
        </div>
      </div>
    </div>
  )
}

const InstructionsSection = ({ instructions }) => (
  <div className="flex-grow overflow-auto p-2 max-h-full">
    <div className="prose max-w-none text-sm sm:text-base md:text-lg overflow-y-auto Nokora">
      {parse(instructions || '', options)}
    </div>
  </div>
)

const CodeSection = ({
  phpCode,
  setPhpCode,
  output,
  error,
  isLoading,
  runPhpCode,
  verifyCode,
  isVerifying,
  editorRef,
  handleEditorMount,
  splitPosition,
  setSplitPosition,
  isDragging,
  setIsDragging,
  startX,
  setStartX,
  verificationStatus,
  successMessage,
  refreshCode,
}) => {
  const dragRef = useRef(null)
  const containerRef = useRef(null)
  const outputRef = useRef(null)

  const handleDragStart = useCallback(
    (e) => {
      setIsDragging(true)
      const containerRect = containerRef.current.getBoundingClientRect()
      setStartX(e.clientX - containerRect.left)
    },
    [setIsDragging, setStartX]
  )

  const handleDrag = useCallback(
    (e) => {
      if (!isDragging || !containerRef.current) return

      const containerRect = containerRef.current.getBoundingClientRect()
      const containerWidth = containerRect.width
      const currentX = e.clientX - containerRect.left
      const deltaX = currentX - startX

      requestAnimationFrame(() => {
        const newPosition = splitPosition + (deltaX / containerWidth) * 100
        const clampedPosition = Math.min(Math.max(newPosition, 20), 80)
        setSplitPosition(clampedPosition)
        setStartX(currentX)
      })
    },
    [isDragging, splitPosition, startX, setSplitPosition, setStartX]
  )

  const handleDragStop = useCallback(() => {
    setIsDragging(false)
    if (editorRef.current) {
      setTimeout(() => {
        editorRef.current.layout()
      }, 50)
    }
  }, [setIsDragging, editorRef])

  // Modify the output HTML to prevent default form submissions
  const modifiedOutput = useMemo(() => {
    if (!output) return ''
    // Use a DOM parser to modify the form's action attribute
    const parser = new DOMParser()
    const doc = parser.parseFromString(output, 'text/html')
    const forms = doc.querySelectorAll('form')
    forms.forEach((form) => {
      form.setAttribute('action', '')
      form.setAttribute('onsubmit', 'return false;')
    })
    return doc.body.innerHTML
  }, [output])

  const handleFormSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const formObject = {}
    formData.forEach((value, key) => {
      formObject[key] = value
    })

    // Re-run the PHP code, passing the form data
    runPhpCode(formObject)
  }

  useEffect(() => {
    if (outputRef.current) {
      const forms = outputRef.current.querySelectorAll('form')
      forms.forEach((form) => {
        form.addEventListener('submit', handleFormSubmit)
      })

      // Cleanup on unmount or when output changes
      return () => {
        forms.forEach((form) => {
          form.removeEventListener('submit', handleFormSubmit)
        })
      }
    }
  }, [modifiedOutput])

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-grow relative" ref={containerRef}>
        <div
          style={{
            width: `${splitPosition}%`,
            transition: isDragging ? 'none' : 'width 0.1s ease-out',
          }}
          className="h-full border-2 border-inset bg-[#000000] relative"
        >
          <ReusableEditor
            activeCodeTab={"php"}
            code={phpCode}
            onChange={(value) => setPhpCode(value || '')}
            handleEditorMount={handleEditorMount}
            options={{
              fontSize: 20,
              lineNumbers: 'on',
              renderLineHighlight: 'all',
              tabSize: 2,
              automaticLayout: true,
              fontFamily: "'VT323', monospace",
              fontLigatures: true,
            }}
            theme="vs-dark"
          />
        </div>
        <DraggableCore onStart={handleDragStart} onDrag={handleDrag} onStop={handleDragStop} nodeRef={dragRef}>
          <div
            ref={dragRef}
            className={`
              absolute top-0 bottom-0
              w-4 -ml-2
              cursor-col-resize
              group
              flex items-center justify-center
              z-10
              ${isDragging ? 'select-none' : ''}
            `}
            style={{
              left: `${splitPosition}%`,
              transition: isDragging ? 'none' : 'left 0.1s ease-out',
            }}
          >
            <div
              className={`
                w-1 h-8
                bg-gray-400
                group-hover:bg-blue-400
                rounded
                transition-colors duration-150
                ${isDragging ? 'bg-blue-500' : ''}
              `}
            />
            <div
              className={`
                absolute left-0 top-0 bottom-0 w-full
                opacity-0 group-hover:opacity-100
                transition-opacity duration-150
                pointer-events-none
                ${isDragging ? 'opacity-100' : ''}
              `}
              style={{
                background:
                  'linear-gradient(90deg, rgba(0,0,0,0.02) 0%, rgba(0,0,0,0.05) 50%, rgba(0,0,0,0.02) 100%)',
                width: '200px',
                transform: 'translateX(-50%)',
              }}
            />
          </div>
        </DraggableCore>
        
        <div
          style={{
            width: `${100 - splitPosition}%`,
            transition: isDragging ? 'none' : 'width 0.1s ease-out',
          }}
          className="h-full bg-white border-2 border-inset overflow-auto p-1 flex flex-col"
        >
          {/* Preview Section */}
          <div className="flex-grow overflow-auto" ref={outputRef}>
           
            <PhpReusablePreview
              className={"text-sm sm:text-base md:text-lg"}
              outputHtml={modifiedOutput}
            />
            
          </div>

          {/* Feedback Messages */}
          {verificationStatus === 'success' && <FeedbackMessage type="success" message={successMessage} />}
          {verificationStatus === 'error' && <FeedbackMessage type="error" message={error} />}
        </div>
      </div>
      {/* Action Buttons */}
      <div className="mt-2 space-y-2">
        <div className="flex gap-2">
          <RetroButton onClick={() => runPhpCode()} disabled={isLoading} className={isLoading ? 'opacity-50' : ''}>
            <div className="flex items-center gap-2">
              {isLoading && (
                <div className="animate-spin w-4 h-4 border-2 border-white border-t-transparent rounded-full"></div>
              )}
              {isLoading ? 'Running...' : 'Run PHP'}
            </div>
          </RetroButton>

          <RetroButton onClick={verifyCode} disabled={isVerifying} className={isVerifying ? 'opacity-50' : ''}>
            <div className="flex items-center gap-2">
              {isVerifying && (
                <div className="animate-spin w-4 h-4 border-2 border-white border-t-transparent rounded-full"></div>
              )}
              {isVerifying ? 'Verifying...' : 'Verify Code'}
            </div>
          </RetroButton>

          <RetroButton onClick={refreshCode} className="ml-auto">
            <div className="flex items-center gap-2">
              <RefreshCw size={16} />
              Refresh
            </div>
          </RetroButton>
        </div>
      </div>
    </div>
  )
}

export default function PHPLessonRetro({
  lesson,
  currentDesign,
  onToggleDesign,
  isSubscribed,
  isModern,
}) {
  const [activeTab, setActiveTab] = useState('instructions')
  const [phpCode, setPhpCode] = useState(lesson?.starter_php || '')
  const [output, setOutput] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)
  const [splitPosition, setSplitPosition] = useState(50)
  const [isDragging, setIsDragging] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [startX, setStartX] = useState(0)
  const [successMessage, setSuccessMessage] = useState('')
  const [verificationStatus, setVerificationStatus] = useState(null)

  const phpRef = useRef(null)
  const editorRef = useRef(null)
  const containerRef = useRef(null)
  const resizeTimeoutRef = useRef(null)

  const [windowDimensions, setWindowDimensions] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleResize = useCallback(
    debounce(() => {
      if (editorRef.current) {
        editorRef.current.layout()
      }
    }, 100),
    []
  )

  const verifyCode = async () => {
    setIsVerifying(true)
    setError('')
    setSuccessMessage('')
    setVerificationStatus(null)

    try {
      const { data } = await axios.post(
        'https://codeforcambodia.codes/codecademy/compile/verify-php',
        {
          code: phpCode,
          expectedOutput: lesson?.sample_output || '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (data.correct) {
        setShowConfetti(true)
        setVerificationStatus('success')
        setSuccessMessage('🎉 Great job! Your code works correctly!')

        setTimeout(() => {
          setShowConfetti(false)
        }, 5000)
      } else {
        setVerificationStatus('error')
        setError(data.message || "Your code output doesn't match the expected result. Keep trying!")
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || err.message
      setVerificationStatus('error')
      setError('Error: ' + errorMessage)
      console.error('Verification error:', err)
    } finally {
      setIsVerifying(false)
    }
  }

  useEffect(() => {
    const initPhp = async () => {
      try {
        const php = new PhpWeb()
        phpRef.current = php

        php.addEventListener('ready', () => {
          console.log('PHP-WASM is ready!')
        })

        php.addEventListener('output', (event) => {
          setOutput((prevOutput) => prevOutput + event.detail)
        })

        php.addEventListener('error', (event) => {
          setError((prevError) => prevError + event.detail)
        })
      } catch (err) {
        console.error('Failed to initialize PHP-WASM:', err)
        setError('Failed to initialize PHP-WASM. Please check your setup.')
      }
    }

    initPhp()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      handleResize.cancel()
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === containerRef.current) {
          if (resizeTimeoutRef.current) {
            clearTimeout(resizeTimeoutRef.current)
          }
          resizeTimeoutRef.current = setTimeout(() => {
            handleResize()
          }, 100)
        }
      }
    })

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current)
      }
    }
  }, [handleResize])

  const runPhpCode = async (formData = {}) => {
    if (phpRef.current) {
      setIsLoading(true)
      setOutput('')
      setError('')
      setVerificationStatus(null)
      try {
        await phpRef.current.run(phpCode, {
          post: formData,
        })
      } catch (err) {
        console.error('Failed to run PHP code:', err)
        setError('Failed to run PHP code. Please check your code and try again.')
      } finally {
        setIsLoading(false)
      }
    } else {
      setError('PHP-WASM is not initialized. Please wait or refresh the page.')
    }
  }

  const handleEditorMount = (editor, monaco) => {
    editorRef.current = editor
    setTimeout(() => {
      editor.layout()
    }, 0)
  }

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen)
  }

  const refreshCode = () => {
    setPhpCode(lesson?.starter_php || '')
    setOutput('')
    setError('')
    setSuccessMessage('')
    setVerificationStatus(null)
  }

  return (
    <div
      className={`flex flex-col ${isFullScreen ? 'h-screen pb-0 md:pb-12' : 'min-h-screen pb-0 md:pb-12'
        } overflow-hidden pb-4`}
    >
      {!isFullScreen ? (isSubscribed ? <Header/> : <FreeTrialHeader/>) : null}
      {showConfetti && (
        <Confetti
          width={windowDimensions.width}
          height={windowDimensions.height}
          recycle={false}
          numberOfPieces={300}
          gravity={0.3}
          colors={['#ffd700', '#98ff98', '#87ceeb', '#dda0dd', '#f08080']}
          tweenDuration={5000}
        />
      )}
      <div
        className={`flex-grow bg-gray-200 p-1 font-['VT323',_monospace] text-sm sm:text-base md:text-lg overflow-hidden ${isFullScreen ? 'h-full' : 'h-[calc(100vh-8rem-2rem)]'
          }`}
        ref={containerRef}
      >
        <div className="bg-[#c0c0c0] border-t-2 border-l-2 border-[#ffffff] border-r-2 border-b-2 border-[#808080] h-full flex flex-col">
          <div className="bg-[#000080] text-white p-1 flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="w-6 h-6 bg-[#c0c0c0] flex items-center justify-center text-black font-bold border-2 border-[#808080] text-sm sm:text-base md:text-lg">
                PHP
              </div>

              <span className="font-bold text-sm sm:text-base md:text-lg">
                {lesson?.lesson_name || 'php-wasm'}
              </span>
            </div>
            <div className="flex justify-center">
              <button
                onClick={onToggleDesign}
                className="text-white hover:text-gray-300 mr-4"
              >
                <ToggleRight size={20} />
              </button>
              <button onClick={toggleFullScreen} className="text-white hover:text-gray-300">
                {isFullScreen ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
              </button>
            </div>
          </div>
          <div className="p-1 space-y-1 flex-grow flex flex-col overflow-hidden">
            <div className="flex space-x-1">
              <RetroButton onClick={() => setActiveTab('instructions')} active={activeTab === 'instructions'}>
                Instructions
              </RetroButton>
              <RetroButton onClick={() => setActiveTab('code')} active={activeTab === 'code'}>
                Code
              </RetroButton>
              <RetroButton onClick={() => setActiveTab('sample')} active={activeTab === 'sample'}>
                Sample
              </RetroButton>
            </div>
            <div className="flex-grow overflow-hidden">
              {activeTab === 'instructions' && <InstructionsSection instructions={lesson?.instructions} />}
              {activeTab === 'code' && (
                <CodeSection
                  phpCode={phpCode}
                  setPhpCode={setPhpCode}
                  output={output}
                  error={error}
                  isLoading={isLoading}
                  isVerifying={isVerifying}
                  runPhpCode={runPhpCode}
                  verifyCode={verifyCode}
                  editorRef={editorRef}
                  handleEditorMount={handleEditorMount}
                  splitPosition={splitPosition}
                  setSplitPosition={setSplitPosition}
                  isDragging={isDragging}
                  setIsDragging={setIsDragging}
                  startX={startX}
                  setStartX={setStartX}
                  verificationStatus={verificationStatus}
                  successMessage={successMessage}
                  refreshCode={refreshCode}
                />
              )}
              {activeTab === 'sample' && <SampleSection sampleCode={lesson?.sample_output} />}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#c0c0c0] font-['VT323',_monospace] text-black p-1 border-t-2 border-l-2 border-[#ffffff] border-r-2 border-b-2 border-[#808080] text-sm sm:text-base md:text-lg mt-4">
        php-wasm ready!
      </div>
      {!isFullScreen && (
        <div className="mt-4">
          <Footer />
        </div>
      )}
    </div>
  )
}
