import React, { useEffect, useState, useRef } from 'react'; 
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight, Menu, ChevronLeft, ChevronRight, Home, MessageCircle } from 'lucide-react';

export default function LessonNavigation({
  currentModule,
  modules,
  lessons,
  currentLessonIndex,
  courseId,
  moduleId,
  currentLessonId,
  onNavigate
}) {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const isFirstLesson = currentLessonIndex === 0;
  const isLastLesson = currentLessonIndex === lessons.length - 1;
  const currentModuleIndex = modules.findIndex(m => m.id === parseInt(moduleId));
  const isFirstModule = currentModuleIndex === 0;
  const isLastModule = currentModuleIndex === modules.length - 1;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getLessonsForModule = (moduleId) => {
    return lessons.filter(lesson => lesson.module_id === moduleId);
  };
  
  const handleNextNavigation = () => {
    if (isLastLesson) {
      if (!isLastModule) {
        onNavigate('nextModule');
      }
    } else {
      onNavigate('next');
    }
  };
  
  const handlePreviousNavigation = () => {
    if (isFirstLesson) {
      if (!isFirstModule) {
        const prevModule = modules[currentModuleIndex - 1];
        onNavigate('previousModule', { moduleId: prevModule.id, lessonIndex: -1 });
      }
    } else {
      onNavigate('previous', { lessonIndex: currentLessonIndex - 1 });
    }
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleTelegramClick = () => {
    window.open('https://t.me/codeforcambodia_support', '_blank');
  };

  return (
    <div className="bottom-0 left-0 right-0 bg-gray-50 border-t border-gray-200 py-2 fixed">
      <div className="w-full px-4">
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <div className="flex items-center space-x-4 mb-2 sm:mb-0">
            <div className="flex items-center space-x-3">
              <Link 
                to={`/modules/${courseId}/course`}
                className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
              >
                <Home size={20} />
              </Link>
              
              <div className="relative" ref={menuRef}>
                <button
                  onClick={toggleMenu}
                  className="text-gray-600 hover:text-gray-800 transition-colors duration-200 p-1 hover:bg-gray-100 rounded-md"
                >
                  <Menu size={20} />
                </button>
                
                {showMenu && (
                  <div className="absolute bottom-full left-0 mb-2 w-64 bg-white rounded-lg shadow-lg border border-gray-200 z-50">
                    <div className="max-h-96 overflow-y-auto">
                      <div className="p-2">
                        <h3 className="text-base font-semibold Nokora text-gray-700 mb-2 px-2 border-b border-gray-200 pb-1">ថ្នាក់ក្នុងជំពូក</h3>
                        <div className="space-y-1">
                          {lessons.map((lesson) => (
                            <div key={lesson.id} className="relative">
                              <Link
                                to={`/courses/${courseId}/modules/${lesson.module_id}/lessons/${lesson.id}`}
                                className="block px-2 py-1.5 text-sm Nokora text-gray-600 hover:bg-gray-50 rounded-md"
                                onClick={() => setShowMenu(false)}
                              >
                                {lesson.lesson_name}
                                {lesson.id === parseInt(currentLessonId) && (
                                  <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-2 h-2 bg-blue-500 rounded-full"></span>
                                )}
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full">
                      <div className="w-3 h-3 bg-white rotate-45 transform origin-center border-r border-b border-gray-200"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            
            <div className="flex items-center space-x-1">
              <button
                onClick={() => onNavigate('previousModule', { moduleId: modules[currentModuleIndex - 1]?.id, lessonIndex: -1 })}
                className={`p-1 rounded transition-colors duration-200 ${
                  isFirstModule ? 'text-gray-300 cursor-not-allowed' : 'text-gray-600 hover:bg-gray-200'
                }`}
                disabled={isFirstModule}
              >
                <ChevronLeft size={20} />
              </button>
              <span className="text-sm font-medium text-gray-700 Nokora">{currentModule.module_name}</span>
              <button
                onClick={() => onNavigate('nextModule', { moduleId: modules[currentModuleIndex + 1]?.id, lessonIndex: 0 })}
                className={`p-1 rounded transition-colors duration-200 ${
                  isLastModule ? 'text-gray-300 cursor-not-allowed' : 'text-gray-600 hover:bg-gray-200'
                }`}
                disabled={isLastModule}
              >
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
          
          <div className="flex items-center space-x-4">
            <button
              onClick={handleTelegramClick}
              className="flex items-center Nokora px-2 py-1 rounded transition-colors duration-200 text-gray-600 hover:bg-gray-200"
            >
              <MessageCircle size={16} className="mr-1.5" />
              <span className="hidden sm:inline">ត្រូវការជំនួយ?</span>
              <span className="sm:hidden">ជួយមេរៀន</span>
            </button>

            <button
              onClick={handlePreviousNavigation}
              className={`flex items-center px-2 py-1 rounded transition-colors duration-200 ${
                isFirstLesson && isFirstModule ? 'text-gray-300 cursor-not-allowed' : 'text-gray-600 hover:bg-gray-200'
              }`}
              disabled={isFirstLesson && isFirstModule}
            >
              <ArrowLeft size={16} className="mr-1 hidden sm:inline" />
              <span className="text-sm">Previous</span>
            </button>
            <div className="text-sm text-gray-600 hidden sm:block">
              {currentLessonIndex + 1} / {lessons.length}
            </div>
            <button
              onClick={handleNextNavigation}
              className={`flex items-center px-2 py-1 rounded transition-colors duration-200 ${
                isLastLesson && isLastModule ? 'text-gray-300 cursor-not-allowed' : 'text-gray-600 hover:bg-gray-200'
              }`}
              disabled={isLastLesson && isLastModule}
            >
              <span className="text-sm">Next</span>
              <ArrowRight size={16} className="ml-1 hidden sm:inline" />
            </button>
          </div>
        </div>
        
        <div className="mt-2 w-full bg-gray-200 h-1">
          <div 
            className="bg-blue-500 h-1" 
            style={{ width: `${((currentLessonIndex + 1) / lessons.length) * 100}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
}