// Main class that coordinates everything
import BasicCommands from './commands/BasicCommands';
import ConfigurationCommands from './commands/ConfigurationCommands';
import InterfaceCommands from './commands/InterfaceCommands';
import ShowCommands from './commands/ShowCommands';
import DeviceManager from './device/DeviceManager';
import ConnectionManager from './network/ConnectionManager';

export default class NetworkState {
    constructor() {
        this.devices = new Map();
        this.connections = new Map();
        this.packets = new Map();
        this.configMode = new Map();
        this.pingTimeouts = new Map();
        this.commandHistory = new Map();
        this.deviceCounters = {
            router: 0,
            switch: 0,
            pc: 0
        };
        
        // Initialize managers
        this.deviceManager = new DeviceManager(this);
        this.connectionManager = new ConnectionManager(this);
        this.basicCommands = new BasicCommands(this);
        this.configCommands = new ConfigurationCommands(this);
        this.interfaceCommands = new InterfaceCommands(this);
        this.showCommands = new ShowCommands(this);

        // Add a method to update device state
        this.updateDevice = (deviceId, updater) => {
            const device = this.devices.get(deviceId);
            if (device) {
                const updated = updater(device);
                this.devices.set(deviceId, updated);
                return updated;
            }
            return null;
        };
        
        // Initialize command handlers
        this.commandHandlers = [
            new BasicCommands(this),
            new ConfigurationCommands(this),
            new InterfaceCommands(this),
            new ShowCommands(this)
            // Add other command handlers here
        ];
        this.dhcpPools = new Map(); // Store DHCP pools per router
        this.dhcpLeases = new Map(); // Store active DHCP leases
    }

    getNextDeviceId(deviceType) {
        if (!this.deviceCounters[deviceType]) {
            this.deviceCounters[deviceType] = 0;
        }
        this.deviceCounters[deviceType]++;
        return this.deviceCounters[deviceType];
    }
    
    updateDeviceInterface(deviceId, interfaceName, updates) {
        const device = this.devices.get(deviceId);
        if (!device) {
            console.log('Device not found:', deviceId);
            return false;
        }
    
        console.log('Before update - Device interfaces:', JSON.stringify(device.interfaces, null, 2));
        console.log('Updating interface:', interfaceName, 'with:', updates);
    
        const newDevice = {
            ...device,
            interfaces: {
                ...device.interfaces,
                [interfaceName]: {
                    ...device.interfaces[interfaceName],
                    ...updates
                }
            }
        };
    
        this.devices.set(deviceId, newDevice);
        
        // Verify update
        const verifyDevice = this.devices.get(deviceId);
        console.log('After update - Device interfaces:', JSON.stringify(verifyDevice.interfaces, null, 2));
        
        return true;
    }

    addDevice(type, x, y, id = null) {
      // Existing code to create the device
      const deviceId = id || `${type}${this.getNextDeviceId(type)}`;
      const interfaces = this.createInterfaces(type);
  
      const device = {
          id: deviceId,
          type: type,
          position: { x, y },
          interfaces: interfaces,
          config: {
              hostname: deviceId
          }
      };
  
      console.log('Adding device to network:', {
          id: deviceId,
          type: type,
          interfaceCount: Object.keys(interfaces).length
      });
  
      this.devices.set(deviceId, device);
  
      // Initialize configMode for the device
      if (type === 'pc') {
          // PCs default to 'config' mode
          this.configMode.set(deviceId, { mode: 'config' });
      } else {
          // Routers and switches start in 'user' mode
          this.configMode.set(deviceId, { mode: 'user' });
      }
  
      return device;
  }
  

    createInterfaces(deviceType) {
        switch(deviceType) {
            case 'router':
                return {
                    'FastEthernet0/0': { status: 'down', ip: null, subnet: null },
                    'FastEthernet0/1': { status: 'down', ip: null, subnet: null },
                    'FastEthernet0/2': { status: 'down', ip: null, subnet: null },
                    'FastEthernet0/3': { status: 'down', ip: null, subnet: null },
                    'FastEthernet0/4': { status: 'down', ip: null, subnet: null },
                    'GigabitEthernet0/0': { status: 'down', ip: null, subnet: null },
                    'GigabitEthernet0/1': { status: 'down', ip: null, subnet: null }
                };
            case 'switch':
                // Create 24 FastEthernet ports for the switch
                return Object.fromEntries(
                    Array.from({ length: 24 }, (_, i) => [
                        `FastEthernet0/${i + 1}`,
                        { status: 'down', vlan: 1 }
                    ])
                );
            case 'pc':
                return {
                    'Ethernet0': { status: 'down', ip: null, subnet: null, gateway: null }
                };
            default:
                return {};
        }
    }

    deleteDevice(deviceId) {
        return this.deviceManager.deleteDevice(deviceId);
    }

    addConnection(fromDevice, toDevice) {
        console.log('Attempting to add connection:', {
            fromId: fromDevice?.id,
            toId: toDevice?.id,
            fromInterfaces: fromDevice?.interfaces ? Object.keys(fromDevice.interfaces) : [],
            toInterfaces: toDevice?.interfaces ? Object.keys(toDevice.interfaces) : []
        });

        return this.connectionManager.addConnection(fromDevice, toDevice);
    }

    deleteConnection(connectionId) {
        return this.connectionManager.deleteConnection(connectionId);
    }

    executeCommand(command, deviceId) {
        const device = this.devices.get(deviceId);
        if (!device) return { error: 'Device not found' };

        const [cmd, ...args] = command.trim().split(/\s+/);
        const mode = this.configMode.get(deviceId)?.mode || 'user';

        // Create base result with input command
        const baseResult = {
            input: command,
            prompt: this.getCommandPrompt(deviceId)
        };

        // Find handler for command
        const handler = this.commandHandlers.find(h => h.canHandle(cmd));
        if (!handler) {
            return {
                ...baseResult,
                error: 'Invalid command'
            };
        }

        // Execute command and merge with base result
        const result = handler.execute(cmd, args, deviceId);
        return {
            ...baseResult,
            ...result,
            input: command  // Ensure input is preserved
        };
    }

    isCommandAuthorized(cmd, mode) {
        const userCommands = ['enable', 'show', 'exit', 'help', '?'];
        const privilegedCommands = [...userCommands, 'configure', 'copy', 'write'];
        const configCommands = [...privilegedCommands, 'hostname', 'interface', 'router', 'ip', 'route'];
        
        switch(mode) {
            case 'user':
                return userCommands.includes(cmd);
            case 'privileged':
                return privilegedCommands.includes(cmd);
            case 'config':
            case 'config-if':
            case 'config-router':
                return configCommands.includes(cmd);
            default:
                return false;
        }
    }

    getCommandPrompt(deviceId) {
      const device = this.devices.get(deviceId);
      if (!device) return '';
      
      const mode = this.configMode.get(deviceId) || { mode: 'user', interface: null };
      const hostname = device.config.hostname;
      
      if (device.type === 'pc') {
          // For PCs, perhaps use a simpler prompt
          return `${hostname}>`;
      }
      
      switch(mode.mode) {
          case 'privileged':
              return `${hostname}#`;
          case 'config':
              return `${hostname}(config)#`;
          case 'config-if':
              return `${hostname}(config-if)#`;
          case 'config-router':
              return `${hostname}(config-router)#`;
          default:
              return `${hostname}>`;
      }
  }
  

    getState() {
        return {
            devices: this.deviceManager.getSerializedDevices(),
            connections: this.connectionManager.getSerializedConnections()
        };
    }

    getCommandHistory(deviceId) {
        return this.commandHistory.get(deviceId) || [];
    }

    getDevice(deviceId) {
        const device = this.devices.get(deviceId);
        if (!device) {
            console.warn(`Device not found: ${deviceId}`);
            console.log('Available devices:', Array.from(this.devices.keys()));
        }
        return device;
    }

    reset() {
        this.devices = new Map();
        this.connections = new Map();
        this.packets = new Map();
        this.configMode = new Map();
        this.pingTimeouts = new Map();
        this.commandHistory = new Map();
        this.deviceCounters = {
            router: 0,
            switch: 0,
            pc: 0
        };
        this.dhcpPools = new Map();
        this.dhcpLeases = new Map();
    }
}

